import { navigate } from '@reach/router';
import React from 'react';
import Swal from 'sweetalert2';
import { authentication } from '../../Services/firebase';
import { Helper } from '../../Services/Helper';
import { createUserAPI } from '../../Services/userServices';
import PrivacyInfo from '../../Components/PrivacyInfo';
import TermsInfo from '../../Components/TermsInfo';

export default class Register extends React.Component {
    constructor() {
        super();
        this.state = {
            phoneNumber: Helper.getParameterByName('phoneNumber')
        }
    }

    componentDidMount() {
        window.location.href = "https://beeclean-frontend.web.app/register"
        // const user = JSON.parse(window.localStorage.getItem('loginuser'));
    }

    async onConfirm(data) {
        var user = JSON.parse(window.localStorage.getItem('loginuser'));
        this.submit.removeClass('loading');
        let displayName = data.name;
        let email = data.email;
        let userUid = user.uid;
        let phoneNumber = user.phoneNumber;
        const res = await createUserAPI(userUid, email, phoneNumber, displayName);

        this.submit.removeClass('loading');

        if (res.error) {
            Swal.fire({
                // title: `Warning`,
                icon: 'warning',
                html: res.response.data.errorInfo.message,
            })
        } else {
            user.displayName = displayName;
            user.email = email;
            Helper.setLocalStorage('loginuser', JSON.stringify(user));
            window.location.href = '/dashboard'
        }

    }

    render() {
        // let phone = ``;
        // phone += `+${this.state.phoneNumber.slice(0, 4)}`;
        // phone += `-${this.state.phoneNumber.slice(4, 7)}`;
        // phone += `-${this.state.phoneNumber.slice(7, 11)}`;
        return;
        return (
            <>
                <div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}>
                    <div>
                        <form
                            className='login-box mb-4'
                            ref={(ref) => {
                                this.$form = window.$(ref)
                                this.submit = this.$form.find(`button[type="submit"]`)
                            }}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (this.submit.hasClass('loading')) { return false; }
                                this.submit.addClass('loading');
                                let data = Helper.getFormInputObject(this.$form);
                                if (!data.name || !data.email) {
                                    Swal.fire({
                                        // title: `Warning`,
                                        icon: 'warning',
                                        html: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                                    })
                                    this.submit.removeClass('loading');
                                    return;
                                }
                                this.onConfirm(data);
                            }}
                        >
                            <div className='d-flex flex-column border-bottom-custom pb-4 mb-3'>
                                <label>ชื่อ - นามสกุล</label>
                                <input type="text" className="form-control" name="name" />
                            </div>
                            <div className='d-flex flex-column mb-4'>
                                <label className='mb-0'>อีเมล์</label>
                                <p className='mb-2' style={{ fontSize: 14, color: '#ccc' }}>เราจะส่งคำยืนยันไปทางอีเมลของท่าน</p>
                                <input type="email" className="form-control" name="email" />
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="true"
                                        name="acceptTerms"
                                        id="acceptTerms"

                                        onChange={(e) => {
                                            let formNode = this.submit
                                            if (window.$(e.target).is(':checked')) {
                                                formNode.attr('disabled', false);
                                            } else {
                                                formNode.attr('disabled', true);
                                            }
                                        }}
                                    />
                                    <label className="form-check-label" for="acceptTerms" >
                                        ยอมรับเงื่อนไขในการให้บริการ
                                    </label>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mb-4'>
                                <span className='btn-link ml-1 mr-1 cursor-pointer'
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <ModalInfo
                                                type="privacy"
                                            />
                                        )
                                    }}
                                >นโยบายและข้อกำหนด</span>
                                <span style={{ color: `#000` }}>และ</span>
                                <span className='btn-link ml-1 cursor-pointer'
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <ModalInfo
                                                type="terms"
                                            />
                                        )
                                    }}
                                >ข้อตกลงการใช้งาน</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mb-4'>
                                <button
                                    type={"submit"}
                                    className={`submit-btn btn`}
                                    disabled={true}
                                >
                                    เสร็จสิ้น
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </>
        )
    }
}
class ModalInfo extends React.Component {
    render() {
        return (

            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                style={{ maxWidth: '100%' }}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content custom-modal" >
                        <div className="modal-body">
                            {
                                this.props.type == "privacy" ?
                                    <PrivacyInfo />
                                    : null
                            }
                            {
                                this.props.type == "terms" ?
                                    <TermsInfo />
                                    : null
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type="button"
                                className="btn btn-service mr-4"
                                data-dismiss="modal"
                            >ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}