import axios from 'axios';
import { db } from './firebase';
import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;
export const getReview = async () => {
    try {
        let res = await axios.get(`${window.api_host}/get-reviewer`);
        return { success: true, ...res.data };
    } catch (error) {
        return { error: true, error: error }
    }

}
// export const getReview = async () => {
//     let ref = await db.collection('books').where('starFromUser', '==', 5).where('reportFromUser', "!=", null).orderBy(`bookedAt`, 'desc').get();
//     let items = [];
//     if(!ref.empty) {
//         for (const doc of ref.docs) {
//             let item = {
//                 ...doc.data(),
//                 id: doc.id,
//             }
//             items.push(item);
//         }
//     }
//     return items;
// }