import React from "react";
import { Helper } from './../../../Services/Helper'
const textRed = `#B65153`;
export default class Info extends React.Component {
    render() {

        return (
            <div className="service-info pt-3 pb-3">
                <h1>บริการล้างแอร์บีคลีน</h1>
                <div className="mb-5">
                    <span className="mr-2">
                        <i className="fa-solid fa-star" style={{ color: '#FCB822' }}></i>
                        <i className="fa-solid fa-star" style={{ color: '#FCB822' }}></i>
                        <i className="fa-solid fa-star" style={{ color: '#FCB822' }}></i>
                        <i className="fa-solid fa-star" style={{ color: '#FCB822' }}></i>
                        <i className="fa-solid fa-star" style={{ color: '#E5E7E7' }}></i>
                    </span>
                    Rating 4.8 <span style={{ color: textRed }}>(รีวิว {Helper.numberFormat(15000)})</span> ใช้บริการไปแล้ว <span style={{ color: textRed }}>{Helper.numberFormat(19600)}</span> ครั้ง
                </div>
                <h5>รายละเอียดของบริการ</h5>
                <ul className="mb-0">
                    {/* {this.props.data.foreach(e => {
                        return (
                            <li>{e}</li>
                        )
                    })} */}

                    <li>ทุก BTU จบในราคาเดียว ไม่มีจ่ายเพิ่ม</li>
                    <li>ใส่ใจ ดูแลความสะอาด ระหว่างทำและหลังล้าง</li>
                    <li>ระยะเวลาบริการ ไม่เกิน 2 ชม. ต่อเครื่อง</li>
                    <li>พื้นที่บริการ : กรุงเทพฯ และเขตปริมณฑล</li>
                    <li>รับประกัน 30 วัน อาการผิดปกติ และความเสียหายหลังจากการล้าง</li>
                    <li>รบกวนแจ้งกับทางนิติบุคคลทางคอนโด หรือโครงการบ้านล่วงหน้า ว่าจะมีช่างเข้ามาทำงานในเวลาวันที่จอง เพื่อนความสะดวกในการใช้บริการ</li>
                </ul>

            </div>
        )
    }
}