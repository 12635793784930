import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOMClient from 'react-dom/client';
import thailand from './thailand.json'
import { storage } from './firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const Compress = require('compress.js').default;
const compress = new Compress();

window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
export const Helper = {
    async uploadFile(node, path, options) {
        // let id = this.state.provider_id;
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        // console.log("file.type", file)
        if (file
            && file.type !== 'image/png'
            && file.type !== 'image/jpeg'
            && file.type !== 'image/jpg'
        ) {
            if (options.onError) {
                options.onError({ error: true, message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" });
            }
            upload_btn.removeClass('loading')
            return false;
        }

        const filename = Helper.setNewImageFileName(file);
        let reszieFile = file;
        // if (options.resize) {
        //     reszieFile = await Helper.resizeImageFn(file, options)
        // }
        // console.log(path + filename)
        // var uploadTask = storage.ref().child(path + filename).put(reszieFile);
        const storageRef = ref(storage, path + filename);

        const uploadTask = uploadBytesResumable(storageRef, reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log("snapshot", snapshot);
        }, (error) => {
            // console.log(error);
            upload_btn.removeClass('loading');
            if (options.onError) {
                options.onError({ message: 'อัพโหลดรูปไม่สำเร็จ' });
                upload_btn.removeClass('loading')
            }
        }, async () => {
            let url = {};
            url.storePath = path + filename;
            url.imagePath = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("url", url)
            if (options.onSuccess) {
                options.onSuccess(url);
                upload_btn.removeClass('loading')
            }
        });

    },
    renderStatus(status) {
        if (!status) {
            return 'ไม่แสดงสถานะ';
        }
        if (status == 'wait') {
            return <><div className="bg-secondary rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> รอ</>
        }
        if (status == 'accept') {
            return <><div className="bg-primary rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> ยอมรับ</>
        }
        if (status == 'going') {
            return <><div className="bg-warning rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> กำลังเดินทาง</>
        }
        if (status == 'arrive') {
            return <><div className="bg-warning rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10, backgroundColor: 'darkblue' }}></div> ถึง</>
        }
        if (status == 'working') {
            return <><div className="bg-info rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> เริ่มทำงาน</>
        }
        if (status == 'finish') {
            return <><div className="bg-success rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> เสร็จงาน</>
        }
        if (status == 'done') {
            return <><div className="bg-success rounded-circle border border-0 mr-2 border-white" style={{ height: 10, width: 10 }}></div> ส่งงาน</>
        }
    },
    displayPlaceIcon(str) {
        if (str == "house") {
            return <img src={`${window.location.origin}/assets/img/home-icon.png`} alt="place type icon" />
        }
        if (str == "condo") {
            return <img src={`${window.location.origin}/assets/img/condo-icon.png`} alt="place type icon" />
        }
    },
    displayPlaceText(str) {
        if (str == "house") {
            return <div>ทาวน์โฮม/บ้านเดี่ยว</div>
        }
        if (str == "condo") {
            return <div>คอนโด/หอพัก</div>
        }
    },
    loginCheckRedirect() {
        let redirect = "/";
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (currentUser) {
            redirect = `/dashboard`;
        }
        return redirect;
    },
    numberFormat(number, options) {
        if (!number) { return 0 }
        number = parseFloat(number);
        if (options && options.decimal) {
            number = number.toFixed(options.decimal)
        }
        if (isNaN(number)) {
            number = 0;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    getFormInputObject(form) {
        let data = {};
        form.serializeArray().map((item) => {
            data[item.name] = item.value;
            return false;
        })
        return data;
    },
    getAllProvinces() {
        let provinces = [];
        for (const _thailand in thailand) {
            provinces.push({
                code: _thailand,
                ...thailand[_thailand]
            })
        }
        provinces = provinces.sort((a, b) => (a.name.th > b.name.th) ? 1 : -1);
        return provinces;
    },
    formatDate(date, options) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },
    getDateThai(_date, options) {
        let date = new Date(_date);
        let time = '';
        if (options && options.plus_hours) {
            date = new Date(date.setTime(date.getTime() + (parseFloat(options.plus_hours) * 60 * 60 * 1000)));
        }
        if (options && options.is_show_time) {
            let hour = date.getHours();
            let minute = date.getMinutes();
            let second = date.getSeconds();
            if (hour < 10) {
                hour = '0' + date.getHours()
            }
            if (minute < 10) {
                minute = '0' + date.getMinutes()
            }
            if (second < 10) {
                second = '0' + date.getSeconds()
            }
            // time = ' - ' + hour + ':' + minute + ':' + second;
            time = ' - ' + hour + ':' + minute;
        }
        let month = window.months_th[parseFloat(date.getMonth())];
        if (options && options.dateNumber) {
            month = parseFloat(date.getMonth());
            if (month < 10) {
                month = '0' + month;
            }
        }
        let space = ' ';
        if (options && options.space) {
            space = options.space;
        }
        if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
            return "";
        }
        return date.getDate() + space + month + space + (date.getFullYear() + 543) + time;
    },
    getDate(_date, options) {
        let date = new Date(_date);
        let time = '';
        if (options && options.is_show_time) {
            let hour = date.getHours();
            let minute = date.getMinutes();
            let second = date.getSeconds();
            if (hour < 10) {
                hour = '0' + date.getHours()
            }
            if (minute < 10) {
                minute = '0' + date.getMinutes()
            }
            if (second < 10) {
                second = '0' + date.getSeconds()
            }
            // time = ' - ' + hour + ':' + minute + ':' + second;
            time = ' - ' + hour + ':' + minute;
        }
        if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
            return "";
        }
        if (options && options.lang === 'th') {
            return date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) + time;
        }
        return date.getDate() + ' ' + window.months_eng[parseFloat(date.getMonth())] + ' ' + (date.getFullYear()) + time;
    },
    getTime(_date, options) {
        if (!_date) { return null }
        let date = new Date(_date);
        let time = '';
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        if (hour < 10) {
            hour = '0' + date.getHours()
        }
        if (minute < 10) {
            minute = '0' + date.getMinutes()
        }
        if (second < 10) {
            second = '0' + date.getSeconds()
        }
        time = hour + ':' + minute;

        if (options && options.bookSelectHalfTime) {
            if (parseFloat(minute) === 0) {
                time = parseFloat(hour);
            }
            if (parseFloat(minute) > 0) {
                time = parseFloat(hour) + '.' + minute;
            }
        }
        return time;
    },
    // async removeFile(path) {
    //     var desertRef = await storage.ref().child(path);
    //     let _response = {};
    //     desertRef.delete().then(() => {
    //         _response.success = true;
    //     }).catch((error) => {
    //         _response.error = true;
    //         _response.error_data = error;
    //     });
    //     return _response;
    // },
    // async uploadFile(node, path, options) {
    //     // let id = this.state.provider_id;
    //     // console.log("uploadFile", node, path, options)
    //     let upload_btn = node.parent().find('.btn');
    //     if (upload_btn.hasClass("loading")) { return false }
    //     upload_btn.addClass('loading');
    //     let file = node[0].files[0];
    //     // console.log("file.type", file)
    //     if (file
    //         && file.type !== 'image/png'
    //         && file.type !== 'image/jpeg'
    //         && file.type !== 'image/jpg'
    //     ) {
    //         if (options.onError) {
    //             options.onError({ error: true, message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" });
    //         }
    //         upload_btn.removeClass('loading')
    //         return false;
    //     }

    //     const filename = Helper.setNewImageFileName(file);
    //     let reszieFile = file;
    //     if (options.resize) {
    //         reszieFile = await Helper.resizeImageFn(file)
    //     }
    //     // console.log(path + filename)
    //     var uploadTask = storage.ref().child(path + filename).put(reszieFile);
    //     uploadTask.on('state_changed', (snapshot) => {
    //         // Observe state change events such as progress, pause, and resume
    //         // console.log(snapshot);
    //     }, (error) => {
    //         // console.log(error);
    //         upload_btn.removeClass('loading');
    //         if (options.onError) {
    //             options.onError({ message: 'อัพโหลดรูปไม่สำเร็จ' });
    //             upload_btn.removeClass('loading')
    //         }
    //     }, async () => {
    //         let url = {};
    //         url.storePath = path + filename;
    //         url.imagePath = await storage.ref(path + filename).getDownloadURL();
    //         // console.log(url)
    //         if (options.onSuccess) {
    //             options.onSuccess(url);
    //             upload_btn.removeClass('loading')
    //         }
    //     });
    // },
    readURL(input) {
        let show_image_Ref = input.parent().find('#show_image');
        let checktype = false;
        if (input[0].files
            && input[0].files[0]
            && (input[0].files[0].type === 'image/png'
                || input[0].files[0].type === 'image/jpeg'
                || input[0].files[0].type === 'image/jpg'
            )) {
            checktype = true;
        }
        // console.log(checktype);
        if (input[0].files && input[0].files[0] && checktype) {

            var reader = new FileReader();
            reader.onload = (e) => {
                input.parent().find('.button').text('เปลี่ยนรูป');
                if (show_image_Ref) {
                    show_image_Ref.html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                    show_image_Ref.html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
                }
            };

            reader.readAsDataURL(input[0].files[0]);
            return true;
        } else {
            // show_image_Ref.html('');
            input.val('');
            Helper.messageTop({ message: "กรุณาอัพโหลดเฉพาะรูปภาพเท่านั้น" })
            return false;
        }
    },

    reactToDom($dom, Component, props) {
        // console.log(typeof Component);
        const root = ReactDOMClient.createRoot(document.getElementById('append_root'));
        if (typeof Component === 'function') {
            root.render(React.createElement(Component, props, null), document.createElement('div'), function () {
                $dom.append(ReactDOMClient.findDOMNode(this));
            });
        }
        if (typeof Component === 'object') {
            root.render(Component, document.createElement('div'), function () {
                $dom.append(ReactDOMClient.findDOMNode(this));
            });
        }
    },
    setNewImageFileName(file) {
        let date = new Date();
        let file_type = file.name.substr(file.name.indexOf('.'), file.name.indexOf('.') + 3);
        let file_name = file.name.substr(0, file.name.indexOf('.'));
        return file_name + Date.parse(date) + file_type;
    },
    async resizeImageFn(file, options) {
        const resizedImage = await compress.compress([file], {
            size: 20, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: options && options.maxWidth ? options.maxWidth : 500, // the max width of the output image, defaults to 1920px
            maxHeight: options && options.maxHeight ? options.maxHeight : 500, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        })
        const img = resizedImage[0];
        // return img;
        const base64str = img.data
        const imgExt = img.ext
        const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
        return resizedFiile;
    },
    setLocalStorage(cname, cvalue) {
        window.localStorage.setItem(cname, cvalue);
        // var d = new Date();
        // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        // var expires = "expires=" + d.toUTCString();
        // // cvalue = window.escape(cvalue);
        // cvalue = window.escape(cvalue);
        // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    removeStorage(name) {
        window.localStorage.removeItem(name);
    },
    getLocalStorage(cname) {
        let data = window.localStorage.getItem(cname);
        return data || '';
        // var name = cname + "=";
        // var ca = document.cookie.split(';');
        // for (var i = 0; i < ca.length; i++) {
        //   var c = ca[i];
        //   while (c.charAt(0) === ' ') {
        //     c = c.substring(1);
        //   }
        //   if (c.indexOf(name) === 0) {
        //     // c = window.unescape(c);
        //     c = window.unescape(c);
        //     return c.substring(name.length, c.length);
        //   }
        // }
        // return "";
    },
    convertFirebaseDate(firebaseDate) {
        if (typeof firebaseDate == "number") {
            return new Date(firebaseDate);
        } else {
            return new Date(firebaseDate._seconds * 1000 + firebaseDate._nanoseconds / 1000000);
        }

    },
    renderPhone(phone) {
        if (typeof phone == 'number' || phone.indexOf('+66') == -1) {
            return 'เบอร์โทรศัพท์ไม่ถูกต้อง'
        }
        let _phone = phone.replace('+66', '0');
        let return_phone = ``;
        return_phone += _phone.slice(0, 3)
        return_phone += `-`;
        return_phone += _phone.slice(3, 6)
        return_phone += `-`;
        return_phone += _phone.slice(6, 11)
        return return_phone
    }
}