import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from './../Components/CustomDateInput';
import InputMask from 'react-input-mask';
import { Helmet } from "react-helmet";
import { Helper } from '../Services/Helper';
import { db, storage } from '../Services/firebase';
import Swal from 'sweetalert2';
const axios = require('axios').default;
// import {data} from './../db.json';
const minAge = 18;
const maxAge = 60;
export default class Register extends React.Component {
    constructor() {
        super();

        this.state = {
            is_first_form_show: true,
            is_second_form_show: true,
            birth_day: null,
            educate_day: new Date(),
            slip_day: new Date(),
            data: {},
            training_date: [],
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            selected_province: false,
            selected_amphoe: [],
            db: [],
            bank_list: [
                // "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                // "ธนาคารกรุงไทย",
                // "ธนาคารทหารไทย",
                // "ธนาคารไทยพาณิชย์",
                // "ธนาคารกรุงศรีอยุธยา",
                // "ธนาคารเกียรตินาคินภัทร",
                // "ธนาคารซีไอเอ็มบีไทย",
                // "ธนาคารทิสโก้",
                // "ธนาคารธนชาต",
                // "ธนาคารยูโอบี",
                // "ธนาคารไทยเครดิตเพื่อรายย่อย",
                // "ธนาคารแลนด์แอนด์ เฮ้าส์",
                // "ธนาคารไอซีบีซี (ไทย)",
                // "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                // "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                // "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                // "ธนาคารออมสิน",
                // "ธนาคารอาคารสงเคราะห์",
                // "ธนาคารอิสลามแห่งประเทศไทย"
            ],
            bank_selected: "ธนาคารกสิกรไทย",
        }
    }

    componentDidMount() {
        // window.$.Thailand({
        //     database: './assets/js/database/db.json', 
        //     $amphoe: this.amphoe, // input ของอำเภอ
        //     $province: this.province, // input ของจังหวัด
        //     templates: {
        //       empty: ' ',
        //       suggestion: (data) =>  {
        //           console.log(data);
        //         return '<div>' + data.d + '</div>';
        //       }
        //     },
        // });
        this.fetchDate();
    }


    async fetchDate() {
        let province = await axios.get(window.api_host + "/service-area").then(async (res) => {
            let _data = res.data.data.filter((resp) => {
                return resp.amphoe.length > 0
            });
            //    console.log(_data)
            return _data
        })
        // let tranningDateRef = db.collection("training_date").orderBy('date', 'asc');
        // let tranningDateData = await tranningDateRef.get();
        let tranningDate_data = [];
        // tranningDate_data = await Promise.all(
        //     tranningDateData.docs.map(async (element) => {
        //         let participants = await db.collection('training_date/' + element.id + '/participants').get();
        //         let date = new Date(element.data().date);
        //         let now = new Date();
        //         if (date >= now) {
        //             return {
        //                 ...element.data(),
        //                 date: date,
        //                 id: element.id,
        //                 participants_count: participants.docs.length,
        //             };
        //         }
        //         return null;

        //     })
        // );
        // console.log(province);
        this.setState({
            training_date: tranningDate_data,
            educate_day: tranningDate_data[0],
            db: province,
            selected_province: province[0].provinceCode
        })
    }

    readURL(input) {
        if (input && input[0] && input[0].files && input[0].files[0]) {
            let file = input[0].files[0];
            if (file
                && file.type !== 'image/png'
                && file.type !== 'image/jpeg'
                && file.type !== 'image/jpg'
            ) {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'อัพโหลดเฉพาะรูปภาพเท่านั้น',
                    icon: 'warning',
                    position: 'top-end',
                    toast: true,
                    backdrop: false,
                    showConfirmButton: false,
                    timer: 2500
                })
                return;
            }
            var reader = new FileReader();
            reader.onload = (e) => {
                this.file.parent().find('.button').text('เปลี่ยนรูป');
                input.parent().find('#show_image').html('<div className="ui active centered inline loader" style={{ marginTop: 15 }}></div>');
                input.parent().find('#show_image').html('<img id="blah" src="' + e.target.result + '" alt="your image" />');
            };

            reader.readAsDataURL(input[0].files[0]);

        }
    }
    async uploadFiles(node, id, options) {
        let upload_btn = node.parent().find('.btn');
        if (upload_btn.hasClass("loading")) { return false }
        upload_btn.addClass('loading');
        let file = node[0].files[0];
        const filename = Helper.setNewImageFileName(file);
        console.log(filename)
        let reszieFile = file;
        if (options.resize) {
            reszieFile = await Helper.resizeImageFn(file)
        }
        var uploadTask = storage.ref().child('provider_users_data/' + id + '/' + filename).put(reszieFile);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            console.log(snapshot);
        }, (error) => {
            console.log(error);
            if (options.onError) {
                options.onError();
            }
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'อัพโหลดรูปไม่สำเร็จ',
                icon: 'error',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            upload_btn.removeClass('loading');
        }, async () => {
            let url = 'provider_users_data/' + id + '/' + filename;
            if (options.onSuccess) {
                options.onSuccess(url);
            }

        });
    }

    uploadFile(file, id) {
        const filename = Helper.setNewImageFileName(file);
        var uploadTask = storage.ref().child('provider_users_data/' + id + '/' + filename).put(file);
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // console.log(snapshot);
        }, (error) => {
            // console.log(error);
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'อัพโหลดรูปไม่สำเร็จ',
                icon: 'error',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            this.$form.find('button[typr="submit"]').removeClass('loading');
        }, async () => {
            let url = 'provider_users_data/' + id + '/' + filename;
            let transferImageUrl = url;
            let transferImage = await storage.ref(url).getDownloadURL();
            db.doc(`provider_users_data/` + id).update({ transferImage: transferImage, transferImageUrl: transferImageUrl }).then(res => {
                this.props.history.push({
                    pathname: '/register-success',
                    search: null,
                    state: null
                });
            });
        });
    }
    async registerProvider(update_data, data, res) {
        update_data.firstTimePhoneNumber = update_data.phoneNumber;
        // await db.doc(`provider_users_data/${res.data.userUid}`).set({
        //     ...update_data
        // });
        // await db.collection(`training_date/${data.traningUID}/participants`).add({ providerUid: res.data.userUid });

        let _res = await axios.post(`${window.api_host}/provider-data`, {
            res: res,
            data: data,
            update_data: data,
        })

        window.location = '/register-success';
    }
    async add(data) {
        data.status = "waitForApprove";
        data.createdAt = new Date().getTime();
        let phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
        let emergencyContactNumber = '+66' + data.emergencyContactNumber.substr(1, 9);
        // let is_provider_users = 0;
        // let _data = [];
        data.phoneNumber = phoneNumber;
        data.emergencyContactNumber = emergencyContactNumber;
        data.registerBy = "yourself";

        let file = this.file[0].files[0];

        const displayName = data.firstName + ' ' + data.lastName;
        const traningUID = data.traningUID;
        let submitBtn = this.$form.find('button[type="submit"]');
        // console.log(data);
        // return;
        axios.post(window.api_host + '/create-provider-user', {
            phoneNumber, displayName, traningUID
        }).then(async res => {
            if (res.data.code == 200 || res.data.code == 201) {
                let transferImageUrl = false;
                let transferImage = false;
                let IDcardImageUrl = false;
                let IDcardImage = false;
                let AddressCardImageUrl = false;
                let AddressCardImage = false;
                let bankAccountImageUrl = false;
                let bankAccountImage = false;
                console.log(`SUCCESS`)
                console.log(`UPLOAD IMAGE`)
                await this.uploadFiles(this.file_image_idcard, res.data.userUid, {
                    onSuccess: async (image_idcard_url) => {
                        IDcardImageUrl = image_idcard_url;
                        console.log('UPLOADING ADDRESS CARD IMAGE')
                        await this.uploadFiles(this.file_image_addresscard, res.data.userUid, {
                            onSuccess: async (image_addresscard_url) => {
                                AddressCardImageUrl = image_addresscard_url;
                                console.log('UPLOADING BOOK BANK IMAGE')
                                await this.uploadFiles(this.file_image_bank, res.data.userUid, {
                                    onSuccess: async (image_bank_url) => {
                                        bankAccountImageUrl = image_bank_url;
                                        bankAccountImage = await storage.ref(image_bank_url).getDownloadURL();
                                        IDcardImage = await storage.ref(image_idcard_url).getDownloadURL();
                                        AddressCardImage = await storage.ref(image_addresscard_url).getDownloadURL();
                                        let update_data = {
                                            ...data,
                                            createdAt: new Date().getTime(),
                                            updatedAt: new Date().getTime(),
                                            bankAccountImage: bankAccountImage,
                                            bankAccountImageUrl: bankAccountImageUrl,
                                            AddressCardImage: AddressCardImage,
                                            AddressCardImageUrl: AddressCardImageUrl,
                                            IDcardImage: IDcardImage,
                                            IDcardImageUrl: IDcardImageUrl,
                                            transferImage: transferImage,
                                            transferImageUrl: transferImageUrl
                                        }
                                        if (this.file_covid
                                            && this.file_covid[0]
                                            && this.file_covid[0].files
                                            && this.file_covid[0].files[0]) {
                                            await this.uploadFiles(this.file_covid, res.data.userUid, {
                                                onSuccess: async (image_covid_url) => {
                                                    let covidImageUrl = image_covid_url;
                                                    let covidImage = await storage.ref(image_covid_url).getDownloadURL();
                                                    update_data.covidImage = covidImage;
                                                    update_data.covidImageUrl = covidImageUrl;
                                                    this.registerProvider(update_data, data, res)
                                                }
                                            })
                                        } else {
                                            this.registerProvider(update_data, data, res)
                                        }







                                    },
                                    onError: () => {
                                        this.deleteAuth(data.phoneNumber, {
                                            reason: `อัพโหลดรูปบัญชีธนาคาร ไม่สำเร็จ`
                                        })
                                    }
                                })
                            },
                            onError: () => {
                                this.deleteAuth(data.phoneNumber, {
                                    reason: `อัพโหลดรูปทะเบียนบ้าน ไม่สำเร็จ`
                                })
                            }
                        })
                    },
                    onError: () => {
                        this.deleteAuth(data.phoneNumber, {
                            reason: `อัพโหลดรูปบัตรประชาชน ไม่สำเร็จ`
                        })
                    }
                })

            } else {
                submitBtn.removeClass('loading');
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: res.data.message,
                    icon: 'warning',
                    position: 'top-end',
                    toast: true,
                    backdrop: false,
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        }).catch(error => {
            submitBtn.removeClass('loading');
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณาลองใหม่อีกครั้ง',
                icon: 'error',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            // Helper.messageTop({ message: 'ไม่ทราบสาเหตุ' });
        });


        return;
        //remove check app
        // await db.collection('provider_users').where('phoneNumber', '==', phoneNumber).get().then((doc) => {
        //     doc.docs.forEach((element) => {
        //         _data.push({ ...element.data(), id: element.id });
        //         is_provider_users++;
        //     });
        //     // console.log(_data);
        // });
        //if (is_provider_users > 0) {
        // db.doc('provider_users_data/' + _data[0].id).set({ ...data }).then(() => {
        //     if (file) {
        //         this.uploadFile(file, _data[0].id)
        //     }
        // });
        // }
        // if (is_provider_users === 0) {
        //     db.collection(`provider_users_data`).add({
        //         ...data,
        //         createdAt: new Date().getTime(),
        //         updatedAt: new Date().getTime(),
        //     }).then(async (documentReference) => {
        //         db.doc(`provider_users_data/` + documentReference.id).get().then(async (doc) => {
        //             if (file) {
        //                 await this.uploadFile(file, documentReference.id)
        //             }
        //         });
        //     });
        // }
    }

    deleteAuth(phoneNumber, options) {
        let submitBtn = this.$form.find('button[type="submit"]');
        axios.post(window.api_host + '/deleteAuth', {
            phoneNumber: phoneNumber.replace('+66', '66'),
            whyDelete: 'ineedtodelete',
        }).then(() => {
            submitBtn.removeClass('loading');
            let reason = `เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง`;
            if (options && options.reason) {
                reason = options.reason;
            }
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: reason,
                icon: 'error',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            // Helper.messageTop({ message: reason });
        })
    }

    getAmphoe() {
        let apm = [];
        this.state.db.forEach((db) => {
            if (db.provinceCode === this.state.selected_province) {
                db.amphoe.forEach((amph, amph_i) => {
                    apm.push({ name: amph, code: db.amphoesCode[amph_i] });
                })
            }
        });
        if (this.state.selected_amphoe.length > 0) {
            apm = apm.filter((_apm) => {
                let count = 0;
                this.state.selected_amphoe.forEach((selected_amphoe) => {
                    if (_apm.code === selected_amphoe.code) {
                        count = 1;
                    }
                })
                return count === 0
            })
        }
        apm.sort()
        return apm;
    }

    async registerCheck(data) {
        let phoneNumber = '+66' + data.phoneNumber.substr(1, 9);

        let res = await axios.post(`${window.api_host}/provider-register-checked`, {
            phoneNumber: phoneNumber,
            data: data
        })
        if (res && res.data && res.data.error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: res.data.message,
                icon: 'warning',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            if (this.$submitBtn) {
                this.$submitBtn.removeClass('loading');
            }
            return;
        }
        this.add(data);
        // db.collection('provider_users_data').where('phoneNumber', '==', phoneNumber).get().then(async (doc) => {
        //     if (doc.size > 0) {
        //         Helper.messageTop({ message: 'มีผู้ใช้งานเบอร์โทรศัพทนี้แล้ว' });
        //         this.$submitBtn.removeClass('loading');
        //         return;
        //     }
        //     if (doc.size === 0) {
        //         db.collection('provider_users_data').where('personalNo', '==', data.personalNo).get().then(async (doc) => {
        //             if (doc.size > 0) {
        //                 Helper.messageTop({ message: 'เลขบัตรประจำตัวประชาชนนี้มีผู้ใช้งานแล้ว' });
        //                 this.$submitBtn.removeClass('loading');
        //                 return;
        //             }
        //             if (doc.size === 0) {
        //                 this.add(data);
        //             }
        //         });

        //     }
        // });
    }

    render() {
        const renderDate = () => {
            let days = window.$('.react-datepicker__day-names').find(".react-datepicker__day-name");
            let thai_days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];
            for (let index = 0; index <= days.length; index++) {
                window.$(days[index]).text(thai_days[index])
            }
        }
        const renderCustomHeader = ({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }) => {
            let now = new Date();
            // months_th
            const years = [];
            let i;
            for (i = 1980; i <= now.getFullYear(); i++) {
                years.push(i)
            }
            return (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center"
                    }}
                >

                    <Helmet>
                        {/* <meta name="apple-itunes-app" content="app-id=1540422458" /> */}
                        <meta name="apple-itunes-app" content="app-id=1540422113" />
                    </Helmet>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                        className={"btn btn-primary "}
                        type={"button"}
                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    >
                        <i className="fas fa-chevron-left" />
                    </button>
                    <select
                        className={"form-control text-th"}
                        defaultValue={now.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ borderRadius: 0 }}
                    >
                        {years.map(option => (
                            <option key={option} value={option} >
                                {option + 543}
                            </option>
                        ))}
                    </select>

                    <select
                        className={"form-control text-th"}
                        defaultValue={window.months_th[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(window.months_th.indexOf(value))
                        }
                        style={{ borderRadius: 0 }}
                    >
                        {window.months_th.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                        className={"btn btn-primary"}
                        style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        type={"button"}
                    >
                        <i className="fas fa-chevron-right" />
                    </button>
                </div>
            )
        }

        let hours = []
        for (let index = 0; index < 24; index++) {
            let number = index;
            if (number < 10) {
                number = '0' + number;
            }
            hours.push(number + '');
        }
        let minutes = []
        for (let index = 0; index < 59; index++) {
            let number = index;
            if (number < 10) {
                number = '0' + number;
            }
            minutes.push(number + '');
        }
        let nowDate = new Date()
        let maxBirthDay = new Date((nowDate.getFullYear() - 20), nowDate.getMonth(), nowDate.getDate())
        return (
            <div className='content-full booking-detail-content' style={{ backgroundColor: '#ffe8b6' }}>
                <div className='container pt-5 pb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-md-8'>
                            <div className='card' style={{ borderRadius: 20, backgroundColor: '#fff', borderWidth: 0 }}>
                                <div className='card-body register-wizard-box custom-form text-th'>
                                    {/* <div className="header">
                                        {
                                            this.state.is_first_form_show ?
                                                "ข้อมูลทั่วไป"
                                                : null
                                        }
                                        {
                                            this.state.is_second_form_show ?
                                                "ข้อมูลอบรม"
                                                : null
                                        }
                                    </div> */}
                                    <h4 className='mb-0' style={{ fontSize: 18, color: '#8e8c8c' }}>ข้อมูลทั่วไป</h4>
                                    <h3 style={{ fontSize: 26, color: '#231f20' }}>สมัครแม่บ้าน</h3>
                                    <form ref={ref => { this.$form = window.$(ref); }}
                                        onSubmit={(e) => {
                                            let submitBtn = this.$form.find('button[type="submit"]');
                                            if (submitBtn.hasClass('loading')) { return; }
                                            submitBtn.addClass('loading');
                                            e.preventDefault();

                                            // let data = { ...this.state.data };
                                            // let count_object = 0;

                                            let data = {};
                                            let count_object = 0;
                                            this.$form.serializeArray().map((field) => {
                                                if (field.value !== "") {
                                                    if (field.name !== "traningUID"
                                                        // || field.name === "amountTransferBySlip"
                                                        // || field.name === "transferDate"
                                                        // || field.name === "transferImage"
                                                        || field.name !== "hours"
                                                        || field.name !== "congenitalDisease"
                                                        || field.name !== "amphoe"
                                                    ) {
                                                        data[field.name] = field.value;
                                                    }
                                                }
                                            });
                                            let personalNo = "";
                                            if (!data.personalNo) {

                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'ระบุหมายเลขประจำตัวประชาชน',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                // Helper.messageTop({ message: 'ระบุหมายเลขประจำตัวประชาชน' });
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            data.personalNo.split(" ").map((no) => {
                                                personalNo += no;
                                                return;
                                            })

                                            // console.log(personalNo);
                                            // console.log(data);
                                            // console.log(count_object);
                                            if (data.phoneNumber && data.phoneNumber.length !== 10) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            if (this.state.selected_amphoe.length === 0) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'ระบุเขต/อำเภอ',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            data.provincesCode = [this.state.selected_province];
                                            data.amphoesCode = [];
                                            this.state.selected_amphoe.map((selected_amphoe) => {
                                                data.amphoesCode.push(selected_amphoe.code)
                                            })
                                            if (!personalNo || (personalNo && !Helper.chkDigitPid(personalNo))) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            if (data.iscongenitalDisease === 'มี' && (data.congenitalDisease === '' || !data.congenitalDisease)) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'ระบุโรคประจำตัว',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            if (
                                                // count_object < 12 
                                                !data.birthDay
                                                || !data.currentOccupation
                                                || !data.currentWorkplace
                                                || !data.firstName
                                                || !data.lastName
                                                || !data.gender
                                                || !data.isWorked
                                                || !data.lineID
                                                || !data.phoneNumber
                                                || !data.prefix
                                                || !data.iscongenitalDisease
                                                || !data.emergencyContactNumber
                                                || !data.prefix) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'กรอกข้อมูลให้ครบถ้วน',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            if (
                                                // count_object < 12 
                                                !data.bank
                                                || !data.bankBranch
                                                || !data.bankAccountName
                                                || !data.bankAccountNo
                                            ) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'กรอกข้อมูลธนาคาร',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            if (!this.file_image_addresscard
                                                || !this.file_image_addresscard[0]
                                                || !this.file_image_addresscard[0].files
                                                || !this.file_image_addresscard[0].files[0]
                                                || !this.file_image_idcard
                                                || !this.file_image_idcard[0]
                                                || !this.file_image_idcard[0].files
                                                || !this.file_image_idcard[0].files[0]
                                                || !this.file_image_bank
                                                || !this.file_image_bank[0]
                                                || !this.file_image_bank[0].files
                                                || !this.file_image_bank[0].files[0]
                                            ) {
                                                Swal.fire({
                                                    title: 'เกิดข้อผิดพลาด',
                                                    text: 'อัพโหลดเอกสารไม่ครบ',
                                                    icon: 'warning',
                                                    position: 'top-end',
                                                    toast: true,
                                                    backdrop: false,
                                                    showConfirmButton: false,
                                                    timer: 2500
                                                })
                                                this.$submitBtn.removeClass('loading');
                                                return;
                                            }
                                            // return;
                                            this.registerCheck(data);


                                            // if (count_object < 1) {
                                            //     // if (count_object < 3) {
                                            //     Helper.messageTop({ message: 'กรอกข้อมูลให้ครบถ้วน' });
                                            //     submitBtn.removeClass('loading');
                                            //     return;
                                            // }
                                            // let file = this.file[0].files[0];

                                            // if (!file) {
                                            //     Helper.messageTop({ message: 'กรุณาอัพโหลดหลักฐานการโอนเงิน' });
                                            //     submitBtn.removeClass('loading');
                                            //     return;
                                            // }
                                            // this.add(data);
                                        }}
                                    >
                                        {
                                            this.state.is_first_form_show ?
                                                <>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label style={{ textAlign: 'center', width: "100%" }}>บัตรประชาชน</label>
                                                                <input name="IDcardImage"
                                                                    type={"file"}
                                                                    ref={(ref) => this.file_image_idcard = window.$(ref)}
                                                                    style={{ display: 'none' }}
                                                                    id="file_image_idcard"
                                                                    onChange={(e) => {
                                                                        this.readURL(this.file_image_idcard);
                                                                    }}
                                                                />
                                                                <div id="show_image" />
                                                                <label htmlFor="file_image_idcard" className="btn login-button" style={{ width: '100%' }}>
                                                                    เลือกรูปภาพ
                                                                </label>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <small style={{ color: 'red' }}>**เอกสารต้องชัดและสามารถอ่านข้อความได้</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label style={{ textAlign: 'center', width: "100%" }}>ทะเบียนบ้าน</label>
                                                                <input name="AddressCardImage"
                                                                    type={"file"}
                                                                    ref={(ref) => this.file_image_addresscard = window.$(ref)}
                                                                    style={{ display: 'none' }}
                                                                    id="file_image_addresscard"
                                                                    onChange={(e) => {
                                                                        this.readURL(this.file_image_addresscard);
                                                                    }}
                                                                />
                                                                <div id="show_image" />
                                                                <label htmlFor="file_image_addresscard" className="btn login-button" style={{ width: '100%' }}>
                                                                    เลือกรูปภาพ
                                                                </label>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <small style={{ color: 'red' }}>**เอกสารต้องชัดและสามารถอ่านข้อความได้</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <InputMask
                                                            mask="9 9999 99999 99 9"
                                                            placeholder={"เลขบัตรประชาชน"}
                                                            style={{ textAlign: 'center' }}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                let personalNo = e.target.value;
                                                                this.$personalNo.val(personalNo)
                                                            }}
                                                        />
                                                        <input name={"personalNo"} type={"hidden"} ref={(ref) => { this.$personalNo = window.$(ref) }} />
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"col-sm-6"}>
                                                            <div className="form-group">
                                                                <select
                                                                    name={"prefix"}
                                                                    className="form-control placeholder_font"
                                                                    ref={(ref) => { this.$prefix = window.$(ref) }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "") {
                                                                            this.$prefix.addClass("placeholder_font");
                                                                        }
                                                                        if (e.target.value !== "") {
                                                                            this.$prefix.removeClass("placeholder_font");
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">คำนำหน้านาม</option>
                                                                    {/* <option value="นาย">นาย</option> */}
                                                                    <option value="นาง">นาง</option>
                                                                    <option value="นางสาว">นางสาว</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"row"}>
                                                        <div className={"col-sm-6"}>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    placeholder={"ชื่อ"}
                                                                    name={"firstName"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={"col-sm-6"}>
                                                            <div className="form-group">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    placeholder={"นามสกุล"}
                                                                    name="lastName"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group" style={{ display: 'none' }}>
                                                        <input type={"hidden"} name={"gender"} defaultValue={"หญิง"} />
                                                        {/* <select
                                        className="form-control placeholder_font"
                                        name={"gender"}
                                        ref={(ref) => { this.$gender = window.$(ref) }}
                                        defaultValue={"หญิง"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                this.$gender.addClass("placeholder_font");
                                            }
                                            if (e.target.value !== "") {
                                                this.$gender.removeClass("placeholder_font");
                                            }
                                        }}
                                    >
                                        <option value="">เพศ</option>
                                        <option value="ชาย">ชาย</option>
                                        <option value="หญิง">หญิง</option>
                                    </select> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder={"เบอร์โทรศัพท์"} name={"phoneNumber"} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder={"Line ID"} name={"lineID"} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>วันเดือนปีเกิด</label>
                                                        <DatePicker
                                                            onChange={date => {
                                                                this.setState({
                                                                    birth_day: date
                                                                })
                                                            }}
                                                            locale="th-TH"
                                                            selected={this.state.birth_day}
                                                            placeholderText={"วัน / เดือน / ปี"}
                                                            name={"birthDay"}
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            withPortal
                                                            maxDate={maxBirthDay}
                                                            onCalendarOpen={renderDate}
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled
                                                            }) => {

                                                                let now = new Date();
                                                                const years = [];
                                                                let i;
                                                                let selected_data = new Date(date);
                                                                for (i = (now.getFullYear() - maxAge); i <= (now.getFullYear() - minAge); i++) {
                                                                    years.push(i)
                                                                }
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            margin: 10,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}
                                                                    >
                                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                            className={"btn btn-primary "}
                                                                            type={"button"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                        >
                                                                            <i className="fas fa-chevron-left" />
                                                                        </button>
                                                                        <select
                                                                            className={"form-control"}
                                                                            defaultValue={parseFloat(selected_data.getFullYear())}
                                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {years.map(option => (
                                                                                <option key={option} value={option} >
                                                                                    {option + 543}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={window.months_th[parseFloat(selected_data.getMonth())]}
                                                                            onChange={({ target: { value } }) =>
                                                                                changeMonth(window.months_th.indexOf(value))
                                                                            }
                                                                            style={{ borderRadius: 0 }}
                                                                        >
                                                                            {window.months_th.map(option => (
                                                                                <option key={option} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                                                            className={"btn btn-primary"}
                                                                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                                            type={"button"}
                                                                        >
                                                                            <i className="fas fa-chevron-right" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }}
                                                            customInput={<CustomDateInput />}
                                                        />
                                                        <input type="hidden" name={"birthDay"} value={this.state.birth_day ? new Date(this.state.birth_day).getTime() : ''} onChange={() => { console.log("Change BirthDay") }} readOnly={true} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>จังหวัดที่ต้องการสมัคร</label>
                                                        <select className="form-control"
                                                            name={"province"}
                                                            defaultValue={this.state.selected_province}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    selected_province: e.target.value,
                                                                    selected_amphoe: [],
                                                                })
                                                            }}
                                                        >
                                                            {
                                                                this.state.db.map((item, item_i) => (
                                                                    <option value={item.provinceCode} key={item_i}>{item.province}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>พื้นที่เขตที่สะดวกรับงาน</label>
                                                        {
                                                            !this.state.selected_province ?
                                                                <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                                                : null
                                                        }
                                                        {
                                                            this.state.selected_province ?
                                                                <select className="form-control"
                                                                    name={"amphoe"}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "" || !e.target.value) { return; }
                                                                        let selected_amphoe = [...this.state.selected_amphoe];
                                                                        let _data = false;
                                                                        this.getAmphoe().map((amp, amp_i) => {
                                                                            if (amp.code === e.target.value) {
                                                                                _data = { ...amp }
                                                                            }
                                                                        })
                                                                        selected_amphoe.push(_data);
                                                                        this.setState({
                                                                            selected_amphoe: selected_amphoe,
                                                                        })
                                                                    }}
                                                                    value={""}
                                                                >
                                                                    <option value="">เลือกเขต/อำเภอ{this.state.selected_amphoe.length > 0 ? "เพิ่ม" : null}</option>
                                                                    {
                                                                        this.getAmphoe().map((amp, amp_i) => (
                                                                            <option value={amp.code} key={amp_i}
                                                                                onClick={() => {

                                                                                }}
                                                                            >{amp.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.selected_amphoe.length > 0 ?
                                                            <div className="form-group tag-label">
                                                                {
                                                                    this.state.selected_amphoe.map((selected_amphoe, selected_amphoe_i) => {
                                                                        return (
                                                                            <div key={selected_amphoe_i}>
                                                                                <i className="fas fa-times"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        let _selected_amphoe = [...this.state.selected_amphoe];
                                                                                        _selected_amphoe.splice(selected_amphoe_i, 1);
                                                                                        this.setState({
                                                                                            selected_amphoe: _selected_amphoe
                                                                                        })
                                                                                    }}
                                                                                ></i> {selected_amphoe.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                    <div className='row'>
                                                        <div className='col-md-12'><label>บัญชีธนาคาร</label></div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <select className="form-control" name={"bank"} required={true} defaultValue={this.state.bank_selected}>
                                                                    <option value={""}>เลือกธนาคาร</option>
                                                                    {
                                                                        this.state.bank_list.map((bank, bank_i) => {
                                                                            return (
                                                                                <option key={bank_i} value={bank}>{bank}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <input name="bankBranch" className="form-control" required={true} placeholder='สาขา' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <input name="bankAccountName" className="form-control" required={true} placeholder='ชื่อบัญชี' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <input name="bankAccountNo" className="form-control" required={true} placeholder='เลขบัญชี' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group" >
                                                                <label style={{ textAlign: 'center', width: "100%" }}>บัญชีธนาคาร</label>
                                                                <input name="bankAccountImage"
                                                                    type={"file"}
                                                                    ref={(ref) => this.file_image_bank = window.$(ref)}
                                                                    style={{ display: 'none' }}
                                                                    id="file_image_bank"
                                                                    onChange={(e) => {
                                                                        this.readURL(this.file_image_bank);
                                                                    }}
                                                                />
                                                                <div id="show_image" />
                                                                <label htmlFor="file_image_bank" className="btn login-button" style={{ width: '100%' }}>
                                                                    เลือกรูปภาพ
                                                                </label>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <small style={{ color: 'red' }}>**เอกสารต้องชัดและสามารถอ่านข้อความได้</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder={"อาชีพปัจจุบัน"} name={"currentOccupation"} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder={"สถานที่ทำงานปัจจุบัน"} name={"currentWorkplace"} />
                                                    </div>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>โรคประจำตัว</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease1"
                                                                defaultValue="ไม่มี"
                                                                defaultChecked={true}
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.hide();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease1">ไม่มี</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                name="iscongenitalDisease"
                                                                id="iscongenitalDisease2" defaultValue="มี"
                                                                onChange={(e) => {
                                                                    this.congenitalDisease.show();
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="iscongenitalDisease2">มี</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" style={{ display: 'none' }} className="form-control" placeholder={"ระบุโรคประจำตัว"} name={"congenitalDisease"} ref={(ref) => { this.congenitalDisease = window.$(ref) }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>เคยทำงานแม่บ้านมาก่อนหรือไม่</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isWorked" id="isWorked1" defaultValue="เคย" defaultChecked={true} />
                                                            <label className="form-check-label" htmlFor="isWorked1">เคย</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isWorked" id="isWorked2" defaultValue="ไม่เคย" />
                                                            <label className="form-check-label" htmlFor="isWorked2">ไม่เคย</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder={"เบอร์ติดต่อฉุกเฉิน"} name={"emergencyContactNumber"} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>คุณได้รับการฉีดวัคซีน Covid-19 แล้วหรือไม่ ?</label>
                                                        <select className="form-control" name="covid-19">
                                                            <option value="ยังไม่ได้ฉีด">ยังไม่ได้ฉีด</option>
                                                            <option value="ฉีดแล้ว 1 เข็ม">ฉีดแล้ว 1 เข็ม</option>
                                                            <option value="ฉีดแล้ว 2 เข็ม">ฉีดแล้ว 2 เข็ม</option>
                                                            <option value="ฉีดแล้ว 3 เข็ม">ฉีดแล้ว 3 เข็ม</option>
                                                            <option value="ฉีดแล้ว 4 เข็ม">ฉีดแล้ว 4 เข็ม</option>
                                                        </select>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group" >
                                                                <label style={{ textAlign: 'center', width: "100%" }}>เอกสารการฉีดวัคซีน</label>
                                                                <input name="bankAccountImage"
                                                                    type={"file"}
                                                                    ref={(ref) => this.file_covid = window.$(ref)}
                                                                    style={{ display: 'none' }}
                                                                    id="file_covid"
                                                                    onChange={(e) => {
                                                                        this.readURL(this.file_covid);
                                                                    }}
                                                                />
                                                                <div id="show_image" />
                                                                <label htmlFor="file_covid" className="btn login-button" style={{ width: '100%' }}>
                                                                    เลือกรูปภาพ
                                                                </label>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <small style={{ color: 'red' }}>**เอกสารต้องชัดและสามารถอ่านข้อความได้</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        {
                                            this.state.is_second_form_show ?
                                                <>
                                                    <div className="form-group">
                                                        <input name={"traningUID"} type="hidden" value={"9OdBztgSPOwmm7mYIfEd"} />
                                                    </div>


                                                    <div className="form-group">
                                                        อบรมออนไลน์ ส่งเอกสารทางไลน์ @BEEfastjob ดังนี้ สำเนาทะเบียนบ้าน สำเนาบัตรประชาชน หน้าสมุดบัญชี เอกสารการฉีดวัคซีน
                                                    </div>



                                                    <div className={"form-group"} style={{ display: 'none' }}>
                                                        <div>
                                                            โอนเงินจองการอบรมจำนวน 350 บาท
                                                        </div>
                                                        <div>
                                                            โอนผ่านบัญชีธนาคาร
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-2">
                                                                <img src="./assets/images/bank.png" style={{ maxWidth: '100%' }} alt="Bank" />
                                                            </div>
                                                            <div className="col-sm-10">
                                                                บจก. วี ดีเวลลอป
                                                                สาขาโลตัส วังหิน<br />
                                                                ธนาคารกสิกรไทย<br />
                                                                เลขบัญชี 063-1-41389-7
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group" style={{ display: 'none' }}>
                                                        <label>ยอดโอนตามสลิป</label>
                                                        <input type="number" className="form-control" placeholder={"เฉพาะตัวเลขเท่านั้น"} name="amountTransferBySlip" />
                                                    </div>
                                                    <div className={"form-group"} style={{ display: 'none' }}>
                                                        <div className={"row"}>
                                                            <div className={"col-sm-6"}>
                                                                <label>วันที่โอนตามสลิป</label>
                                                                <DatePicker
                                                                    className="form-control"
                                                                    onChange={date => {

                                                                        this.setState({
                                                                            slip_day: date
                                                                        })
                                                                    }}
                                                                    selected={this.state.slip_day}
                                                                    placeholderText={"วัน / เดือน / ปี"}
                                                                    name={"educateDate"}
                                                                    dateFormat="MMMM d, yyyy"
                                                                    withPortal
                                                                    onCalendarOpen={renderDate}
                                                                    renderCustomHeader={renderCustomHeader}
                                                                    customInput={<CustomDateInput />}
                                                                />
                                                                <input type="hidden" name={"transferDate"} value={new Date(this.state.slip_day).getTime()} />
                                                            </div>
                                                            <div className={"col-sm-3"}>
                                                                <label>เวลาที่โอนตามสลิป</label>
                                                                <select className={"form-control"} name="hours"
                                                                    defaultValue={this.state.hour}
                                                                >
                                                                    {
                                                                        hours.map((hour, hour_i) => (
                                                                            <option value={hour} key={hour_i}>{hour}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className={"col-sm-3"}>
                                                                <label>&nbsp; </label>
                                                                <select className={"form-control"} name="hours"
                                                                    defaultValue={this.state.minute}
                                                                >
                                                                    {
                                                                        minutes.map((minute, minute_i) => (
                                                                            <option value={minute} key={minute_i}>{minute}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                            <input type={"hidden"} name="transferTime" value={this.state.hour + ':' + this.state.minute} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group" style={{ display: 'none' }}>
                                                        <label>โอนสลิป</label>
                                                        <input name="transferImage"
                                                            type={"file"}
                                                            ref={(ref) => this.file = window.$(ref)}
                                                            style={{ display: 'none' }}
                                                            id="file_image"
                                                            onChange={(e) => {
                                                                this.readURL(this.file[0]);
                                                            }}
                                                        />
                                                        <div id="show_image" />
                                                        <label htmlFor="file_image" className="btn btn-primary">
                                                            เลือกรูปภาพ
                                                        </label>
                                                    </div>



                                                    <div className="form-group" style={{ color: 'red' }}>
                                                        หมายเหตุ<br />
                                                        ต้องเตรียมเอกสาร<br />
                                                        1. สำเนาบัตรประชาชน<br />
                                                        2. สำเนาทะเบียนบ้าน<br />
                                                        3. หน้าสมุดบัญชีธนาคาร<br />
                                                        4. เอกสารการฉีดวัคซีน
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <button
                                                            ref={(ref) => { this.$submitBtn = window.$(ref) }}
                                                            className="btn btn-service ml-2"
                                                            type={"submit"}
                                                        >
                                                            ยืนยันการสมัคร
                                                        </button>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </form>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        );
    }
}