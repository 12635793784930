import React from 'react';

export default class Loading extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                {/* <span className="spinner-border spinner-border-md align-middle mr-3"></span> */}
                <div className="ui active inline small loader mr-2" ></div>
                <h4 className='mb-0' style={{ fontWeight: 'bold' }}> กำลังโหลดข้อมูล...</h4>
            </div>
        )
    }
}