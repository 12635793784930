import { navigate } from '@reach/router';
import React from 'react';
import Swal from 'sweetalert2';
import { testApiCall } from './../../Services/TestServices'
import { Helper } from '../../Services/Helper';

export default class Login extends React.Component {


    componentDidMount() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (currentUser) {
            window.location.href = '/dashboard'
        }
    }

    async onSubmit(data) {
        // let res = await testApiCall();
        // console.log(`testApiCall`, res)

        const fullPhoneNumber = "66" + data.phoneNumber;

        var phoneNoSpace = fullPhoneNumber.replace(/\s/g, '');
        if (phoneNoSpace.length === 12) {
            var str = phoneNoSpace;
            phoneNoSpace = str.slice(0, 2) + str.slice(3);
        } else if (phoneNoSpace.length === 13) {
            phoneNoSpace = str.slice(0, 2) + str.slice(4);
        }
        console.log(phoneNoSpace)
        navigate(`/login/confirm?phoneNumber=${phoneNoSpace}`)
    }

    render() {
        return (
            <>
                <div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}>
                    <div>
                        <form
                            className='login-box mb-4'

                            ref={(ref) => {
                                this.$form = window.$(ref)
                                this.submit = this.$form.find(`button[type="submit"]`)
                            }}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (this.submit.hasClass('loading')) { return false; }
                                this.submit.addClass('loading');
                                let data = Helper.getFormInputObject(this.$form);
                                if (!data.phoneNumber) {
                                    Swal.fire({
                                        // title: `Warning`,
                                        icon: 'warning',
                                        html: 'กรุณากรอกเบอร์โทรศัพท์',
                                    })
                                    this.submit.removeClass('loading');
                                    return;
                                }
                                if (data.phoneNumber
                                    && (data.phoneNumber.length < 9 || data.phoneNumber.length > 10)) {
                                    Swal.fire({
                                        // title: `Warning`,
                                        icon: 'warning',
                                        html: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง' + data.phoneNumber.length,
                                    })
                                    this.submit.removeClass('loading');
                                    return;
                                }
                                data.phoneNumber = data.phoneNumber.replace(' ', '');
                                data.phoneNumber = data.phoneNumber.replace('-', '');
                                this.onSubmit(data);

                            }}
                        >
                            <h2 className='title mb-4'>ยินดีต้อนรับ</h2>
                            <p className='mb-1 text-center'>กรุณากรอกหมายเลขโทรศัพท์ของท่าน</p>
                            <div className='d-flex justify-content-center align-items-center pl-3 pr-3 mb-3'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="las la-phone mr-1"></i>+66</span>
                                    </div>
                                    <input type="text" className="form-control" name={"phoneNumber"} style={{ letterSpacing: 5 }} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mb-4'>
                                <button
                                    type={"submit"}
                                    className={`submit-btn btn`}
                                >
                                    ถัดไป
                                    <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </form>
                        <p style={{ fontSize: 14, color: '#999', textAlign: 'center' }}>หรือ ลงทะเบียนด้วยบัญชี</p>
                        <div className='d-flex justify-content-center align-items-center mb-4 social-list'>
                            <div>
                                <div className='social-circle facebook mb-1'>
                                    <i className="fa-brands fa-facebook-f"></i>
                                </div>
                                <p className='mb-0' style={{ fontSize: 10, color: '#999', textAlign: 'center' }}>Facebook</p>
                            </div>
                            <div>
                                <div className='social-circle google mb-1'>
                                    <i className="fa-brands fa-google"></i>
                                </div>
                                <p className='mb-0' style={{ fontSize: 10, color: '#999', textAlign: 'center' }}>Google</p>
                            </div>
                            <div>
                                <div className='social-circle apple mb-1'>
                                    <i className="fa-brands fa-apple"></i>
                                </div>
                                <p className='mb-0' style={{ fontSize: 10, color: '#999', textAlign: 'center' }}>Apple</p>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}