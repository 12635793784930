import React from 'react';
import {
    updateDoc,
} from "firebase/firestore"
import { _getCol } from '../Services/firebase';
import { Helper } from '../Services/Helper';
const AndroidURL = "https://play.google.com/store/apps/details?id=com.playonline.cleaning.user";
const IOSURL = "https://apps.apple.com/us/app/beeclean-%E0%B9%80%E0%B8%A3-%E0%B8%A2%E0%B8%81%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99/id1540422458";
export default class Download extends React.Component {
    componentDidMount() {
        this.checkRefferal();
        setTimeout(() => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                console.log('android')
                window.location.href = AndroidURL;
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('ios')
                window.location.href = IOSURL;
            }
        }, 1000);
    }

    componentDidMount() {
        this.checkRefferal();
    }

    async checkRefferal() {
        let refferalCode = Helper.getParameterByName(`refferal`) || false;
        if (refferalCode) {

            let getRefferal = await _getCol('refferalLink')
            if (!getRefferal.empty) {
                for (const doc of getRefferal.docs) {
                    let _data = doc.data();
                    if (_data.code === refferalCode) {
                        let count = _data.view || 0;
                        count += 1
                        await updateDoc(doc.ref, { view: count })
                    }
                }
            }
        }
    }

    render() {
        return (
            <div className={"page_content polygon-bg bg-bottom-left"} style={{ height: '100%' }}>
                <div className="container pt-5 pb-5 text-th ">
                    <div className='row'>
                        <div className='col-12 text-th home-cover-info d-flex justify-content-center align-items-center'>
                            <div>
                                <img src={`${window.location.origin}/assets/images/logo-horizontal.png`} className={"mb-3"} style={{ height: 40 }} alt={"beeclean logo"} />
                                <h2 className='mb-4'>BEEclean บริการเรียกแม่บ้านออนไลน์มืออาชีพ</h2>
                                <p className='mb-0'>รับสมัครแม่บ้านและให้บริการทำความสะอาดผ่านแอพพลิเคชั่น</p>
                                <p className='mb-5'>สามารถดาวน์โหลด Application ได้แล้ววันนี้</p>
                                <div className='d-flex'>
                                    <a href={"https://play.google.com/store/apps/details?id=com.playonline.cleaning.user"} className='mr-5' target={"_blank"} rel="noreferrer">
                                        <img src={`${window.location.origin}/assets/images/android-btn.png`} className={'hover-opacity'} alt={"beeclean android"} />
                                    </a>
                                    <a href={"https://apps.apple.com/us/app/beeclean-%E0%B9%80%E0%B8%A3-%E0%B8%A2%E0%B8%81%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99/id1540422458"} target={"_blank"} rel="noreferrer">
                                        <img src={`${window.location.origin}/assets/images/apple-btn.png`} className={'hover-opacity'} alt={`beeclean ios`} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}