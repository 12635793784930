import React from 'react';
import { Helper } from '../Services/Helper';
const axios = require('axios').default;

export default class OmiseComplete extends React.Component {

    constructor() {
        super();
        this.state = {
            status: "Loading...",
            // status: "payment fail",
            isLoading: true,
            openedWindow: null
        }
    }

    // openWindow = () => {
    //     this.state.openedWindow = window.open('moreinfo.htm');
    // }

    // closeOpenedWindow = () => {
    //     this.state.openedWindow.close();
    // }

    componentDidMount() {

        // this.setState({ isLoading: true });
        // if (!Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime') && !Helper.getParameterByName('voidUid')) {
        //     this.setState({
        //         status: "unknown",
        //         isLoading: false
        //     })
        // }
        // if (Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime')) {
        //     axios.post(window.api_host + '/omise-complete', {
        //         bookUid: Helper.getParameterByName('bookUid')
        //     }).then(async res => {
        //         if (res.data.code == 200) {
        //             this.setState({ status: "payment success" })
        //         } else if (res.data.code == 201) {
        //             this.setState({ status: "payment fail" })
        //         } else {
        //             this.setState({ status: "unknown" })
        //         }
        //         this.setState({ isLoading: false });
        //         window.close();
        //     }).catch(error => {
        //         console.log(error.response)
        //         this.setState({ status: "error" })
        //         this.setState({ isLoading: false });
        //         window.close();
        //     });
        // }
        // if (Helper.getParameterByName('voidUid')) {
        //     axios.post(window.api_host + '/omise-complete', {
        //         voidUid: Helper.getParameterByName('voidUid')
        //     }).then(async res => {
        //         if (res.data.code == 200) {
        //             this.setState({ status: "payment success" })
        //         } else if (res.data.code == 201) {
        //             this.setState({ status: "payment fail" })
        //         } else {
        //             this.setState({ status: "unknown" })
        //         }
        //         this.setState({ isLoading: false });
        //         window.close();
        //     }).catch(error => {
        //         console.log(error.response)
        //         this.setState({ status: "error" })
        //         this.setState({ isLoading: false });
        //         window.close();
        //     });
        // }

        // if (Helper.getParameterByName('plusTime')) {
        //     axios.post(window.api_host + '/omise-complete', {
        //         bookPlusTimeUid: Helper.getParameterByName('bookPlusTimeUid'),
        //         plusTime: true,
        //         isNew: false
        //     }).then(async res => {
        //         if (res.data.code == 200) {
        //             this.setState({ status: "payment success" })
        //         } else if (res.data.code == 201) {
        //             this.setState({ status: "payment fail" })
        //         } else {
        //             this.setState({ status: "unknown" })
        //         }
        //         this.setState({ isLoading: false });
        //         window.close();
        //     }).catch(error => {
        //         console.log(error.response)
        //         this.setState({ status: "error" })
        //         this.setState({ isLoading: false });
        //         window.close();
        //     });
        // }

        this.setState({
            isLoading: true
        }, () => {
            if (!Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime') && !Helper.getParameterByName('voidUid')) {
                this.setState({
                    status: "unknown",
                    isLoading: false
                })
            }
            if (Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime')) {
                axios.post(window.api_host + '/omise-complete', {
                    bookUid: Helper.getParameterByName('bookUid')
                }).then(async res => {
                    let status = 'unknown';
                    if (res.data.code == 200) {
                        status = "payment success"
                    } else if (res.data.code == 201) {
                        status = "payment fail"
                    }
                    this.setState({
                        isLoading: false,
                        status: status,
                    });
                    window.close();
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        status: "error",
                        isLoading: false,
                    })
                    window.close();
                });
            }
            if (Helper.getParameterByName('voidUid')) {
                axios.post(window.api_host + '/omise-complete', {
                    voidUid: Helper.getParameterByName('voidUid')
                }).then(async res => {
                    let status = 'unknown';
                    if (res.data.code == 200) {
                        status = "payment success"
                    } else if (res.data.code == 201) {
                        status = "payment fail"
                    }
                    this.setState({
                        isLoading: false,
                        status: status,
                    });
                    window.close();
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        status: "error",
                        isLoading: false,
                    })
                    window.close();
                });
            }

            if (Helper.getParameterByName('plusTime')) {
                axios.post(window.api_host + '/omise-complete', {
                    bookPlusTimeUid: Helper.getParameterByName('bookPlusTimeUid'),
                    plusTime: true,
                    isNew: false
                }).then(async res => {
                    let status = 'unknown';
                    if (res.data.code == 200) {
                        status = "payment success"
                    } else if (res.data.code == 201) {
                        status = "payment fail"
                    }
                    this.setState({
                        isLoading: false,
                        status: status,
                    });
                    window.close();
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        status: "error",
                        isLoading: false,
                    })
                    window.close();
                });
            }
        });


    }

    closer() {

    }

    linktoapp() {
        // window.opener = null;
        // window.open('', '_self');
        // window.close();
        window.location.href = "beecleanuser://beeclean";
        setTimeout(
            function () {
                window.location.href = "https://beeclean-frontend.web.app/home";
            }
                .bind(this),
            3000
        );
    }

    renderTitle() {
        if (this.state.status == 'payment success') {
            return (
                <>
                    <div>
                        <div style={{ color: 'green' }}>ผูกบัตรสำเร็จ</div>
                        <small style={{ color: 'green', opacity: 0.7 }}>add card successfully</small><br />
                        <small style={{ opacity: 0.7 }}>โปรดกลับไปเพื่อทำการจองใหม่อีกครั้ง</small>
                    </div>
                </>
            )
        }
        if (this.state.status == 'payment fail') {
            return (
                <>
                    <div>
                        <div style={{ color: 'red' }}>ผูกบัตรไม่สำเร็จ</div>
                        <small style={{ color: 'red', opacity: 0.7 }}>add card Failed</small><br />
                        <small style={{ opacity: 0.7 }}>โปรดกลับไปเพื่อทำการผูกบัตรใหม่อีกครั้ง</small>
                    </div>
                </>
            )
        }
        if (this.state.status == 'unknown') {
            return (
                <>
                    <div>
                        <div style={{ color: 'red' }}>ผูกบัตรผิดพลาด</div>
                        <small style={{ color: 'red', opacity: 0.7 }}>add card Error</small><br />
                        {/* <small style={{ opacity: 0.7 }}>กรุณาติดต่อผู้ทำระบบ</small> */}
                    </div>
                </>
            )
        }
        return;
    }


    render() {
        return (
            <>
                <div className="container content-bg omise-complete-ui pt-5 mt-5">
                    <div className={"title text-center mb-3 fs-3"}>
                        {this.state.isLoading ? <div className={"ui loader inline active"} style={{ top: -5 }} /> : null}
                        {
                            !this.state.isLoading ?
                                this.renderTitle()
                                : null
                        }
                    </div>
                    {
                        this.state.status == 'payment success' ?
                            <div style={{ textAlign: 'center', marginBottom: 25 }}>

                                <img src={window.location.origin + "/assets/images/15.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />

                            </div>
                            : null
                    }
                    {
                        this.state.status == 'payment fail' ?
                            <div style={{ textAlign: 'center', marginBottom: 25 }}>

                                <img src={window.location.origin + "/assets/images/29.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />

                            </div>
                            : null
                    }
                    {
                        this.state.status == 'unknown' ?
                            <div style={{ textAlign: 'center', marginBottom: 25 }}>

                                <img src={window.location.origin + "/assets/images/19.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />

                            </div>
                            : null
                    }
                    {!this.state.isLoading ? <div className='d-flex justify-content-center'><button className={"btn login-button"} style={{ width: 'auto' }} onClick={this.linktoapp}>กดเพื่อกลับไปยัง application</button></div> : null}
                </div>
            </>
        );
    }
}