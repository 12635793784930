import React from "react";
import { Helper } from './../../../Services/Helper'
const textRed = `#B65153`;
export default class Info extends React.Component {
    render() {
        let item = this.props.data;
        let starFill = [];
        let star = []
        for (let index = 1; index <= parseFloat(item.star); index++) {
            starFill.push(index)
        }
        for (let index = 1; index <= (5 - parseFloat(item.star)); index++) {
            star.push(index)
        }
        return (
            <div className="service-info pt-3 pb-3">
                <h2>{this.props.data.name}</h2>
                <div className="mb-3">
                    <span className="mr-2">
                        {
                            starFill.map((s, i) => <i key={i} className="fa-solid fa-star" style={{ color: '#FCB822' }}></i>)
                        }
                        {
                            star.map((s, i) => <i key={i} className="fa-solid fa-star" style={{ color: '#E5E7E7' }}></i>)
                        }
                    </span>
                    Rating {this.props.data.star} <span style={{ color: textRed }}>(รีวิว {Helper.numberFormat(this.props.data.countReview)})</span> ใช้บริการไปแล้ว <span style={{ color: textRed }}>{Helper.numberFormat(this.props.data.countService)}</span> ครั้ง
                </div>
                <p>{this.props.data.description}</p>
                <h5>รายละเอียดของบริการ</h5>
                <ul className="mb-0">
                    {
                        this.props.data.descriptions.map((des, i) => {
                            return (
                                <li key={i}>{des}</li>
                            )
                        })
                    }
                </ul>

            </div>
        )
    }
}