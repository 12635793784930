import React from 'react';
import { Helper } from '../Services/Helper';
const axios = require('axios').default;

export default class OmiseComplete extends React.Component {

    constructor() {
        super();
        this.state = {
            status: "Loading ...",
            // status: "payment fail",
            isLoading: true,
            failure_code: false,
            failure_message: false,
            openedWindow: null
        }
    }

    // openWindow = () => {
    //     this.state.openedWindow = window.open('moreinfo.htm');
    // }

    // closeOpenedWindow = () => {
    //     this.state.openedWindow.close();
    // }

    componentDidMount() {

        // this.setState({ isLoading: true });
        if (!Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime') && !Helper.getParameterByName('voidUid')) {
            this.setState({
                status: "unknown",
                isLoading: false
            })
        }
        if (Helper.getParameterByName('bookUid') && !Helper.getParameterByName('plusTime')) {
            axios.post(window.api_host + '/omise-complete', {
                bookUid: Helper.getParameterByName('bookUid')
            }).then(async res => {
                let failure_code = false;
                let failure_message = false;
                if (res.data.failure_code) {
                    failure_code = res.data.failure_code
                }
                if (res.data.failure_message) {
                    failure_message = res.data.failure_message
                }
                let status = "unknown";
                if (res.data.code == 200) {
                    status = "payment success";
                    // this.setState({ status: "payment success" })
                } else if (res.data.code == 201) {
                    status = "payment fail";
                    // this.setState({ status: "payment fail" })
                }
                this.setState({
                    isLoading: false,
                    status: status,
                    failure_code: failure_code,
                    failure_message: failure_message,
                });
                window.close();
            }).catch(error => {
                console.log(error.response)
                this.setState({
                    isLoading: false,
                    status: "error"
                });
                window.close();
            });
        }
        if (Helper.getParameterByName('voidUid')) {
            axios.post(window.api_host + '/omise-complete', {
                voidUid: Helper.getParameterByName('voidUid')
            }).then(async res => {
                let failure_code = false;
                let failure_message = false;
                if (res.data.failure_code) {
                    failure_code = res.data.failure_code
                }
                if (res.data.failure_message) {
                    failure_message = res.data.failure_message
                }
                let status = "unknown";
                if (res.data.code == 200) {
                    status = "payment success";
                    // this.setState({ status: "payment success" })
                } else if (res.data.code == 201) {
                    status = "payment fail";
                    // this.setState({ status: "payment fail" })
                }
                this.setState({
                    isLoading: false,
                    status: status,
                    failure_code: failure_code,
                    failure_message: failure_message,
                });
                window.close();
            }).catch(error => {
                console.log(error.response)
                this.setState({
                    isLoading: false,
                    status: "error"
                });
                window.close();
            });
        }

        if (Helper.getParameterByName('plusTime')) {
            axios.post(window.api_host + '/omise-complete', {
                bookPlusTimeUid: Helper.getParameterByName('bookPlusTimeUid'),
                plusTime: true,
                isNew: false
            }).then(async res => {
                let failure_code = false;
                let failure_message = false;
                if (res.data.failure_code) {
                    failure_code = res.data.failure_code
                }
                if (res.data.failure_message) {
                    failure_message = res.data.failure_message
                }
                let status = "unknown";
                if (res.data.code == 200) {
                    status = "payment success";
                    // this.setState({ status: "payment success" })
                } else if (res.data.code == 201) {
                    status = "payment fail";
                    // this.setState({ status: "payment fail" })
                }
                this.setState({
                    isLoading: false,
                    status: status,
                    failure_code: failure_code,
                    failure_message: failure_message,
                });
                window.close();
            }).catch(error => {
                console.log(error.response)
                this.setState({
                    isLoading: false,
                    status: "error"
                });
                window.close();
            });
        }
    }

    closer() {

    }

    linktoapp() {
        // window.opener = null;
        // window.open('', '_self');
        // window.close();
        window.location.href = "beecleanuser://beeclean";
        setTimeout(
            function () {
                window.location.href = "https://beeclean-frontend.web.app/home";
            }
                .bind(this),
            3000
        );
    }




    render() {
        return (
            <>
                <div className="container content-bg omise-complete-ui mt-5 pt-3">
                    <div className={"title text-center mb-3 fs-3"}>{this.state.isLoading ? <div className={"ui loader inline active"} style={{ top: -5 }} /> : null} {this.state.status}</div>
                    {
                        this.state.status ?
                            <div style={{ textAlign: 'center', marginBottom: 25 }}>
                                {
                                    this.state.status === "payment success" ?
                                        <img src={window.location.origin + "/assets/images/15.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                        : null
                                }
                                {
                                    this.state.status === "unknown" || this.state.status === "error" ?
                                        <img src={window.location.origin + "/assets/images/29.png"} alt={"Error Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                        : null
                                }
                                {
                                    this.state.status === "payment fail" ?
                                        <img src={window.location.origin + "/assets/images/19.png"} alt={"Fail Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                        : null
                                }

                            </div>
                            : null
                    }
                    {this.state.failure_code ? <p className='text-center mb-0' style={{ fontSize: 20 }}><strong>Failure Code :</strong> {this.state.failure_code}</p> : null}
                    {this.state.failure_message ? <p className='text-center ' style={{ fontSize: 20 }}><strong>Failure Message :</strong> {this.state.failure_message}</p> : null}
                    {!this.state.isLoading ? <div className='d-flex justify-content-center'><button className={"btn login-button"} style={{ width: 'auto' }} onClick={this.linktoapp}>กดเพื่อกลับไปยัง application</button></div> : null}
                </div>
            </>
        );
    }
}