
import axios from 'axios';

const config = {
    'Content-Type': 'application/x-www-form-urlencoded'
};
export const getChannels = async () => {

    let url = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app/wegate-merchant-channel';

    try {
        //const res = await axios.post(url, { ...data }, config);
        const res = await axios.get(url, config);
        return { success: true, ...res.data };
    } catch (error) {
        return { error: true, ...error };
    }
}
