import React from 'react';
import { Link, navigate } from '@reach/router';
import { Helper } from '../Services/Helper';
import Swal from 'sweetalert2';

export default class DashboardMenu extends React.Component {
    signOut() {
        Helper.removeStorage('loginuser')
        Helper.removeStorage('cart')
        Helper.removeStorage('items')
        navigate('/login')
        if (window.headerRef && window.headerRef.current) {
            window.headerRef.current.checkLoginUser();
        }
    }
    render() {
        return (
            <>
                <div className="dashboard-menu-box mb-5">
                    <Link to={"/dashboard"} className={this.props.active == 'dashboard' ? 'active' : ''}>
                        <i className="las la-user"></i> แดชบอร์ด
                    </Link>
                    {/* <Link to={"/service/order"} className={this.props.active == 'service' ? 'active' : ''} >
                        <i className="las la-broom"></i> เรียกบริการ
                    </Link> */}
                    <Link to={"/service/history"} className={this.props.active == 'history' ? 'active' : ''} >
                        <i className="las la-history"></i> ประวัติการใช้บริการ
                    </Link>
                    <Link to={"/my-place"} className={this.props.active == 'place' ? 'active' : ''}>
                        <i className="las la-map-marked-alt"></i> สถานที่ของฉัน
                    </Link>
                    <Link to={"/my-card"} className={this.props.active == 'card' ? 'active' : ''}>
                        <i className="las la-credit-card"></i> เครดิตการ์ดของฉัน
                    </Link>
                    <button
                        onClick={() => {
                            Swal.fire({
                                title: 'ยืนยันการออกจากระบบ',
                                text: "ต้องการออกจากใช่หรือไม่ ?",
                                icon: 'question',
                                reverseButtons: true,
                                showCancelButton: true,
                                confirmButtonText: 'ออกจากระบบ',
                                cancelButtonText: 'ยกเลิก',
                                customClass: {
                                    confirmButton: 'btn btn-service',
                                    cancelButton: 'btn btn-white'
                                },
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.signOut();
                                }
                            })
                        }}
                    >
                        <i className="las la-sign-out-alt"></i> ออกจากระบบ
                    </button>
                    <img src={`${window.location.origin}/assets/img/beeclipart.png`} alt={"beeclean logo"} className="info-clippart " />
                </div>
            </>
        )
    }
}