import { Link, navigate } from '@reach/router';
import React, { Component } from 'react'
import { getBooks, requestInvoice } from '../../Services/taxInvoiceServices';
import Swal from 'sweetalert2';
import Loading from '../../Components/Loading';
import { Helper } from '../../Services/Helper';

export default class TaxInvoiceForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            data: [],
            user: false,
        }
        this.BooksSelector = React.createRef();
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        if (!this.props.phoneNumber) {
            navigate('/request/tax-invoice');
            return;
        }
        let qs = {};
        qs.phoneNumber = this.props.phoneNumber;
        let res = await getBooks(qs);

        if (res.error) {
            let message = 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง';
            if (res?.response?.data?.message) {
                message = res.response.data.message;
            }
            Swal.fire({
                // title: `Warning`,
                icon: 'warning',
                html: message,
            })

            navigate('/request/tax-invoice')
        }

        let data = res.data || [];
        let user = res.user || false;
        this.setState({
            data,
            user,
            isLoading: false,
        })
    }

    async onSubmit(data) {
        let booksSeletedUids = this.BooksSelector.current.selectedBooksId();
        if (booksSeletedUids.length == 0) {
            Swal.fire({
                // title: `Warning`,
                icon: 'warning',
                html: `กรุณาเลือก Book ที่จะทำการขอใบกำกับภาษี`,
            })
            this.$submit.removeClass('loading');
            return;
        }
        Swal.fire({
            title: 'กรุณารอสักครู่...',
            html: '<b>ระบบกำลังทำการส่งคำขอ</b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })
        data.booksUid = booksSeletedUids;
        let res = await requestInvoice(data);
        if (res.error) {
            let message = 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง';
            if (res?.response?.data?.message) {
                message = res.response.data.message;
            }
            Swal.fire({
                // title: `Warning`,
                icon: 'warning',
                html: message,
            })
            this.$submit.removeClass('loading');
            return;
        }
        Swal.fire({
            // title: `Warning`,
            icon: 'success',
            html: `ส่งคำขอสำเร็จ`,
            toast: true,
            position: 'top-end',
            backdrop: false,
            showConfirmButton: false,
            timer: 2500

        })
        navigate('/request/tax-invoice/success')
    }

    render() {
        if (this.state.isLoading) return (<div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}><Loading /></div>)
        let user = this.state.user;
        let data = this.state.data;
        let renderName = user?.taxInvoice?.name || user?.displayName || '';
        let renderBranch = user?.taxInvoice?.branch || '';
        let renderBranchNumber = user?.taxInvoice?.branchNumber || '';
        let renderTaxPayerNumber = user?.taxInvoice?.taxPayerNumber || '';
        let renderAddress = user?.taxInvoice?.address || user?.place?.address || '';
        let renderZipcode = user?.taxInvoice?.zipcode || '';
        let renderPhoneNumber = user?.taxInvoice?.phoneNumber || this.props.phoneNumber || '';
        let renderEmail = user?.taxInvoice?.email || user?.email || '';

        return (
            <div className='content-full  pt-5 pb-5' style={{ backgroundColor: '#ffe8b680' }}>
                <form
                    ref={ref => this.$form = window.$(ref)}
                    className="container"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (this.$submit.hasClass('loading')) { return; }
                        this.$submit.addClass('loading');
                        let data = Helper.getFormInputObject(this.$form);
                        if (!data.phoneNumber) {
                            Swal.fire({
                                // title: `Warning`,
                                icon: 'warning',
                                html: 'กรุณากรอกเบอร์โทรศัพท์',
                            })
                            this.$submit.removeClass('loading');
                            return;
                        }
                        if (data.phoneNumber
                            && (data.phoneNumber.length != 10)) {
                            Swal.fire({
                                // title: `Warning`,
                                icon: 'warning',
                                html: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
                            })
                            this.$submit.removeClass('loading');
                            return;
                        }
                        if (!data.taxPayerNumber) {
                            Swal.fire({
                                // title: `Warning`,
                                icon: 'warning',
                                html: 'กรุณากรอกเลขประจำตัวผู้เสียภาษี',
                            })
                            this.$submit.removeClass('loading');
                            return;
                        }
                        if (data.taxPayerNumber
                            && (data.taxPayerNumber.length != 13)) {
                            Swal.fire({
                                // title: `Warning`,
                                icon: 'warning',
                                html: 'กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้อง',
                            })
                            this.$submit.removeClass('loading');
                            return;
                        }
                        this.onSubmit(data);
                    }}
                >
                    <div className='row justify-content-center'>

                        <div className='col-12 col-md-8'>

                            <Link
                                to={`/request/tax-invoice`}
                                className={`btn-white btn mb-4`}
                            >

                                <i className="fa-solid fa-arrow-left mr-2"></i> เปลี่ยนเบอร์โทรศัพท์
                            </Link>
                            <BooksSelector
                                ref={this.BooksSelector}
                                data={data}
                            />
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className='mb-3'>
                                        <label>ชื่อ/บริษัท (สาขา)</label>
                                        <input name={"name"} className='form-control' defaultValue={renderName} required={true} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>สาขา <small>กรณีนิติบุคคล</small></label>
                                        <input name={"branch"} className='form-control' defaultValue={renderBranch} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>เลขสาขา <small>กรณีนิติบุคคล</small></label>
                                        <input name={"branchNumber"} className='form-control' defaultValue={renderBranchNumber} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>เลขประจำตัวผู้เสียภาษี</label>
                                        <input name={"taxPayerNumber"} className='form-control hide-arrow' type="number" required={true} defaultValue={renderTaxPayerNumber} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>ที่อยู่</label>
                                        <textarea name={"address"} className='form-control' defaultValue={renderAddress} rows={3} required={true} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>รหัสไปรษณีย์</label>
                                        <input name={"zipcode"} className='form-control hide-arrow' type="number" required={true} defaultValue={renderZipcode} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>เบอร์โทรศัพท์</label>
                                        <input name={"phoneNumber"} className='form-control hide-arrow' type="number" defaultValue={renderPhoneNumber} required={true} />
                                    </div>
                                    <div className='mb-3'>
                                        <label>อีเมล์</label>
                                        <input name={"email"} className='form-control' defaultValue={renderEmail} required={true} type={'email'} />
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='m-auto '>
                                            <button
                                                ref={ref => this.$submit = window.$(ref)}
                                                type={"submit"}
                                                className={`submit-btn btn`}
                                            >
                                                ดำเนินการ
                                                <i className="fa-solid fa-envelope-circle-check ml-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
class BooksSelector extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedBooksId: [],
        }
    }

    selectedBooksId() {
        return this.state.selectedBooksId
    }

    render() {
        let data = this.props.data;
        return (
            <>
                {
                    data.length > 0 ?
                        <>
                            <div className='row'>
                                {
                                    data.map((_data, _data_i) => {
                                        let plusTime = 0;
                                        if (_data.plusTime?.length > 0) {
                                            for (const ptime of _data.plusTime) {
                                                if (ptime.status == 'success') {
                                                    plusTime += parseFloat(ptime.time)
                                                }
                                            }
                                        }
                                        let selected = this.state.selectedBooksId.findIndex((e) => e == _data.id) != -1;
                                        return (<>
                                            <div className='col-6 col-md-4'>
                                                <div
                                                    className={`card-wrap mb-4`}
                                                    onClick={() => {
                                                        let selectedBooksId = [...this.state.selectedBooksId];
                                                        let check = selectedBooksId.findIndex((e) => e == _data.id);
                                                        if (check == -1) {
                                                            selectedBooksId.push(_data.id);
                                                        } else {
                                                            selectedBooksId.splice(check, 1)
                                                        }
                                                        this.setState({
                                                            selectedBooksId,
                                                        })
                                                    }}
                                                >
                                                    <div className={`card-header ${selected ? 'four' : 'three'}`}>
                                                        <i className="fas fa-spray-can-sparkles"></i>
                                                    </div>
                                                    <div className="card-content">
                                                        <h1 className="card-title mb-2">{_data.bookOn} </h1>
                                                        {/* <p className="card-text mb-1">
                                                            <i className={"fas fa-circle"} style={{ color: _data.bookType === 'now' ? "green" : "blue", fontSize: 10 }}></i>  {" "}
                                                            {_data.bookType === 'now' ? "เดี๋ยวนี้" : null}
                                                            {_data.bookType === 'pre' ? "จองล่วงหน้า" : null}
                                                        </p> */}
                                                        {
                                                            _data.bookedAt ?
                                                                <p className="card-text sm mb-1">{Helper.getDateThai(_data.bookedAt, { is_show_time: true })}</p>
                                                                : null
                                                        }

                                                        {_data.address && _data.address.name ?
                                                            <p className="card-text mb-1"><label>ชื่อสถานที่ : </label> {_data.address.name}</p>
                                                            : null
                                                        }
                                                        {_data.address && _data.address.placeType && _data.address.placeType.name_th ?
                                                            <p className="card-text mb-1"><label>ประเภท : </label> {_data.address.placeType.name_th}</p>
                                                            : null
                                                        }
                                                        {_data.address && _data.address.placeType && _data.address.placeType.place_sub_type && _data.address.placeType.place_sub_type.description_th ?
                                                            <p className="card-text mb-1"><label>พืนที่ : </label> {_data.address.placeType.place_sub_type.description_th}</p>
                                                            : null
                                                        }
                                                        {_data.address && _data.bookTime && _data.bookTime.name_th ?
                                                            <p className="card-text mb-1"><label>กี่ชั่วโมง : </label> {_data.bookTime.name_th}

                                                                {
                                                                    plusTime > 0 ?
                                                                        <span style={{ marginLeft: 5 }}>( <label>เพิ่มเวลา : </label> {plusTime} ชม. )</span>
                                                                        : null
                                                                }
                                                            </p>
                                                            : null
                                                        }
                                                        <button
                                                            type={'button'}
                                                            className={`mt-3 card-btn ${selected ? 'four' : 'three'}`}
                                                        >
                                                            {
                                                                selected ?
                                                                    <>
                                                                        <i className="fas fa-check"></i>
                                                                    </>
                                                                    : `เลือก`
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    })
                                }
                            </div>
                        </>
                        : null
                }

            </>
        )
    }
}