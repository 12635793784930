import axios from 'axios';
import React from 'react';
import Swal from 'sweetalert2'
export default class Test extends React.Component {
    componentDidMount() {
        this.postPrivider();
    }
    async postPrivider() {
        let res = await axios.post(`${window.api_host}/provider-register-checked`, {
            phoneNumber: '+66876618015',
            data: {
                personalNo: "3 3001 01611 53 8"
            }
        })
        if (res && res.data && res.data.error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: res.data.message,
                icon: 'warning',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            if (this.$submitBtn) {
                this.$submitBtn.removeClass('loading');
            }
            return;
        }
    }
    render() {
        return (
            <>
                TEST
            </>
        )
    }
}