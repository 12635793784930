import React from 'react';
import { Link } from '@reach/router'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helper } from '../../Services/Helper';
import RuleInfo from '../../Components/RuleInfo';
import ContactFixed from '../../Components/ContactFixed';
import { getReview } from '../../Services/HomePageService';
export default class Home extends React.Component {
    render() {
        return (
            <>
                <Cover />
                <Info />
                <RatingPrice />
                <HowToService />
                <NewInfo />
                <Service />
                <Reviewer />
                <Tips />
                <ContactFixed />
            </>
        )
    }
}

class Cover extends React.Component {
    render() {
        return (
            <>
                <section className='home-cover polygon-bg bg-bottom-left d-flex align-items-center'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-7 text-th home-cover-info'>
                                <img src={`${window.location.origin}/assets/images/logo-horizontal.png`} className={"mb-3"} style={{ height: 40 }} alt={"beeclean logo"} />
                                <h2 className='mb-2'>เรียกแม่บ้านออนไลน์มืออาชีพ กับแม่บ้าน BEEclean</h2>
                                <p className='mb-2'>จ้างแม่บ้านออนไลน์ทําความสะอาด ด้วยบริการแม่บ้านรายชั่วโมง และรายวัน ในพื้นที่ใกล้บ้านคุณ ให้ BEEclean ช่วยจัดหาแม่บ้านออนไลน์มืออาชีพ เพื่อคุณได้ง่ายๆ ผ่านแอปพลิเคชัน BEEclean เริ่มต้น 2 ชม. เพียง 500 บาทเท่านั้น</p>
                                <ul className='list-style check' style={{ paddingLeft: '1.5rem' }}>
                                    <li>บริการแม่บ้านทําความสะอาดรายชั่วโมง และรายวัน หรือราคา Package สุดคุ้ม</li>
                                    <li>แม่บ้านผ่านการอบรม ทดสอบ ทางความรู้ความสามารถ ตามมาตรฐานการให้บริการทําความสะอาด</li>
                                    <li>จองวัน เวลา บริการแม่บ้านได้ล่วงหน้า รวมทั้งสามารถเปลียนแปลงเวลานัดหมายได้</li>
                                    <li>แม่บ้านเป็นคนไทย ผ่านการตรวจสอบประวัติ อาชญากรรม</li>
                                    <li>แม่บ้านได้รับวัคซีน COVID-19 และมีการตรวจ ATK อย่างสม่ําเสมอ</li>
                                    <li>มีประกันคุ้มครองความเสียหาย และเคลมปัญหาการให้บริการ ภายใน 24 ชั่วโมง</li>
                                    <li>ให้บริการช่วยเหลือลูกค้าที่หลากหลายช่องทาง เช่น ทางโทรศัพท์ LINE OA หรือ Facebook</li>
                                </ul>
                                <p className='mb-5'>จองแม่บ้านทําความสะอาด ดาวน์โหลดเลย</p>
                                <div className='d-flex'>
                                    <a href={"https://play.google.com/store/apps/details?id=com.playonline.cleaning.user"} className='mr-5' target={"_blank"} rel="noreferrer">
                                        <img src={`${window.location.origin}/assets/images/android-btn.png`} className={'hover-opacity'} alt={"beeclean android"} />
                                    </a>
                                    <a href={"https://apps.apple.com/us/app/beeclean-%E0%B9%80%E0%B8%A3-%E0%B8%A2%E0%B8%81%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99/id1540422458"} target={"_blank"} rel="noreferrer">
                                        <img src={`${window.location.origin}/assets/images/apple-btn.png`} className={'hover-opacity'} alt={`beeclean ios`} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='beer-backdrop'>
                        <img src={`${window.location.origin}/assets/images/beer.png`} className={'beer-image'} alt={"beeclean beer thevoice"} />
                        <img src={`${window.location.origin}/assets/images/winkwhite.png`} className={'beer-whip pos-1 blink'} style={{ animationDelay: `1s` }} alt={"beeclean beer thevoice"} />
                        <img src={`${window.location.origin}/assets/images/winkwhite.png`} className={'beer-whip pos-2 blink'} style={{ animationDelay: `1.5s` }} alt={"beeclean beer thevoice"} />
                        <img src={`${window.location.origin}/assets/images/winkwhite.png`} className={'beer-whip pos-3 blink'} style={{ animationDelay: `2.3s` }} alt={"beeclean beer thevoice"} />
                    </div>
                </section>
            </>
        )
    }
}
class Info extends React.Component {
    render() {
        return (
            <>
                <section className='d-flex info-content'>
                    <img src={`${window.location.origin}/assets/images/air2.jpg`} className={'air-cover'} alt={"air"} />
                    <div className='info'>
                        <div className='info-card'>
                            <h1 className='fs-1 mb-4'>บริการใหม่!! <small>เร็วๆนี้ ล้างแอร์กับบีคลีน</small></h1>
                            <p>การล้างแอร์อย่างสม่ำเสมอ จะช่วยลดฝุ่นละออง และสิ่งสกปรกที่สะสมในชั้นกรองอากาศ ที่เป็นสาเหตุของภูมิแพ้ รวมถึงสาเหตุของประสิทธิภาพการทำงานของแอร์ ลดการใช้พลังงาน และยังทำให้ประหยัดไฟอีกด้วย
                                สำหรับลูกค้าที่กำลังมองหาช่างแอร์มืออาชีพ เราให้บริการล้างแอร์ติดผนัง รองรับขนาดเริ่มต้นตั้งแต่ 9,000 BTU ทั้งบ้านและคอนโด ทั่วทุกพื้นที่ใน กรุงเทพฯ และปริมณฑล</p>
                            <div className='d-flex justify-content-end'>
                                <img src={`${window.location.origin}/assets/images/logo-horizontal.png`} className={"mb-3"} style={{ height: 30 }} alt={"beeclean logo"} />
                            </div>
                        </div>
                        <div className='icon-happy'>
                            <img src={`${window.location.origin}/assets/images/happy.png`} alt={"beeclean logo"} />
                        </div>
                        <img src={`${window.location.origin}/assets/img/beeclipart.png`} alt={"beeclean logo"} className="info-clippart" />
                    </div>
                </section>
            </>
        )
    }
}
class HowToService extends React.Component {
    render() {
        let item = `${window.location.origin}/assets/images/howToservice.png`
        return (
            <>
                <section className='info-content p-5'>
                    <div className='text-center'>
                        <a href={`https://www.youtube.com/watch?v=GMM0cdfozi0&t=105s`} target={"_blank"}>
                            <img style={{ maxWidth: '100%', borderRadius: 30 , border: '4px solid #fff'}} className="how_to_service" src={item} />
                        </a>
                    </div>
                </section>
            </>
        )
    }
}
class NewInfo extends React.Component {
    render() {
        return (
            <>
                <section className='beeclean-service-content pt-5 pb-5'>
                    <div className='new-info'>
                        <div className='new-info-card'>
                            <RuleInfo />
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class RatingPrice extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [
                { number: 2, price: 500, size: "ไม่เกิน 40 ตารางเมตร" },
                { number: 3, price: 750, size: "ไม่เกิน 80 ตารางเมตร" },
                { number: 4, price: 1000, size: "ไม่เกิน 80 ตารางเมตร" },
                { number: 5, price: 1250, size: "80 - 120 ตารางเมตร" },
                { number: 6, price: 1500, size: "120 -150 ตารางเมตร" },
                { number: 8, price: 2000, size: "150 - 250 ตารางเมตร" },
            ]
        }
    }
    render() {
        return (
            <>
                <section className='price-rating' >
                    <div className='container' id="priceRate">
                        <h2 className='mb-5'>อัตราค่าบริการ</h2>
                        {/* <div className='price-rating-list mb-5'>
                            <div className='d-flex flex-column first-box align-items-end justify-content-center'>
                                <h4 className='mb-2'>จำนวนชั่วโมง</h4>
                                <p className='mb-0'>ค่าบริการ</p>
                            </div>
                            <div className='list-box'>
                                {
                                    this.state.list.map((item, i) => {
                                        return (
                                            <div className='list-item' key={i}>
                                                <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                                                    <h2 className='mb-0'>{item.number}</h2>
                                                    <p className='mb-0'>{Helper.numberFormat(item.price, { decimal: 0 })}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        <div className='price-table'>
                            <div className='price-table-header'>
                                <div className='row table-row'>
                                    <div className='col-4 col-lg-4'>จำนวนชั่วโมง</div>
                                    <div className='col-3 col-lg-4'>ค่าบริการ</div>
                                    <div className='col-5 col-lg-4'>ขนาด</div>
                                </div>
                            </div>
                            <div className='price-table-body'>
                                {
                                    this.state.list.map((item, i) => {
                                        return (
                                            <div className='row table-row' key={i}>
                                                <div className='col-4 col-lg-4'>{item.number}</div>
                                                <div className='col-3 col-lg-4'>{Helper.numberFormat(item.price, { decimal: 0 })}</div>
                                                <div className='col-5 col-lg-4'>{item.size}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'></div>
                            <div className='col-6'></div>
                        </div>
                        <a className='d-flex justify-content-center align-items-center mb-5' href="https://www.facebook.com/beecleanapp">
                            <img src={`${window.location.origin}/assets/images/promotion-btn.png`} alt={'beeclean promotion button'} />
                        </a>
                        <div className='d-flex justify-content-center align-items-center mb-0'>
                            <Link to="/service" className={"btn btn-service"}>เงื่อนไขการให้บริการ</Link>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class Service extends React.Component {
    constructor() {
        super();
        this.state = {
            slideItems: [
                `${window.location.origin}/assets/images/artboard1.png`,
                `${window.location.origin}/assets/images/artboard2.png`,
                `${window.location.origin}/assets/images/artboard3.png`,

            ]
        }
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            arrows: false,
            speed: 500,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
        };
        return (
            <>
                <section className='service-content p-5'>
                    {/* <div className='service-container'>
                        <div className='service-info-home'>
                            <h1 className='fs-1 mb-4'>การบริการ</h1>
                            <p>ลักษณะของสถานที่ ที่ให้บริการมีดังต่อไปนี้<br />
                                <strong>คอนโด | บ้าน | อาคารพาณิชย์</strong><br />
                                ลูกค้าสามารถกำหนดวันเวลาทำความสะอาดได้<br />
                                ตามความต้องการของลูกค้า<br />
                                แม่บ้านจะเดินทางมาพร้อมอุปกรณ์<br />
                                และน้ำยาทำความสะอาดอย่างครบครัน
                            </p>
                            <div className='d-flex'>
                                <Link to="/download">
                                    <img src={`${window.location.origin}/assets/images/call-maid.png`} className={'hover-opacity'} alt={`beeclean call main`} style={{ height: 50 }} />
                                </Link>
                            </div>
                            <img src={`${window.location.origin}/assets/images/white_polygon.png`} className={"service-bg-bottom-right"} alt={`beeclean bg`} />
                        </div>
                    </div> */}
                    <div className='service-slide'>
                        <Slider {...settings}>
                            {
                                this.state.slideItems.map((item, i) => {
                                    return (
                                        <div key={i} >
                                            <img style={{ maxWidth: '100%' }} src={item} className={"how_to_service"}/>
                                            {/* <div className='service-slide-bg'
                                                style={{ backgroundImage: `url("${item}")` }}
                                            >
                                                <h3>{item}</h3>
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </section>
            </>
        )
    }
}

class Tips extends React.Component {
    render() {
        return (
            <>
                <section className='tips-content  polygon-bg'>
                    <div className='container'>
                        <h1 className='mb-5'>เคล็ดลับน่ารู้</h1>
                        <div className='row mb-5'>
                            <div className='col-4'>
                                <a href={`https://blog.beeclean.app/2023/01/23/%e0%b8%97%e0%b8%a3%e0%b8%b4%e0%b8%84%e0%b8%97%e0%b8%b3%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b8%aa%e0%b8%b0%e0%b8%ad%e0%b8%b2%e0%b8%94%e0%b8%9a%e0%b9%89%e0%b8%b2%e0%b8%99%e0%b9%83%e0%b8%ab%e0%b9%89/`}
                                    target={"_blank"}
                                >
                                    <div className='tips-box mb-3'>
                                        <div className='tips-image-frame'>
                                            <img src={`${window.location.origin}/assets/img/blog/1.png`} alt={`beeclean bg`} />
                                        </div>
                                        <div className='image-icon' style={{ left: -45 }}>
                                            <img src={`${window.location.origin}/assets/images/main2.png`} alt={`beeclean bg`} />
                                        </div>
                                    </div>
                                    <div className='pl-3 pr-3'>
                                        <p className='tips-text mb-0 text-th text-black'>
                                            ทริคทำความสะอาดบ้านให้ติดเป็นนิสัย
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className='col-4'>
                                <a href={`https://blog.beeclean.app/2023/01/09/%e0%b8%88%e0%b8%b1%e0%b8%94%e0%b8%9a%e0%b9%89%e0%b8%b2%e0%b8%99%e0%b9%83%e0%b8%ab%e0%b9%89%e0%b8%99%e0%b9%88%e0%b8%b2%e0%b8%ad%e0%b8%a2%e0%b8%b9%e0%b9%88-%e0%b8%97%e0%b8%b1%e0%b9%89%e0%b8%87%e0%b8%aa/`}
                                    target={"_blank"}
                                >
                                    <div className='tips-box mb-3'>
                                        <div className='tips-image-frame'>
                                            <img src={`${window.location.origin}/assets/img/blog/2.jpeg`} alt={`beeclean bg`} />
                                        </div>
                                        <div className='image-icon' style={{ left: -50, bottom: -20 }}>
                                            <img src={`${window.location.origin}/assets/images/mainpay.png`} alt={`beeclean bg`} />
                                        </div>
                                    </div>
                                    <div className='pl-3 pr-3'>
                                        <p className='tips-text mb-0 text-th'>
                                            จัดบ้านให้น่าอยู่ ทั้งสบายตาและสบายใจ
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className='col-4'>
                                <a href={`https://blog.beeclean.app/2023/01/03/%e0%b9%80%e0%b8%9a%e0%b8%81%e0%b8%81%e0%b8%b4%e0%b9%89%e0%b8%87%e0%b9%82%e0%b8%8b%e0%b8%94%e0%b8%b2-%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b9%84%e0%b8%a1%e0%b9%88%e0%b8%98%e0%b8%a3%e0%b8%a3%e0%b8%a1%e0%b8%94/`}
                                    target={"_blank"}
                                >
                                    <div className='tips-box mb-3'>
                                        <div className='tips-image-frame'>
                                            <img src={`${window.location.origin}/assets/img/blog/3.jpeg`} alt={`beeclean bg`} />
                                        </div>
                                        <div className='image-icon'>
                                            <img src={`${window.location.origin}/assets/images/mainwach.png`} alt={`beeclean bg`} />
                                        </div>
                                    </div>
                                    <div className='pl-3 pr-3'>
                                        <p className='tips-text mb-0 text-th'>
                                            เบกกิ้งโซดา ที่ไม่ธรรมดา
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center align-items-center mb-0'>
                            <a href="https://blog.beeclean.app/" target={"_blank"} className={"btn btn-service"}>ดูทั้งหมด</a>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

class Reviewer extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            list: [
                // { starfilled: [1], starempty: [1, 1, 1, 1] },
                // { starfilled: [1, 1], starempty: [1, 1, 1] },
                // { starfilled: [1, 1, 1], starempty: [1, 1] },
                // { starfilled: [1, 1, 1, 1], starempty: [1] },
                // { starfilled: [1, 1, 1, 1, 1], starempty: [] },
                // { starfilled: [1, 1, 1, 1, 1], starempty: [] },
                // { starfilled: [1, 1, 1, 1, 1], starempty: [] },
                // { starfilled: [1, 1, 1, 1, 1], starempty: [] },
            ]
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        let ref = await getReview();
        let list = [];
        for (const doc of ref.data) {
            let item = doc;
            item.starempty = [];
            item.starfilled = [];
            for (let index = 0; index < item.rating; index++) {
                item.starfilled.push(1)
            }
            for (let index = 0; index < item.rating - 5; index++) {
                item.starempty.push(1)
            }
            list.push(item)
        }
        this.setState({
            list: list,
            is_loading: false,
        })
    }

    render() {
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: 3,
            speed: 500,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            pauseOnHover: true,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            arrows: false,
            dots: true,
            draggable: false,
            centerPadding: "0px",
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerMode: false,
                        slidesToShow: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                    }
                }
            ]
        };
        return (
            <>
                <section className='tips-content polygon-bg user-review' >
                    <div className='container'>
                        <h1 className='mb-5'>คะแนนรีวิวจากลูกค้า</h1>
                        {
                            this.state.is_loading ?
                                <div className='text-center'>
                                    <div className={"ui loader inline active"} style={{ top: -5 }} />
                                    <p className='mb-0 mt-2'>กำลังโหลดข้อมูลผู้รีวิว</p>
                                </div>
                                : null
                        }
                        {
                            !this.state.is_loading
                                && this.state.list.length > 0 ? <Slider {...settings}>
                                {
                                    this.state.list.map((item, index) => {
                                        let starempty = [...item.starempty];
                                        let starfilled = [...item.starfilled];
                                        return (
                                            <div key={index}>
                                                <div className='pl-2 pr-2 pt-5 pb-5'>
                                                    <div className='user-review-card'>
                                                        <div className='row'>
                                                            {/* <div className='col-4'>
                                                                <div className='image-frame'>
                                                                    <img src={item.image || `${window.origin}/assets/images/blank.png`} className="image" alt={`reviewer`} />
                                                                </div>
                                                            </div> */}
                                                            <div className='col-12'>
                                                                <h3>{item.name || '-'}</h3>
                                                                <p>{item.description || '-'}</p>
                                                                <div>
                                                                    {
                                                                        starfilled.map(() => {
                                                                            return (<i className="las la-star full"></i>)
                                                                        })
                                                                    }
                                                                    {
                                                                        starempty.map(() => {
                                                                            return (<i className="las la-star"></i>)
                                                                        })
                                                                    }
                                                                </div>
                                                                <div className='justify-content-end align-items-end d-flex flex-column'>
                                                                    <p>Cleaner</p>
                                                                    <div className='cleaner-image-frame mb-1'>
                                                                        <img src={item.cleanerImage || `${window.origin}/assets/images/blank.png`} className="image" alt={`reviewer`} />
                                                                    </div>
                                                                    <strong>{item.cleanerName || '-'}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Slider>
                                : null
                        }

                    </div>
                </section>
            </>
        )
    }
}