import React from 'react';
import PrivacyInfo from '../Components/PrivacyInfo';
export default class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className={"page_content"}>
                <div className="container pt-5 pb-5 text-th">
                    <PrivacyInfo />
                </div>
            </div>
        );
    }
}