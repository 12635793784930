import { navigate } from '@reach/router';
import React from 'react';
import Swal from 'sweetalert2';
import { Helper } from '../../Services/Helper';
import { authentication, _getDoc } from '../../Services/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { async } from '@firebase/util';
import { createUserAPI } from '../../Services/userServices';
export default class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            phoneNumber: Helper.getParameterByName('phoneNumber'),
            fullPhoneNumber: "+" + Helper.getParameterByName('phoneNumber'),
            recaptchaChecked: false,
            disabledBtn: true,
        }
    }

    componentDidMount() {

        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (currentUser) {
            window.location.href = '/dashboard'
            return;
        }

        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            //'size': 'invisible'
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ... enable ok button
                // remove recaptcha-container div

                this.setState({
                    disabledBtn: false,
                })
            },
        }, authentication);

        let appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(authentication, this.state.fullPhoneNumber, appVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
            }).catch((error) => {
                // Error; SMS not sent
                // ...
                console.log(error.message)
                console.log(error.code)
                if (error.code == "auth/too-many-requests") {
                    Swal.fire({
                        // title: `Warning`,
                        icon: 'warning',
                        html: 'กรุณารอสักครู่ ...',
                    })
                    this.submit.removeClass('loading');
                }
            });

    }

    onConfirm(data) {
        let OTP = data.otp;
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(OTP).then(async (result) => {
            // User signed in successfully.
            console.log('success login user :', result.user)
            const user = result.user;
            Helper.setLocalStorage('loginuser', JSON.stringify(user));
            const userDoc = await _getDoc('users/' + user.uid)
            await createUserAPI(user.uid, user.email, user.phoneNumber, user.displayName)
            if (userDoc.exists()) {
                navigate('/dashboard')
                if (window.headerRef && window.headerRef.current) {
                    window.headerRef.current.checkLoginUser();
                }
            } else {
                navigate('/register')
            }

        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            Swal.fire({
                // title: `Warning`,
                icon: 'error',
                html: error,
            })
            this.submit.removeClass('loading');
        });

    }

    render() {
        let phone = ``
        phone += `+${this.state.phoneNumber.slice(0, 2)} `;
        phone += `${this.state.phoneNumber.slice(2, 5)}`;
        phone += `-${this.state.phoneNumber.slice(5, 8)}`;
        phone += `-${this.state.phoneNumber.slice(8, 11)}`;
        return (
            <>
                <div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}>
                    <div>
                        <form
                            className='login-box mb-4'
                            ref={(ref) => {
                                this.$form = window.$(ref)
                                this.submit = this.$form.find(`button[type="submit"]`)
                            }}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (this.submit.hasClass('loading')) { return false; }
                                this.submit.addClass('loading');
                                let data = Helper.getFormInputObject(this.$form);
                                if (!data.otp) {
                                    Swal.fire({
                                        // title: `Warning`,
                                        icon: 'warning',
                                        html: 'กรุณากรอก OTP',
                                    })
                                    this.submit.removeClass('loading');
                                    return;
                                }
                                this.onConfirm(data);
                            }}
                        >
                            <h5 className='title mb-4'>โปรดกรอก OTP ที่ท่านได้รับทาง SMS</h5>
                            <p className='mb-1 text-center'>{phone}</p>
                            <div className='d-flex justify-content-center align-items-center pl-3 pr-3 mb-3'>
                                <input type="text" className="form-control otp-input" name="otp" maxLength={6} />
                            </div>
                            {
                                this.state.disabledBtn ?
                                    <div className='d-flex justify-content-center mb-3'>
                                        <div id="recaptcha-container"></div>
                                    </div>
                                    : null
                            }
                            {!this.state.disabledBtn ? <>
                                <ResendButton />
                                <div className='d-flex justify-content-center align-items-center mb-4'>
                                    <button
                                        type={"submit"}
                                        className={`submit-btn btn`}
                                        disabled={this.state.disabledBtn}
                                    >
                                        ถัดไป
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </div>
                            </> : null}

                        </form>

                    </div>
                </div>
            </>
        )
    }
}

class ResendButton extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0,
        }
    }
    componentDidMount() {
        let countDown = 60;
        let time = setInterval(() => {
            this.sendOtp.html("กดส่งใหม่ได้ใน " + countDown)
            countDown--;
            if (countDown < 0) {
                this.sendOtp.removeClass('loading')
                this.sendOtp.html('ส่งใหม่');
                clearInterval(time)
            }
        }, 1000)
    }

    resend() {
        if (this.sendOtp.hasClass('loading')) { return; }
        this.sendOtp.addClass('loading')
        this.sendOtp.html('กำลังส่ง OTP');

        // let preparePhone = this.state.phoneNumber.replace(' ', '');
        // let phoneNumber = `+${preparePhone}`;

        // await axios.post(`${window.api_appservice}/request-otp-artist`, { type: "THSMS", phoneNumber: phoneNumber });
        let countDown = 10;
        let time = setInterval(() => {
            this.sendOtp.html("กดส่งใหม่ได้ใน " + countDown)
            countDown--;
            if (countDown < 0) {
                this.sendOtp.removeClass('loading')
                this.sendOtp.html('ส่งใหม่');
                clearInterval(time)
            }
        }, 1000)
    }

    render() {
        return (
            <div className='mb-2 d-flex justify-content-center align-items-center mb-3 loading'>
                <div className='link'
                    ref={(ref) => { this.sendOtp = window.$(ref); }}
                    onClick={() => {

                    }}
                >
                    กำลังประมวลผล
                </div>
            </div>
        )
    }
}