import { initializeApp } from "firebase/app"
import {
    getFirestore,
    // query,
    // orderBy,
    // onSnapshot,
    collection,
    getDoc,
    getDocs,
    // addDoc,
    setDoc,
    // updateDoc,
    doc,
    serverTimestamp,
    onSnapshot
    // arrayUnion,
} from "firebase/firestore"
import { getStorage, ref } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyB6H-6eUX3kkeMltrkuphdJxErN_9C51zI",
    authDomain: "beeclean-app-66b97.firebaseapp.com",
    projectId: "beeclean-app-66b97",
    storageBucket: "beeclean-app-66b97.appspot.com",
    messagingSenderId: "416608692434",
    appId: "1:416608692434:web:3aae6dc4074cf9be30a50b",
    measurementId: "G-88NG6CLVJ6"
};

const app = initializeApp(firebaseConfig)


const auth = getAuth(app);
const user = auth.currentUser;
export const storage = getStorage(app);


export const db = getFirestore(app)
export const onSnapFirebase = onSnapshot;
export const authentication = auth;


export const _onAuthStateChanged = (setUser, setLoading) => {
    return onAuthStateChanged(auth, user => {
        if (user) {
            setUser(user)
        } else {
            setUser(null)
        }
        setLoading(false)
    });
}

export const firebaseSignOut = async () => {
    window.localStorage.removeItem('loginuser');
    return signOut(auth)
}

export const checkUser = () => {
    console.log(user)
    if (user) {
        return "logged"
    } else {
        return "logout"
    }
}

export const getAppVerifier = async () => {
    return new RecaptchaVerifier('recaptcha-container');
}

export const authenticateWithPhoneNumber = async (phoneNumber, appVerifier) => {
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
}


export const authenticateWithEmail = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
}

// export const authenticateAnonymously = () => {
//     return signInAnonymously(getAuth(app))
// };


export const _getDoc = (_doc) => {
    const docRef = doc(db, _doc)
    return getDoc(docRef)
}

export const _getCol = (_col) => {
    const colRef = collection(db, _col)
    return getDocs(colRef)
}
