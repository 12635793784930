import { Link, navigate } from '@reach/router';
import React from 'react';
import GoogleMap from './../../Components/GoogleMap';
import { Helper } from '../../Services/Helper';
import Swal from 'sweetalert2'
// import thailand from '../../Services/thailand.json';
import provinces from '../../Services/provinces.json'
import amphoes from '../../Services/amphoes.json'
import { createUserAddress, deleteUserAddress, editUserAddress, getUserAddress } from '../../Services/userServices';
import Loading from '../../Components/Loading';
import { getPlaceType, getServiceArea } from '../../Services/aircleanServices';
export default class PlaceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            map_loading: true,
            location: {
                lat: 13.9618178,
                lng: 100.5688045,
            },
            data: false,
            uid: this.props.placeId,
            area: false,
            placeType: false,
            userUid: false,
        };
    }

    componentDidMount() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }
        this.fetch(currentUser.uid, this.state.uid);
    }

    async fetch(userUid, placeUid) {
        const p = []
        if (this.state.uid) {
            p.push(getUserAddress(userUid, placeUid))
            p.push(getServiceArea())
            p.push(getPlaceType())
            const res = await Promise.all(p);
            this.setState({
                data: res[0].data,
                area: res[1].data,
                placeType: res[2].data,
                is_loading: false,
                map_loading: false,
                location: {
                    lat: res[0].data.coordinate._latitude,
                    lng: res[0].data.coordinate._longitude,
                },
                userUid: userUid
            })
        } else {
            p.push(getServiceArea())
            p.push(getPlaceType())
            const res = await Promise.all(p);

            this.setState({
                area: res[0].data,
                placeType: res[1].data,
                is_loading: false,
                map_loading: false,
                userUid: userUid
            })
        }
    }

    async onDelete() {

        Swal.fire({
            title: 'กรุณารอสักครู่...',
            text: 'ระบบกำลังประมวลผล',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })

        await deleteUserAddress(this.state.userUid, this.state.uid)
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : false;
        if (cart && cart.place && cart.place.uid === this.state.uid) {
            delete cart.place;
            Helper.setLocalStorage('cart', JSON.stringify(cart))
        }
        Swal.fire({
            title: 'ลบสถานที่สำเร็จ',
            icon: 'success',
            position: 'top-end',
            backdrop: false,
            toast: true,
            showConfirmButton: false,
            timer: 2500
        })
        if (this.props.myplace) {
            navigate('/my-place')
        } else {
            navigate('/place')
        }
    }

    async onCreate(data) {

        Swal.fire({
            title: 'กรุณารอสักครู่...',
            text: 'ระบบกำลังประมวลผล',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })


        await createUserAddress(data)
        Swal.fire({
            title: 'สร้างสถานที่สำเร็จ',
            icon: 'success',
            position: 'top-end',
            backdrop: false,
            toast: true,
            showConfirmButton: false,
            timer: 2500
        })
        if (this.props.myplace) {
            navigate('/my-place')
        } else {
            navigate('/place')
        }
        return;
        // on Error

        Swal.fire({
            title: 'สร้างสถานที่ไม่สำเร็จ',
            text: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2500
        })
        if (this.$submit) {
            this.$submit.removeClass('loading')
        }

    }

    async onEdit(data) {

        Swal.fire({
            title: 'กรุณารอสักครู่...',
            text: 'ระบบกำลังประมวลผล',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })


        await editUserAddress(data)
        Swal.fire({
            title: 'แก้ไขสถานที่สำเร็จ',
            icon: 'success',
            position: 'top-end',
            backdrop: false,
            toast: true,
            showConfirmButton: false,
            timer: 2500
        })
        if (this.props.myplace) {
            navigate('/my-place')
        } else {
            navigate('/place')
        }
        return;
        // on Error

        Swal.fire({
            title: 'แก้ไขสถานที่ไม่สำเร็จ',
            text: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            position: 'top-end',
            showConfirmButton: false,
            timer: 2500
        })
        if (this.$submit) {
            this.$submit.removeClass('loading')
        }

    }


    render() {

        return (
            <div className='content-full' style={{ backgroundColor: '#ffe8b6' }}>
                <div className='pt-5 w-100'>
                    <div className='container mb-3'>
                        {
                            this.props.placeId ?
                                <div className='d-flex'>
                                    <div className='right-floated'>
                                        <button type="button" className='btn btn-white'
                                            onClick={() => {
                                                Swal.fire({
                                                    title: 'ยืนยันการลบสถานที่',
                                                    text: `ต้องการลบสถานที่นี้ใช่หรือไม่ ?`,
                                                    icon: 'question',
                                                    reverseButtons: true,
                                                    showCancelButton: true,
                                                    confirmButtonText: 'ลบสถานที่',
                                                    cancelButtonText: 'ยกเลิก',
                                                    customClass: {
                                                        confirmButton: 'btn btn-service',
                                                        cancelButton: 'btn btn-white'
                                                    },
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        this.onDelete();
                                                    }
                                                })
                                            }}
                                        >
                                            ลบที่อยู่
                                        </button>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <form
                        ref={(ref) => {
                            this.$form = window.$(ref)
                            this.$submit = this.$form.find(`[type="submit"]`);
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (this.$submit.hasClass('loading')) { return }
                            this.$submit.addClass('loading')
                            let data = Helper.getFormInputObject(this.$form);
                            let error = false;
                            if (!data.amphoe) {
                                error = { message: `กรุณาเลือกเขต/อำเภอ` }
                            }
                            if (!data.province) {
                                error = { message: `กรุณาเลือกจังหวัด` }
                            }
                            if (!data.addressDetail) {
                                error = { message: `กรุณากรอกที่อยู่` }
                            }
                            if (!data.address || !data.lat || !data.lng) {
                                error = { message: `กรุณาปักหมุดสถานที่` }
                            }
                            if (!data.phoneNumber) {
                                error = { message: `กรุณากรอกที่อยู่` }
                            }
                            if (!data.name) {
                                error = { message: `กรุณากรอกที่อยู่` }
                            }
                            if (!data.subType) {
                                error = { message: `กรุณาเลือกประเภทที่อยู่` }
                            }
                            if (!data.placeType) {
                                error = { message: `กรุณาเลือกประเภทที่อยู่` }
                            }
                            if (error) {
                                Swal.fire({
                                    title: 'สร้างสถานที่ไม่สำเร็จ',
                                    text: error.message,
                                    icon: 'error',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2500,
                                    toast: true,
                                    backdrop: false,
                                })
                                if (this.$submit) {
                                    this.$submit.removeClass('loading')
                                }
                                return;
                            }
                            let dataJson = {
                                userUid: this.state.userUid,
                                placeUid: this.state.uid || false,
                                address: data.address,
                                addressDetail: data.addressDetail,
                                phoneNumber: data.phoneNumber,
                                name: data.name,
                                note: data.note,
                                coordinate: {
                                    latitude: parseFloat(data.lat),
                                    longitude: parseFloat(data.lng)
                                },
                                placeSubTypeUid: data.subType,
                                placeTypeUid: data.placeType,
                                amphoe: data.amphoe,
                                province: data.province
                            }


                            if (this.state.data) {
                                this.onEdit(dataJson)
                            } else {
                                this.onCreate(dataJson)
                            }

                        }}>
                        <div className='place-bg mb-4 custom-form'>
                            <div className='container'>
                                <div className='place-title'>
                                    {
                                        this.props.placeId ?
                                            `แก้ไขสถานที่`
                                            : `เพิ่มสถานที่`
                                    }
                                </div>
                                {
                                    this.state.is_loading ?
                                        <Loading />
                                        :

                                        <div className='row'>
                                            <div className='col-12 col-md-6 col-lg-4 pt-5'>
                                                <p style={{ color: '#555' }}>โปรดเลือกลักษณะที่อยู่อาศัยของท่าน เพื่อคำนวณอัตรค่าบริการที่เหมาะสม</p>
                                                <PlaceTypeSelector placeType={this.state.data ? this.state.data.placeType.name : false} data={this.state.placeType} placeSubTypeUid={this.state.data ? this.state.data.placeSubTypeUid : false} />
                                            </div>
                                            <div className='col-12 col-md-6 col-lg-4'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-6 mb-2'>
                                                        <label>ชื่อสถานที่ :</label>
                                                        <input type={"text"} className={"form-control"} name="name" defaultValue={this.state.data && this.state.data.name ? this.state.data.name : ``} />
                                                    </div>
                                                    <div className='col-12 col-md-6 mb-2'>
                                                        <label>เบอร์ติดต่อ :</label>
                                                        <input type={"text"} className={"form-control"} name="phoneNumber" defaultValue={this.state.data && this.state.data.phoneNumber ? this.state.data.phoneNumber : ``} />
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <label>ปักหมุดที่ :</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="fa-solid fa-map-location-dot"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" className="form-control" name={"address"} defaultValue={this.state.data && this.state.data.address ? this.state.data.address : ``} readOnly={true} />
                                                    </div>
                                                </div>
                                                <div className='mb-2'>
                                                    <label>ที่อยู่ :</label>
                                                    <input type={"text"} className={"form-control"} name="addressDetail" defaultValue={this.state.data && this.state.data.addressDetail ? this.state.data.addressDetail : ``} />
                                                </div>
                                                <ProvinceSelector data={this.state.area} provinceIndex={this.state.data && this.state.data.province ? this.state.data.province : null} amphoeIndex={this.state.data && this.state.data.amphoe ? this.state.data.amphoe : null} />
                                                <div className='mb-2'>
                                                    <label>ระบุหมายเหตุ (ไม่บังคับ) :</label>
                                                    <input type={"text"} className={"form-control"} name="note" defaultValue={this.state.data && this.state.data.note ? this.state.data.note : ``} />
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-12 col-lg-4'>
                                                <p className='text-center' style={{ fontSize: 20 }}>
                                                    <i className="fa-solid fa-location-dot text-danger mr-2" style={{ fontSize: 20 }}></i> โปรดระบุตำแหน่ง GPS
                                                </p>
                                                <GoogleMapLocation
                                                    location={this.state.location}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            !this.state.is_loading ?
                                <div className='container mb-5'>
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <Link  to={this.props.myplace ? "/my-place" : "/place"} className='btn btn-white' style={{ minWidth: 250 }}>ยกเลิก</Link>
                                        <button type="submit" className='btn btn-service ml-4' style={{ minWidth: 250 }}>บันทึก</button>
                                    </div>
                                </div>
                                : null
                        }
                    </form>
                </div>

            </div >
        )
    }
}

class PlaceTypeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.placeType || `condo`,
            selectedUid: props.placeType && props.placeType == `house` ? props.data[1].uid : props.data[0].uid,
            selectedIndex: props.placeType && props.placeType == `house` ? 1 : 0,
            placeSubTypeUid: props.placeSubTypeUid || false,
            data: props.data
        }
    }
    render() {
        return (
            <>
                <div className='row mb-4'>
                    {
                        this.state.data.map((e, i) => {
                            let iconName = "home"
                            if (e.name == "condo") {
                                iconName = "condo"
                            }
                            return (
                                <div className='col-6' key={i}>
                                    <div className='place-card-container'>
                                        <div className={this.state.selected === e.name ? 'place-card active mb-2' : 'place-card mb-2'}
                                            onClick={() => {
                                                this.setState({
                                                    selected: e.name,
                                                    selectedIndex: i,
                                                    selectedUid: e.uid
                                                })
                                            }}
                                        >
                                            <div className={this.state.selected === e.name ? 'icon-check show' : 'icon-check'}>
                                                <i className="fa-solid fa-thumbtack"></i>
                                            </div>
                                            <div className='img'>
                                                <img src={`${window.location.origin}/assets/img/${iconName}-icon.png`} alt="place type icon" />
                                            </div>
                                            <p className='mb-0'>
                                                {e.name_th}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <input name="placeType" type="hidden" value={this.state.selectedUid} />
                <select name='subType' className='form-control' defaultValue={this.state.placeSubTypeUid}>
                    {
                        this.state.data[this.state.selectedIndex].place_sub_type.map((e, i) => {
                            return (
                                <option key={i} value={i}>{e.name_th}</option>
                            )
                        })
                    }
                </select>
            </>
        )
    }
}

class ProvinceSelector extends React.Component {
    constructor(props) {
        super(props);
        let _provinces = [...this.props.data];

        this.state = {
            is_loading: true,
            location: false,
            place_type: [],
            place_type_selected: false,
            place_sub_type_selected: 0,
            provinces: _provinces,
            places: [],
            selected_amphoe: props.amphoeIndex || false,
            selected_province: props.provinceIndex || false,
            booking_time: [],
            time: [],
            place_selected: props.placeUid || false,
            // selected_booking_time: props.data.bookingDuration || false,
            // selected_booking_date: props.data.bookingDate || false,
            // selected_time: props.data.bookingTime || false,
            service_area: [],
            is_loading_service_area: false,
        };
    }


    getAmphoe() {
        let apm = [];
        this.state.provinces.forEach((db) => {
            // console.log(db)
            if (db && db.code === this.state.selected_province) {
                apm = db.amphoes;
            }
        });
        apm.sort()
        return apm;
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-12 col-md-6 mb-2'>
                        <label>จังหวัด :</label>
                        <select className="form-control"
                            name={"province"}
                            defaultValue={this.state.selected_province}
                            onChange={(e) => {
                                this.setState({
                                    selected_province: e.target.value,
                                    selected_amphoe: false,
                                })
                            }}
                        >
                            <option value="">เลือกจังหวัด</option>
                            {
                                this.state.provinces.map((item, item_i) => {
                                    if (!item) return;
                                    return (
                                        <option value={item.code} key={item_i}>{item.name.th}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-12 col-md-6 mb-2'>
                        <label>เขต/อำเภอ :</label>
                        {
                            !this.state.selected_province ?
                                <input type={"test"} defaultValue={"เลือกจังหวัด"} disabled={true} className={"form-control"} />
                                : null
                        }
                        {
                            this.state.selected_province ?
                                <select className="form-control"
                                    name={"amphoe"}
                                    onChange={(e) => {
                                        if (e.target.value === "" || !e.target.value) { return; }
                                        this.setState({
                                            selected_amphoe: e.target.value,
                                        })
                                    }}
                                    defaultValue={this.state.selected_amphoe}
                                >
                                    <option value="">เลือกเขต/อำเภอ</option>
                                    {
                                        this.getAmphoe().map((amp, amp_i) => (
                                            <option value={amp.code} key={amp_i}
                                            >{amp.name.th}</option>
                                        ))
                                    }
                                </select>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    }
}

class GoogleMapLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map_loading: false,
            location: props.location
        }
    }
    render() {
        return (
            <>
                <div className="form-group">
                    <GoogleMap
                        mode="view"
                        height={300}
                        customContainerStyle={{
                            borderRadius: 30,
                            overflow: 'hidden',
                            borderColor: '#000',
                            borderWidth: 1,
                        }}
                        lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                        lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                    />
                </div>
                {
                    this.state.map_loading ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="ui active inline loader small mr-2" ></div> <strong>กำลังโหลดข้อมูลตำแหน่ง GPS</strong>
                        </div>
                        : <div className={"form-group"}>
                            <button
                                onClick={() => {
                                    let geo = { lat: false, lng: false };
                                    if (this.lat && this.lat.val()) {
                                        geo.lat = this.lat.val()
                                    }
                                    if (this.lng && this.lng.val()) {
                                        geo.lng = this.lng.val()
                                    }
                                    Helper.reactToDom(window.$('body'),
                                        <GoogleMap
                                            modalTitle={"ปักหมุดสถานที่"}
                                            mode="modal"
                                            height={500}
                                            onSubmit={(location) => {
                                                this.setState({
                                                    map_loading: true,
                                                }, () => {
                                                    this.setState({
                                                        location: location,
                                                        map_loading: false,
                                                    }, () => {
                                                        window.$(`input[name="address"]`).val(location.address)
                                                        window.$(`input[name="lat"]`).val(location.lat)
                                                        window.$(`input[name="lng"]`).val(location.lng)
                                                    })
                                                })
                                            }}
                                            lat={this.state.location && this.state.location.lat ? parseFloat(this.state.location.lat) : false}
                                            lng={this.state.location && this.state.location.lng ? parseFloat(this.state.location.lng) : false}
                                        />)
                                }}
                                type={"button"}
                                className="btn login-button"
                                style={{ width: '100%' }}
                            >{this.state.location ? "เปลี่ยนตำแหน่ง" : "ระบุตำแหน่ง"}</button>
                        </div>

                }

            </>
        )
    }
}