import React from 'react';
import TermsInfo from '../Components/TermsInfo';
export default class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className={"page_content"}>
                <div className="container pt-5 pb-5 text-th">
                    <TermsInfo />
                </div>
            </div>
        );
    }
}