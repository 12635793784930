import React, { Component } from 'react'
import { Helper } from '../../Services/Helper'
import Swal from 'sweetalert2'
import { navigate } from '@reach/router'

export default class TaxInvoice extends Component {
    render() {
        return (<>
            <div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}>
                <div>
                    <form
                        className='login-box mb-4'

                        ref={(ref) => {
                            this.$form = window.$(ref)
                            this.submit = this.$form.find(`button[type="submit"]`)
                        }}
                    >
                        <h2 className='title mb-4'>ส่งคำขอทำใบกำกับภาษีสำเร็จ</h2>
                        <p className='mb-4 text-center'>ส่งคำขอทำใบกำกับภาษีสำเร็จแล้ว <br />เราจะทำการส่งใบกำกับภาษีทางอีเมล์ ภายใน 1 วัน</p>
                        <div className='d-flex justify-content-center align-items-center pl-3 pr-3 mb-3'>
                            <img alt={'beeclean'}
                                src={`${window.location.origin}/assets/images/9.png`}
                                style={{
                                    maxWidth: `100%`,
                                    width: 350
                                }}
                            />
                        </div>
                        <p className='text-center'>ขอบคุณที่ใช้บริการ</p>
                    </form>
                </div>
            </div >
        </>)
    }
}
