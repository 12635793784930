import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;
export const getAircleanServiceAPI = async () => {
    const url = `${baseUrl}/airclean-service`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getServiceArea = async () => {
    const url = `${baseUrl}/airclean-service-area`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getPlaceType = async () => {
    const url = `${baseUrl}/airclean-place-type`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const paymentAPI = async (data) => {
    const url = `${baseUrl}/payment`;

    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const paymentResultAPI = async (uid) => {
    const url = `${baseUrl}/payment-result/${uid}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}