import React from 'react';
import { Link } from '@reach/router'
import Info from './include/Info';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helper } from '../../Services/Helper';
import ServiceModal from "../../Components/ServiceModal";
import th from 'date-fns/locale/th';
import { navigate } from '@reach/router';
import { getAircleanServiceAPI } from '../../Services/aircleanServices';
import { getSetting } from '../../Services/settingService'
import Loading from './../../Components/Loading'
import moment from 'moment'
registerLocale('th', th)
const baseUrl = `https://s3.amazonaws.com/static.neostack.com/img/react-slick`
export default class Service extends React.Component {
    constructor(props) {
        super(props);
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let place = false;
        let date = new Date(moment().add(1, `days`).format('YYYY-MM-DD'));
        if (cart && cart.place) {
            place = cart.place;
        }
        this.state = {
            is_loading: true,
            items: [],
            cartItems: [],
            date: date,
            place: place,
            disableDate: new Date(),
        };
    }

    async _fetch() {
        //loading ...
        let setting = await getSetting();
        const res = await getAircleanServiceAPI();
        // console.log(res.data)
        let addDate = 1;
        let getSettingExtendServiceDate = setting.data.filter((e) => { return e.key === 'extendServiceDate' })[0];
        if (setting && getSettingExtendServiceDate) {
            addDate = getSettingExtendServiceDate.value
        }
        let date = new Date(moment().add(addDate, `days`).format('YYYY-MM-DD'));
        let now = new Date();
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        if (cart && cart.date) {
            date = new Date(cart.date);
            let a = moment([now.getFullYear(), now.getMonth(), now.getDate()]);
            let b = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
            if (a.diff(b, `days`) > -2) {
                date = new Date(moment().add(addDate, `days`).format('YYYY-MM-DD'));;
            }
            cart.date = moment(date).format('YYYY-MM-DD');
            Helper.setLocalStorage('cart', JSON.stringify(cart))
        } else {
            cart.date = moment().format('YYYY-MM-DD');
            Helper.setLocalStorage('cart', JSON.stringify(cart))
        }
        let disableDate = new Date(moment().add(addDate, `days`).format('YYYY-MM-DD'));

        this.setState({
            is_loading: false,
            items: res.data,
            date: date,
            disableDate: disableDate
        })
        //end loading ...
    }

    componentDidMount() {
        this._fetch()
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }
        // Helper.removeStorage('items')
        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
            window.cartRef.current.renderCartItems()
        }
    }

    render() {
        if (this.state.is_loading) {
            return (
                <div className='content-full pt-5' style={{ backgroundColor: '#fff' }}>
                    <Loading />
                </div>
            )
        }

        const settings = {
            customPaging: function (i) {
                return (
                    <a href="/#">
                        <img src={`${baseUrl}/abstract0${i + 1}.jpg`} alt={"air service picuture"} />
                    </a>
                );
            },
            dots: true,
            dotsClass: "custom-dots",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div className='content-full' style={{ backgroundColor: '#fff' }}>
                <div className='container pt-5'>
                    <div className='row'>
                        <div className='col-12 col-lg-5 col-xl-6'>
                            <Slider {...settings}>
                                <div>
                                    <img src={baseUrl + "/abstract01.jpg"} alt={"air service picuture"} />
                                </div>
                                <div>
                                    <img src={baseUrl + "/abstract02.jpg"} alt={"air service picuture"} />
                                </div>
                                <div>
                                    <img src={baseUrl + "/abstract03.jpg"} alt={"air service picuture"} />
                                </div>
                                <div>
                                    <img src={baseUrl + "/abstract04.jpg"} alt={"air service picuture"} />
                                </div>
                            </Slider>
                        </div>
                        <div className='col-12 col-lg-7 col-xl-6'>
                            <Info />
                            <div className='service-modal-box mb-4'>
                                <div className='row mb-4'>
                                    <div className='col-12 col-sm-6 col-md-5 d-flex flex-column align-items-center mb-3'>

                                        <h5 className='text-center font-weight-bold'><i className="fa-solid fa-map-location-dot"></i> เลือกสถานที่</h5>
                                        {/* {if selected place render this} */}
                                        {
                                            this.state.place ?
                                                <>
                                                    <div className={'place-card active mb-2'}
                                                        onClick={() => {
                                                            navigate('/place')
                                                        }}
                                                    >
                                                        <div className='img'>
                                                            {Helper.displayPlaceIcon(this.state.place.placeType.name)}
                                                        </div>
                                                        <p className='mb-0'>
                                                            {Helper.displayPlaceText(this.state.place.placeType.name)}
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: 14, lineHeight: 1.2 }}>
                                                        {this.state.place.address}
                                                    </p>
                                                </>
                                                :

                                                <>
                                                    <div className={'place-card active mb-2'}
                                                        onClick={() => {
                                                            navigate('/place')
                                                        }}
                                                    >
                                                        <div className='img'>
                                                            <i className="fa-solid fa-magnifying-glass-location"></i>
                                                        </div>
                                                        <p className='mb-0'>
                                                            ยังไม่ได้เลือกสถานที่
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: 14, lineHeight: 1.2 }}>กรุณาเลือกสถานที่</p>
                                                </>
                                        }
                                        <Link className="login-button btn mb-2 " to={'/place'}>
                                            เลือกสถานที่
                                        </Link>
                                    </div>
                                    <div className='col-12 col-sm-6 col-md-7 '>
                                        <h5 className='text-center font-weight-bold'><i className="fa-regular fa-calendar-check"></i> วันที่ให้บริการ</h5>
                                        {/* <p className='mb-0 text-center'>{Helper.getDateThai(this.state.date.getTime())}</p> */}
                                        <div id="custom-datepicker">
                                            <DatePicker
                                                selected={this.state.date}
                                                minDate={this.state.disableDate}
                                                onChange={(date) => {
                                                    this.setState({
                                                        date: date
                                                    }, () => {
                                                        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
                                                        cart.date = moment(date).format('YYYY-MM-DD')
                                                        Helper.setLocalStorage('cart', JSON.stringify(cart));
                                                    })
                                                }}
                                                inline={true}
                                                locale="th"
                                                className="red-border"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.items.map((item, i) => {
                        return (
                            <div className={i % 2 === 0 ? 'service-bg gray mb-3' : 'service-bg  mb-3'} key={i}>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-sm-4 col-md-3'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <img src={item.images[0]} alt={"air service picture"} style={{ maxHeight: 350, maxWidth: '150%' }} />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-8 col-md-6 d-flex align-items-center'>
                                            <div className='service-box w-100'>
                                                <h5 className='mb-5'>{item.name}</h5>
                                                <p className='mb-0'><span className='text-muted'>เริ่มต้นที่</span></p>
                                                <div className='d-flex '>
                                                    <div>
                                                        <p className='price mb-0'>฿{Helper.numberFormat(item.prices[0])} / เครื่อง</p>
                                                    </div>
                                                    <div className='right-floated'>
                                                        <button type='button'
                                                            className='btn btn-service mb-0'
                                                            onClick={() => {
                                                                Helper.reactToDom(window.$('body'),
                                                                    <ServiceModal
                                                                        data={item}
                                                                    />
                                                                )
                                                            }}
                                                        >
                                                            เพิ่ม
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}

