import { Link, navigate } from '@reach/router';
import React from 'react';
import CardForm from '../../Components/CardForm';
import DashboardMenu from '../../Components/DashboardMenu';
import Loading from '../../Components/Loading';
import { Helper } from '../../Services/Helper';
import { getUserAddressList, omiseGetCreditCard } from '../../Services/userServices';
export default class Card extends React.Component {
    constructor(props) {
        super(props);
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let card = false;
        if (cart && cart.card) {
            card = cart.card;
        }
        this.state = {
            is_loading: true,
            items: [],
            selected: card,
        };
    }

    componentDidMount() {
        this.fetch()
    }


    async fetch() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }

        const res = await omiseGetCreditCard(currentUser.uid);
        console.log(res.data.data)

        let items = res.data.data.map(element => {
            return {
                id: element.id,
                cardExpiredMonth: element.expiration_month,
                cardExpiredYear: element.expiration_year,
                cardName: element.name,
                cardNumber: "xxxxxxxxxxxx" + element.last_digits,
                // securityCode: "123",
                brand: element.brand
            }
        });
        this.setState({
            items,
            is_loading: false
        })
    }

    renderCardNumber(number, options) {
        let numberConvert = number;
        let splitBy = '-';
        if (options && options.splitBy) {
            splitBy = options.splitBy;
        }
        if (number) {
            numberConvert = '';
            let i = 0;
            for (const num of number.match(/.{1,4}/g)) {
                if (i > 0) {
                    numberConvert += splitBy;
                }
                numberConvert += num;
                i++;
            }
        }
        return numberConvert;
    }

    render() {
        return (
            <div className='content-full' style={{ backgroundColor: '#ffe8b680' }}>
                <div className='container pt-5'>
                    <div className='row'>
                        {
                            this.props.mycard ?
                                <div className='col-12 col-md-3 col-lg-3'>
                                    <DashboardMenu active="card" />
                                </div>
                                : null
                        }
                        <div className={this.props.mycard ? 'col-12 col-md-9 col-lg-9' : 'col-12'}>
                            <h1 className='page-title'>{this.props.mycard ? `เครดิตการ์ดของฉัน` : `เครดิตการ์ด`}</h1>
                            {
                                !this.props.mycard ?
                                    <p className='text-center' style={{ color: '#555' }}>กรุณาเพิ่มหรือเลือกเครดิตการ์ดที่ต้องการใช้</p>
                                    : null
                            }
                            {
                                this.state.is_loading ?
                                    <Loading />
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className='row mt-4'>
                                            {
                                                this.state.items.map((item, i) => {
                                                    return (
                                                        <div className={`col-12 col-md-6 ${this.props.mycard ? "col-lg-4" : "col-lg-3"} mb-3`} key={i}>
                                                            <div className='place-card-container' >
                                                                <button className='btn btn-icon btn-sm custom-place-btn'
                                                                    onClick={() => {
                                                                        Helper.reactToDom(window.$('body'),
                                                                            <CardForm
                                                                                card={item}
                                                                                onSuccess={(card) => {
                                                                                    let cards = this.state.items;
                                                                                    cards[i] = card;
                                                                                    this.setState({
                                                                                        items: cards
                                                                                    })
                                                                                }}
                                                                                onDeleteSuccess={() => {
                                                                                    let cards = this.state.items.filter((e) => { return e.cardNumber !== item.cardNumber });
                                                                                    this.setState({
                                                                                        items: cards
                                                                                    })
                                                                                }}
                                                                            />
                                                                        )
                                                                    }}
                                                                >
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </button>
                                                                <div className={!this.props.mycard && this.state.selected.uid === item.uid ? 'place-card creditcard-box active mb-2' : 'place-card creditcard-box mb-2'}
                                                                    onClick={() => {
                                                                        if (this.props.mycard) { return; }
                                                                        this.setState({
                                                                            selected: item
                                                                        })
                                                                    }}
                                                                >

                                                                    <div className={!this.props.mycard && this.state.selected.uid === item.uid ? 'icon-check show' : 'icon-check'}>
                                                                        <i className="fa-solid fa-thumbtack"></i>
                                                                    </div>
                                                                    {/* <h4 className='mb-1'>Credit Card</h4> */}

                                                                    <h5 className='mb-0 mt-3'>
                                                                        <strong>{this.renderCardNumber(item.cardNumber, { splitBy: '-' })}</strong>
                                                                    </h5>
                                                                    <h5 className='mb-0'>{item.cardExpiredMonth}/{item.cardExpiredYear}</h5>
                                                                    <p className='mb-0'>{item.cardName}</p>
                                                                    <div className='d-flex justify-content-end align-items-end w-100 card-icon'>
                                                                        {
                                                                            item.brand === 'Visa' ?
                                                                                <img src={`${window.location.origin}/assets/images/visa.png`} alt="creditcard" />
                                                                                : null
                                                                        }
                                                                        {
                                                                            item.brand === 'Master Card' ?
                                                                                <img src={`${window.location.origin}/assets/images/master.png`} alt="creditcard" />
                                                                                : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <p style={{ fontSize: 14, lineHeight: 1.2 }}>{item.address}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className={`col-12 col-md-6 ${this.props.mycard ? "col-lg-4" : "col-lg-3"} mb-3`}>
                                                <button
                                                    onClick={() => {
                                                        Helper.reactToDom(window.$('body'),
                                                            <CardForm
                                                                onSuccess={(card) => {
                                                                    let cards = this.state.items;
                                                                    cards.push(card);
                                                                    this.setState({
                                                                        items: cards
                                                                    })
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                    className={"place-card creditcard-box justify-content-center align-items-center"}
                                                >
                                                    <i className="fa-solid fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center mb-5'>
                                            {
                                                this.props.mycard ?
                                                    // <Link to={'/dashboard'} className='btn btn-white mr-3'>กลับ</Link>
                                                    null
                                                    :
                                                    <button
                                                        onClick={() => {
                                                            let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
                                                            cart.card = this.state.selected;
                                                            Helper.setLocalStorage('cart', JSON.stringify(cart));
                                                            navigate('/payment')
                                                        }}
                                                        // to="/service/order"
                                                        className='btn btn-service mb-0'
                                                    >
                                                        ดำเนินการต่อ
                                                    </button>
                                            }
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}
