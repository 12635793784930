import React from 'react';
import Swal from 'sweetalert2';
import { Helper } from '../Services/Helper';
import { omiseAddCreditCard, omiseDeleteCreditCard, omiseEditCreditCard } from '../Services/userServices';

export default class CardForm extends React.Component {
    constructor(props) {
        super(props);
        let now = new Date();
        let creditCardYears = [];
        for (let index = now.getFullYear(); index <= (now.getFullYear() + 10); index++) {
            creditCardYears.push(index)
        }

        this.state = {
            creditCardYears: creditCardYears,
            card: props.card || false,
        };
    }

    async updateCard(data) {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        console.log(this.state.card)
        const res = await omiseEditCreditCard(this.state.card, currentUser.uid)

        if (this.props.onSuccess) {
            this.props.onSuccess(data);
        }
        Swal.fire({
            title: 'สำเร็จ',
            text: 'ทำการแก้ไขบัตรเรียบร้อยแล้ว',
            icon: 'success',
            position: 'top-end',
            toast: true,
            backdrop: false,
            showConfirmButton: false,
            timer: 2500
        })
        this.$modal.modal('hide')
    }

    async createCard(data) {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        const res = await omiseAddCreditCard(data, currentUser.uid);
        if (res.error) {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'ไม่สามารถเพิ่มบัตรได้',
                icon: 'success',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            this.$modal.modal('hide')
            return
        }
        if (this.props.onSuccess) {
            this.props.onSuccess(data);
        }
        Swal.fire({
            title: 'สำเร็จ',
            text: 'ทำการเพิ่มบัตรเรียบร้อยแล้ว',
            icon: 'success',
            position: 'top-end',
            toast: true,
            backdrop: false,
            showConfirmButton: false,
            timer: 2500
        })

    }

    async deleteCard() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        const res = await omiseDeleteCreditCard(currentUser.uid, this.state.card.id)

        if (this.props.onDeleteSuccess) {
            this.props.onDeleteSuccess();
        }
        Swal.fire({
            title: 'สำเร็จ',
            text: 'ทำการยกเลิกบัตรเรียบร้อยแล้ว',
            icon: 'success',
            position: 'top-end',
            toast: true,
            backdrop: false,
            showConfirmButton: false,
            timer: 2500
        })
        this.$modal.modal('hide')
    }

    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                style={{ maxWidth: '100%' }}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$form = window.$(ref)
                        this.$form.off().on('submit', (e) => {
                            e.preventDefault();
                            this.$submit = this.$form.find('button[type="submit"]');
                            if (this.$submit.hasClass('loading')) { return false; }
                            this.$submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (this.state.card) {
                                this.updateCard(data)
                                return;
                            }
                            this.createCard(data);
                        })
                    }}
                >
                    <div className="modal-content custom-modal" >
                        <div className="modal-body">
                            {
                                this.props.card ?
                                    <div className='d-flex mb-3'>
                                        <div className='right-floated'>
                                            <button type="button"
                                                className="btn btn-red"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: 'ยืนยันการยกเลิกบัตร',
                                                        icon: 'question',
                                                        reverseButtons: true,
                                                        showCancelButton: true,
                                                        confirmButtonText: 'ยกเลิกบัตรเครดิต',
                                                        cancelButtonText: 'ยกเลิก',
                                                        customClass: {
                                                            confirmButton: 'btn btn-service',
                                                            cancelButton: 'btn btn-white'
                                                        },
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            this.deleteCard(); // delete function
                                                        }
                                                    })
                                                }}
                                            >ยกเลิก</button>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="row credit-card-input-row">
                                <div className="col-12 col-md-12 mb-4">
                                    <div className="input-payment-form mb-6 selected">
                                        <label className="text-gray-600 fs-4">Card Number</label>
                                        <input
                                            // placeholder="0000 0000 0000 0000"
                                            id="credit-card-input"
                                            type="text"
                                            name="cardNumber"
                                            // autoComplete="off"
                                            // mask="9999999999999999"
                                            style={{ letterSpacing: '4px' }}
                                            defaultValue={this.state.card && this.state.card.cardNumber ? this.state.card.cardNumber : ``}
                                        />
                                        <span className="payment-svg">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 mb-4">
                                    <div className="input-payment-form mb-6">
                                        <label className="text-gray-600 fs-4">Name on Card</label>
                                        <input
                                            placeholder="ENTER YOUR NAME"
                                            name="cardName"
                                            autoComplete="off"
                                            defaultValue={this.state.card && this.state.card.cardName ? this.state.card.cardName : ``}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <div className="input-payment-form mb-6">
                                        <label className="text-gray-600 fs-4">Expiry</label>
                                        <select
                                            className="form-control form-control-solid"
                                            name="cardExpiredMonth"
                                            defaultValue={this.state.card && this.state.card.cardExpiredMonth ? this.state.card.cardExpiredMonth : ``}
                                        >
                                            <option value="">MM</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <div className="input-payment-form mb-6">
                                        <select
                                            className="form-control form-control-solid"
                                            name="cardExpiredYear"
                                            defaultValue={this.state.card && this.state.card.cardExpiredYear ? this.state.card.cardExpiredYear : ``}
                                        >

                                            <option value="">YY</option>
                                            {
                                                this.state.creditCardYears.map((year, i) => {
                                                    return (
                                                        <option value={year} key={i}>{year}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="input-payment-form mb-6">
                                        <label className="text-gray-600 fs-4">CVV</label>
                                        <input
                                            placeholder="• • •"
                                            maxLength="3"
                                            name="securityCode" />
                                    </div>
                                </div>
                                <input type="hidden" name="CardToken" value="" />
                                <input type="hidden" name="id" value={this.state.card.id} />
                            </div>
                            <div className='d-flex justify-content-center align-items-center btn-form'>
                                <button type="button"
                                    className="btn btn-white mr-4"
                                    data-dismiss="modal"
                                >ปิด</button>
                                <button type="submit" className={"btn submit-btn"}>
                                    {
                                        this.state.card ? `บันทึก` : `เพิ่มบัตร`
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}