import { Link, navigate } from '@reach/router';
import React from 'react';
import Loading from '../../Components/Loading';
import { Helper } from '../../Services/Helper';
import { getUserAddressList } from '../../Services/userServices';
import DashboardMenu from '../../Components/DashboardMenu'
export default class Place extends React.Component {
    constructor(props) {
        super(props);
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let place = false;
        if (cart && cart.place) {
            place = cart.place;
        }
        this.state = {
            is_loading: true,
            items: [],
            selected: place,
        };
    }
    componentDidMount() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }
        getUserAddressList(currentUser.uid).then(e => {
            this.setState({
                items: e.data,
                is_loading: false
            })
        })
    }



    render() {


        return (
            <div className='content-full' style={{ backgroundColor: '#ffe8b680' }}>
                <div className='container pt-5'>
                    <div className='row'>
                        {
                            this.props.myplace ?
                                <div className='col-12 col-md-3 col-lg-3'>
                                    <DashboardMenu active="place" />
                                </div>
                                : null
                        }
                        <div className={this.props.myplace ? 'col-12 col-md-9 col-lg-9' : 'col-12'}>
                            <h1 className='page-title'>{this.props.myplace ? `สถานที่ของฉัน` : `สถานที่`}</h1>
                            <p className='text-center' style={{ color: '#555' }}>กรุณาเพิ่มหรือเลือกสถานที่ของท่านเพื่อเข้ารับบริการ</p>
                            {
                                this.state.is_loading ?
                                    <Loading />
                                    : null
                            }
                            {
                                !this.state.is_loading ?
                                    <>
                                        <div className='row'>
                                            {
                                                this.state.items.map((item, i) => {
                                                    return (
                                                        <div className={`col-6 col-md-4 ${this.props.myplace ? "col-lg-3" : "col-lg-2 "} mb-3`} key={i}>
                                                            <div className='place-card-container'>
                                                                <Link to={this.props.myplace ? `/my-place/${item.uid}` : `/place/${item.uid}`} className='btn btn-icon btn-sm custom-place-btn'>
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </Link>
                                                                <div className={!this.props.myplace && this.state.selected.uid === item.uid ? 'place-card active mb-2' : 'place-card mb-2'}
                                                                    onClick={() => {
                                                                        if (this.props.myplace) { return; }
                                                                        this.setState({
                                                                            selected: item
                                                                        })
                                                                    }}
                                                                >

                                                                    <div className={!this.props.myplace && this.state.selected.uid === item.uid ? 'icon-check show' : 'icon-check'}>
                                                                        <i className="fa-solid fa-thumbtack"></i>
                                                                    </div>
                                                                    <div className='img'>
                                                                        {Helper.displayPlaceIcon(item.placeType.name)}
                                                                    </div>
                                                                    <p className='mb-0'>
                                                                        {Helper.displayPlaceText(item.placeType.name)}
                                                                    </p>
                                                                </div>
                                                                <p style={{ fontSize: 14, lineHeight: 1.2 }}>{item.address}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className={`col-6 col-md-4 ${this.props.myplace ? "col-lg-3" : "col-lg-2 "} mb-3`}>
                                                <Link to={this.props.myplace ? '/my-place/add' : '/place/add'}
                                                    className={"place-card"}
                                                >
                                                    <i className="fa-solid fa-plus"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center mb-5'>
                                            {
                                                this.props.myplace ?
                                                    // <Link to={'/dashboard'} className='btn btn-white mr-3'>กลับ</Link>
                                                    null
                                                    :
                                                    <button
                                                        onClick={() => {
                                                            let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
                                                            cart.place = this.state.selected;
                                                            Helper.setLocalStorage('cart', JSON.stringify(cart));
                                                            navigate('/service/order')
                                                        }}
                                                        // to="/service/order"
                                                        className='btn btn-service mb-0'
                                                    >
                                                        {
                                                            this.props.myplace ?
                                                                `เรียกบริการ`
                                                                : `ดำเนินการต่อ`
                                                        }

                                                    </button>
                                            }
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

