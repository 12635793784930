import React from 'react';
import { Helper } from '../Services/Helper';
const axios = require('axios').default;

export default class AirserviceOmiseCallback extends React.Component {

    constructor() {
        super();
        this.state = {
            status: "Loading ...",
            // status: "payment fail",
            isLoading: true,
            failure_code: false,
            failure_message: false,
            openedWindow: null
        }
    }

    componentDidMount() {
        console.log('hi')
        if (!Helper.getParameterByName('bookId')) {
            this.setState({
                status: "unknown",
                isLoading: false
            })
        }
        console.log(Helper.getParameterByName('bookId'))
        if (Helper.getParameterByName('bookId')) {
            console.log(window.api_airservice_host + `/payment-app-callback/${Helper.getParameterByName('bookId')}`)
            axios.get(window.api_airservice_host + `/payment-app-callback/${Helper.getParameterByName('bookId')}`,).then(async res => {
                console.log(res)
                window.ReactNativeWebView.postMessage("goBack");
                let failure_code = false;
                let failure_message = false;
                if (res.data.failure_code) {
                    failure_code = res.data.failure_code
                }
                if (res.data.failure_message) {
                    failure_message = res.data.failure_message
                }
                let status = "unknown";
                if (res.data.code == 200) {
                    status = "payment success";
                    // this.setState({ status: "payment success" })
                } else if (res.data.code == 201) {
                    status = "payment fail";
                    // this.setState({ status: "payment fail" })
                }
                this.setState({
                    isLoading: false,
                    status: status,
                    failure_code: failure_code,
                    failure_message: failure_message,
                });

            }).catch(error => {
                console.log(error)
                this.setState({
                    isLoading: false,
                    status: "error"
                });
            });
        }
    }

    linktoapp() {

    }

    render() {
        return (
            <>
                <div className="container content-bg omise-complete-ui mt-5 pt-3">
                    Loading ...
                </div>
            </>
        );
    }
}