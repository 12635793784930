import React from 'react';
import { Link } from '@reach/router'
// import { Helper } from '../Services/Helper';
// import Swal from 'sweetalert2';
// import { navigate } from '@reach/router';

export default class Footer extends React.Component {


    render() {
        return (
            <footer>

                <div className='footer-logo'>
                    <Link to={"/"}>
                        <img src={window.location.origin + "/assets/images/logo.png"} alt={"ติดต่อเรา"} />
                    </Link>
                </div>
                <div style={{ flex: 1 }}>
                    <div className='footer'>
                        <div className='footer-detail'>
                            <h4>ติดต่อเรา</h4>
                            <p className='mb-0'>50/804 หมู่ที่ 9 ถนนบอนด์สตรีท ตำบลบางพลู อำเภอปากเกร็ด จังหวัดนนทบุรี 11120</p>
                            <p className='mb-0'>
                                <i className="fa-solid fa-square-phone mr-2"></i>
                                02-027-7848 (Customer Service)
                            </p>
                        </div>
                        <div className='footer-detail' style={{ flex: 1 }}>
                            <ul>
                                <li>
                                    <a href="https://www.instagram.com/beecleanapp/">
                                        <i className="fa-brands fa-instagram mr-2"></i>
                                        BEEclean
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/beecleanapp">
                                        <i className="fa-brands fa-facebook mr-2"></i>
                                        BEEclean
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/beecleanapp">
                                        <i className="fa-brands fa-twitter mr-2"></i>
                                        BEEclean
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='footer-detail'>
                            <h4>นโยบายและข้อกำหนด</h4>
                            <ul>
                                <li>
                                    <Link to={'/privacy-policy'}>
                                        นโยบายและข้อกำหนด
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/terms'}>
                                        ข้อตกลงการใช้งาน
                                    </Link>
                                </li>
                                <li>
                                    <a href="/">
                                        การจัดเก็๋บคุ๊กกี้เว็ปไซต์
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}