import React from 'react';
import Loading from './../../Components/Loading'
import { Link } from '@reach/router';
import ImageRender from '../../Components/ImageRender';
import { Helper } from '../../Services/Helper';
import { paymentResultAPI } from '../../Services/aircleanServices';
import DashboardMenu from '../../Components/DashboardMenu';
export default class Result extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: null,
            test_item: [1],
            user: false
        }
    }

    componentDidMount() {
        let bookId = Helper.getParameterByName('bookId') || false;
        paymentResultAPI(bookId).then(e => {
            let data = false;
            if (!e.error) {
                data = e;
            }
            let user = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage('loginuser')) : false;
            this.setState({ loading: false, data: data, user: user })
        })
    }



    render() {
        let nowDate = new Date();
        let bookId = Helper.getParameterByName('bookId') || false;
        // console.log(this.state.user)
        return (
            <>
                <div className='content-full booking-detail-content' style={{ backgroundColor: '#ffe8b680' }}>
                    <div className='container pt-5 '>
                        <div className='row'>
                            <div className='col-12 col-md-3 col-lg-3'>
                                <DashboardMenu
                                    active="history"
                                />
                            </div>
                            <div className='col-12 col-md-9 col-lg-9 custom-history-card'>
                                {
                                    this.state.loading ?
                                        <div className='d-flex justify-content-center mt-5'><Loading /></div>
                                        : null
                                }
                                {
                                    !this.state.loading
                                        && this.state.data ?
                                        <>
                                            <div className='card payment-card mb-4'>
                                                <div className='card-body'>
                                                    <div className='d-flex'>
                                                        <h3 className='mb-0'>Booking No : {bookId ? <small>{bookId}</small> : null}</h3>
                                                        <div className='right-floated'>
                                                            <Link to="/service/history" className='btn btn-white mb-0'>
                                                                กลับ
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-md-6'>

                                                    <div className='card mb-4'>
                                                        <div className="card-header">
                                                            <h5 className="card-title align-items-start flex-column mb-0">
                                                                <span className="card-label fw-bold text-black">รายละเอียด</span>
                                                            </h5>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">สถานะ</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {Helper.renderStatus(this.state.data.status == 'wait' ? 'accept' : this.state.data.status)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr className='hr-color mt-3 mb-3' />
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">สมาชิก</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.user.displayName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">เบอร์โทรศัพท์</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.user.phoneNumber ? Helper.renderPhone(this.state.user.phoneNumber) : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr className='hr-color mt-3 mb-3' />
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">วันเวลาที่จอง</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {Helper.getDateThai(this.state.data.created, { is_show_time: true })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">วันเวลาที่ให้บริการ</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {Helper.getDateThai(this.state.data.bookDate, { is_show_time: true })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr className='hr-color mt-3 mb-3' />
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">ชื่อสถานที่</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.data && this.state.data.place && this.state.data.place.name ? this.state.data.place.name : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">ประเภท</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.data.place && this.state.data.place.placeType && this.state.data.place.placeType.name_th ? this.state.data.place.placeType.name_th : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">พื้นที่</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {
                                                                            this.state.data.place
                                                                                && this.state.data.place.placeType
                                                                                && this.state.data.place.placeType.place_sub_type[this.state.data.place.placeSubTypeUid]
                                                                                && this.state.data.place.placeType.place_sub_type[this.state.data.place.placeSubTypeUid].name_th
                                                                                ? this.state.data.place.placeType.place_sub_type[this.state.data.place.placeSubTypeUid].name_th
                                                                                : '-'
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-2">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">เบอร์โทรศัพท์</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.data.place && this.state.data.place.phoneNumber ? this.state.data.place.phoneNumber : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.data.place && this.state.data.place.address ? this.state.data.place.address : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr className='hr-color mt-3 mb-3' />
                                                            <div className="row">
                                                                <label className="col-4 col-lg-4 fw-semibold text-gray-800 text-th mb-0">หมายเหตุ</label>
                                                                <div className="col-8 col-lg-8">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center">
                                                                        {this.state.data.place && this.state.data.place.note ? this.state.data.place.note : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <RenderTimeline
                                                        data={this.state.data}
                                                    />
                                                </div>
                                                <div className='col-12 col-md-6'>
                                                    {
                                                        this.state.data.partner ?
                                                            <div className='card mb-4'>
                                                                <div className='card-body'>
                                                                    <div className='row'>
                                                                        <div className='col-12 col-lg-3 mb-3'>
                                                                            <div className='position-relative' style={{ maxWidth: `100%` }}>
                                                                                {
                                                                                    this.state.data.partner.image ?

                                                                                        <img src={this.state.data.partner.image} style={{ maxWidth: `100%` }} />
                                                                                        :
                                                                                        <img src={`${window.location.origin}/assets/images/beeclean-user-logo.png`} style={{ maxWidth: `100%` }} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-9'>
                                                                            <h5>ชื่อ {this.state.data.partner.name}</h5>
                                                                            <p className='mb-0'><strong>ประเภท : </strong> {this.state.data.partner.typeId == 1 ? "นิติบุคคล" : "บุคคล"}</p>
                                                                            <p className='mb-0'><strong>เบอร์โทรศัพท์ : </strong> {Helper.renderPhone(`+${this.state.data.partner.phoneNumber}`)}</p>
                                                                            <p className='mb-0'><strong>อีเมล์ : </strong> {this.state.data.partner.email}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    <div className='card mb-4'>
                                                        <div className="card-header">
                                                            <h5 className="card-title align-items-start flex-column mb-0">
                                                                <span className="card-label fw-bold text-black text-th">สรุปผลค่าบริการ</span>
                                                            </h5>
                                                        </div>
                                                        <div className='card-body'>
                                                            {
                                                                this.state.data.services.map((service, i) => {
                                                                    return (
                                                                        <div className='row text-th mb-2' key={i}>
                                                                            <div className='col-12 col-lg-3'>
                                                                                <div className='position-relative' style={{ maxWidth: `100%` }}>
                                                                                    {
                                                                                        service.image ?

                                                                                            <img src={service.image} style={{ maxWidth: `100%` }} />
                                                                                            :
                                                                                            <img src={`${window.location.origin}/assets/images/beeclean-user-logo.png`} style={{ maxWidth: `100%` }} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6 col-lg-5'>
                                                                                <div className='fs-5 '><strong>{service.name}</strong></div>
                                                                                <div className='fs-7 '>{service.size}</div>
                                                                                <div className='fs-7 '>จำนวน <strong>{Helper.numberFormat(service.amount)}</strong> เครื่อง</div>
                                                                            </div>
                                                                            <div className='col-12 col-md-6 col-lg-4 d-flex justify-content-end'>
                                                                                <div className='fs-5 '>ราคา <strong>{Helper.numberFormat(service.amount * service.price)}</strong> ฿</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <hr className='hr-color mt-3 mb-3' />
                                                            <div className="d-flex mb-1">
                                                                <label className="fw-semibold text-gray-800 text-th mb-0">ค่าบริการ</label>
                                                                <div className="right-floated">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center justify-content-end">
                                                                        {Helper.numberFormat(this.state.data.priceTotal)} ฿
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mb-1">
                                                                <label className="fw-semibold text-gray-800 text-th mb-0">ส่วนลด</label>
                                                                <div className="right-floated">
                                                                    <span className="fw-bold fs-6 text-black text-th d-flex align-items-center justify-content-end">
                                                                        {Helper.numberFormat(this.state.data.pricePromotionDiscount)} ฿
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mb-0">
                                                                <strong className="fw-semibold text-gray-800 text-th fs-3 mb-0">ค่าบริการทั้งหมด</strong>
                                                                <div className="right-floated">
                                                                    <strong className="fs-3 text-black text-th d-flex align-items-center justify-content-end">
                                                                        {Helper.numberFormat(this.state.data.priceTotal)} ฿
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 
                                            <div className='card'>
                                                <div className="card-header">
                                                    <h5 className="card-title align-items-start flex-column mb-0">
                                                        <span className="card-label fw-bold text-black text-th">ข้อมูลส่งงาน</span>
                                                    </h5>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        {
                                                            this.state.test_item.map(() => {
                                                                return (
                                                                    <div className='col-md-3 mb-4'>
                                                                        <ImageRender file={{
                                                                            imagePath: `${window.location.origin}/assets/images/beeclean-user-logo.png`
                                                                        }} disableTitle={true} title={`รูปส่งงาน`} customImageGroup={"fileImages"} frameHeight={90} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <hr className='hr-color mt-3 mb-3' />
                                                    <div className="d-flex mb-3">
                                                        <label className="fw-semibold text-gray-800 text-th">ความประทับใจลูกค้า</label>
                                                        <div className="right-floated">
                                                            <span className="fw-bold fs-6 text-black text-th d-flex align-items-center justify-content-end">
                                                                <i className='fa-solid fa-star text-warning' />
                                                                <i className='fa-solid fa-star text-warning' />
                                                                <i className='fa-solid fa-star text-warning' />
                                                                <i className='fa-solid fa-star text-warning' />
                                                                <i className='fa-solid fa-star text-warning' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-lg-12 mb-2 fw-semibold text-gray-800 text-th">รายงาน</label>
                                                        <div className="col-lg-12">
                                                            <span className="fw-bold fs-6 text-black text-th d-flex align-items-center justify-content-end">
                                                                บ้านเลขที่ 8/88 หมู่บ้านสิธิธร ถนนประชาอุทิศ ซอย 76 เขตทุ่งครุ จังหวัดกรุงเทพฯ ตามหมุดบ้านสีส้มขวามือ ก่อนถึงป้อม รปภ.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}


                                                </div>
                                            </div>
                                        </>
                                        : null
                                }
                                {
                                    !this.state.loading
                                        && !this.state.data ?
                                        <h3 className='page-title'>ไม่พบข้อมูลการใช้บริการ</h3>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class RenderTimeline extends React.Component {
    render() {
        return (
            <div className='card mb-5'>
                <div className='card-body'>
                    <div className='timeline'>
                        <div className='timeline-item' style={{ marginBottom: this.props.data.doneAt ? 10 : 0 }}>
                            <div className="timeline-line w-40px" style={{ bottom: this.props.data.doneAt ? -10 : 0 }}></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label bg-primary">
                                    <span className="svg-icon svg-icon-2 svg-icon-white">
                                        <i className="las la-handshake fs-1 text-white"></i>
                                    </span>
                                </div>
                            </div>
                            <div className={this.props.id == 2 ? "timeline-content mb-10 mt-n2" : "timeline-content mb-0 mt-n2"}>
                                <div className='overflow-auto pe-3'>
                                    <div className='fs-5 fw-semibold mb-0 text-th'>ยอมรับ</div>
                                    <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>{Helper.getDateThai(this.props.data.created, { is_show_time: true })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='timeline-item'>
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label bg-warning">
                                    <span className="svg-icon svg-icon-2 svg-icon-white">
                                        <i className="las la-route fs-1 text-white"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                                <div className='overflow-auto pe-3'>
                                    <div className='fs-5 fw-semibold mb-0 text-th'>เริ่มเดินทาง</div>
                                    <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>{Helper.getDate(new Date(), { is_show_time: true })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='timeline-item'>
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label" style={{ backgroundColor: 'darkblue' }}>
                                    <span className="svg-icon svg-icon-2 svg-icon-white">
                                        <i className="las la-map-marked-alt fs-1 text-white"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                                <div className='overflow-auto pe-3'>
                                    <div className='fs-5 fw-semibold mb-0 text-th'>ถึง</div>
                                    <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>{Helper.getDate(new Date(), { is_show_time: true })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='timeline-item'>
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label bg-info">
                                    <span className="svg-icon svg-icon-2 svg-icon-white">
                                        <i className="las la-broom fs-1 text-white"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                                <div className='overflow-auto pe-3'>
                                    <div className='fs-5 fw-semibold mb-0 text-th'>เริ่มทำงาน</div>
                                    <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>{Helper.getDate(new Date(), { is_show_time: true })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='timeline-item'>
                            <div className="timeline-line w-40px"></div>
                            <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label bg-primary">
                                    <span className="svg-icon svg-icon-2 svg-icon-white">
                                        <i className="las la-clipboard-check fs-1 text-white"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mb-10 mt-n2">
                                <div className='overflow-auto pe-3'>
                                    <div className='fs-5 fw-semibold mb-0 text-th'>เสร็จงาน</div>
                                    <div className='d-flex align-items-center mt-1 fs-6'>
                                        <div className='text-muted me-2 fs-7'>{Helper.getDate(new Date(), { is_show_time: true })}</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {
                            this.props.data.doneAt ?
                                <div className='timeline-item'>
                                    <div className="timeline-line w-40px"></div>
                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                        <div className="symbol-label bg-success">
                                            <span className="svg-icon svg-icon-2 svg-icon-white">
                                                <i className="las la-calendar-check fs-1 text-white"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="timeline-content mb-0 mt-n2">
                                        <div className='overflow-auto pe-3'>
                                            <div className='fs-5 fw-semibold mb-0 text-th'>ส่งงาน</div>
                                            <div className='d-flex align-items-center mt-1 fs-6'>
                                                <div className='text-muted me-2 fs-7'>{Helper.getDateThai(this.props.data.doneAt, { is_show_time: true })}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}