import React from "react";
import { Link, navigate } from "@reach/router";
import { Helper } from "../../Services/Helper";
import Loading from "../../Components/Loading";
import { getBookingHistory } from "../../Services/userServices";
import DashboardMenu from "../../Components/DashboardMenu";

export default class History extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            items: [],
            page: 1,
            rows: 10,
            show_viewmore: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        /// test 
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }
        getBookingHistory(currentUser.uid, this.state.page, this.state.rows).then(res => {
            console.log(res.data.length, this.state.rows)
            this.setState({
                is_loading: false,
                items: res.data.length ? this.state.items.concat(res.data) : this.state.items,
                page: this.state.page + 1,
                show_viewmore: res.data.length == this.state.rows
            }, () => {
                if (this.$viewMore) {
                    this.$viewMore.removeClass('loading')
                }
            })
        })
    }

    render() {

        return (
            <>
                <div className='content-full' style={{ backgroundColor: '#ffe8b680' }}>
                    <div className='container pt-5'>
                        <div className="row">
                            <div className="col-12 col-md-3 col-lg-3">

                                <DashboardMenu
                                    active={"history"}
                                />
                            </div>
                            <div className="col-12 col-md-9 col-lg-9">

                                <h1 className='page-title'>ประวัติการใช้บริการ</h1>
                                {/* <p className='text-center' style={{ color: '#555' }}>ประวัติการใช้บริการ</p> */}
                                {
                                    this.state.is_loading ?
                                        <div className="mt-5">
                                            <Loading />
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading ?
                                        <>
                                            <div className='row mt-5'>

                                                <div className="col-12  mb-5">
                                                    {
                                                        this.state.items
                                                            && this.state.items.length > 0 ?
                                                            this.state.items.map((item, i) => {
                                                                return (
                                                                    <Link key={i} className="history-list mb-3" to={`/payment/result?bookId=${item.uid}`}>
                                                                        <div className="d-flex flex-wrap">
                                                                            <div style={{ flex: 1 }}>

                                                                                <div className="d-flex align-items-center">
                                                                                    <strong className="mr-2">Booking No  : </strong> {item.uid}
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <strong className="mr-2">วันที่ให้บริการ : </strong> {Helper.getDateThai(item.bookDate, { is_show_time: true })}
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <strong className="mr-2">สถานะ : </strong> {Helper.renderStatus(item.status == 'wait' ? 'accept' : item.status)}
                                                                                </div>
                                                                            </div>
                                                                            <div >
                                                                                <div className="d-flex align-items-center">
                                                                                    <strong className="mr-2">วันที่จอง : </strong> {Helper.getDateThai(item.created, { is_show_time: true })}
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <strong className="mr-2">ค่าบริการทั้งหมด : </strong> ฿{Helper.numberFormat(item.priceTotal)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                )
                                                            })
                                                            : null
                                                    }

                                                    {
                                                        this.state.show_viewmore ?
                                                            <div className='d-flex justify-content-center align-items-center mt-4'>
                                                                <button
                                                                    ref={(ref) => {
                                                                        this.$viewMore = window.$(ref);
                                                                    }}
                                                                    onClick={(e) => {
                                                                        if (this.$viewMore.hasClass('loading')) { return; }
                                                                        this.$viewMore.addClass('loading')
                                                                        // fetch more
                                                                        this.fetch();
                                                                    }}
                                                                    // to="/service/order"
                                                                    className='btn btn-service mb-0'
                                                                >
                                                                    โหลดเพิ่ม
                                                                </button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>

                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}