import React from 'react';

export default class RuleInfo extends React.Component {
    render() {
        return (
            <>
                <h2 style={{ textAlign: 'center' }}>รายละเอียดขอบเขตการทำงาน</h2>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={`${window.location.origin}/assets/images/stickers/39.png`} style={{ height: 200 }} alt={"rule"} />
                </div>
                <h3>บริการของเราครอบคลุม</h3>
                <ul className='list-style check' style={{ paddingLeft: '1.5rem' }}>
                    <li>การเรียกใช้บริการ 1 ครั้ง/แม่บ้าน 1 ท่าน</li>
                    <li>ทำความสะอาด ห้องนอน ห้องน้ำ ห้องนั่งเล่น ห้องครัว</li>
                    <li>พื้นที่ส่วนกลาง , ระเบียง</li>
                    <li>ดูดฝุ่น ถูพื้น เช็ดหน้าต่าง (ในระยะที่เอื้อมถึง) นำขยะไปทิ้ง</li>
                    <li>ปัดฝุ่น เช็ดฝุ่น จัดระเบียบสิ่งของ</li>
                    <li>กรณีเปลี่ยนผ้าปู คุณลูกค้าต้องเตรียมไว้</li>
                    <li>ล้างแก้ว ถ้วย จาน ชาม ที่ใช้แล้ว</li>
                </ul>
                <p>*** กรณีที่ต้องการให้ทำความสะอาดภายในเครื่องใช้ไฟฟ้า (ตู้เย็น เตาอบ)
                    คุณลูกค้าต้องเพิ่มชั่วโมงมากกว่าที่ในระบบแนะนำ และระบุในหมายเหตุ ***</p>
                <h3>บริการที่ไม่ครอบคลุม</h3>
                <ul className='list-style cancel' style={{ paddingLeft: '1.5rem' }}>
                    <li>การทำความสะอาด Big Cleaning (ทำความสะอาดหลังงานก่อสร้าง,สถานที่ที่ไม่ได้ทำความสะอาดเกิน 2 เดือน)</li>
                    <li>ห้องย้าย เข้า/ออก (ติดต่อแอดมินเพื่อประเมินก่อนเรียกใช้บริการ)</li>
                    <li>การทำความสะอาดในที่สูง ปีนเก้าอี้ บันได</li>
                    <li>ซักผ้า รีดผ้า การทำสวน ล้างรถ และบริการภายนอกตัวบ้าน</li>
                </ul>
                {/* <h3>การยกเลิกบริการ</h3>
                <ul>
                    <li>หากมีการยกเลิกกระทันหันทางบริษัท น้อยกว่า 48 ชั่วโมง
                        หรือไม่สามารถติดต่อลูกค้าได้ในวันให้บริการ 30 นาทีขึ้นไป
                        ขอสงวนสิทธิ์ไม่คืนเงินเต็มจำนวนทุกกรณี</li>
                </ul> */}

                <h3>ข้อกำหนดและเงื่อนไขการให้บริการ</h3>
                <div className='ol-custom'>
                    <ol style={{ paddingLeft: '1.5rem' }}>
                        <li className='mt-0'>เงื่อนไขการยกเลิกการจองและการคืนเงิน
                            <ol className='ol-next'>
                                <li className='mt-0'>ในกรณีที่ยังไม่มีแม่บ้านรับงานของการจองนั้นๆ หากต้องการยกเลิกการจอง ท่านสามารถติดต่อแอดมินในเวลาทำการ ( 8.00-22.00 น.) ผ่านช่องทาง Line Official Account ( @beeclean ) โดยบริษัทจะทำการยกเลิกและคืนค่าบริการให้เต็มจำนวน</li>
                                <li className='mt-0'>ในกรณีที่มีแม่บ้านรับงานเป็นที่เรียบร้อยแล้ว หากต้องการยกเลิกการจอง จะมีค่าธรรมเนียมและค่าดำเนินการยกเลิก 100 บาท (ไม่รวม Vat) โดยบริษัทจะทำการหักจากยอดค่าบริการในการจองครั้งนั้น
                                    ( ส่วนต่างจะถูกโอนเข้าบัญชีของผู้ใช้บริการภายใน 1-3 วันทำการ  / สำหรับลูกค้าที่หักชำระค่าบริการผ่านบัตรเครดิตจะใช้เวลาประมาณ 7-14 วัน ขึ้นอยู่กับเงื่อนไขของธนาคารนั้นๆ )</li>
                                <li className='mt-0'>การยกเลิกการจองจะต้องแจ้งล่วงหน้าไม่น้อยกว่า 24 ชั่วโมงของเวลาเริ่มงาน โดยทางบริษัทขอสงวนสิทธิ์ในการไม่คืนค่าบริการที่ชำระแล้วหากผิดจากเงื่อนไขข้างต้น</li>
                                <li className='mt-0'>ในวันให้บริการ หากผู้ให้บริการทำความสะอาดเดินทางไปถึงที่นัดหมายตามวันเวลาให้บริการแล้ว แต่ไม่สามารถติดต่อลูกค้าได้ 30 นาทีขึ้นไป รวมถึงผู้ให้บริการไม่สามารถให้บริการได้ อันเนื่องจากฝ่ายนิติบุคคลของสถานที่พักไม่อนุญาตให้เข้าไปได้ ทางบริษัทขอสงวนสิทธิ์ในการไม่คืนค่าบริการที่ชำระแล้ว</li>
                                <li className='mt-0'>ในกรณีที่ลูกค้าต้องการใบกำกับภาษี กรุณาติดต่อแอดมินภายใน 3 วันนับจากวันที่ทำการจอง</li>
                            </ol>
                        </li>
                        <li className='mt-0'>เงื่อนไขการเลื่อนหรือเปลี่ยนแปลงวันเวลาให้บริการ
                            <ol className='ol-next'>
                                <li className='mt-0'>ในกรณีลูกค้าประสงค์ขอเปลี่ยนแปลงวันเวลาใช้บริการ ท่านสามารถติดต่อแอดมินในเวลาทำการ ( 8.00-22.00 น.) ผ่านช่องทาง Line Official Account ( @beeclean )โดยการเปลี่ยนแปลงเวลานั้นหากเวลาเข้าให้บริการน้อยกว่า 24 ชั่วโมงนับจากเวลาที่ทำการติดต่อแอดมิน จะมีค่าธรรมเนียมบริการจัดการเร่งด่วนเพิ่มเติม 100 บาท (ราคาไม่รวม Vat)</li>
                                <li className='mt-0'>ในกรณีลูกค้าที่ประสงค์ขอเลื่อนวันเวลาใช้บริการออกไป หากเวลาเข้าให้บริการมากกว่า 24 ชั่วโมงนับจากเวลาที่ทำการติดต่อแอดมินจะมีค่าธรรมเนียมดำเนินการ 50 บาท (ไม่รวม Vat)</li>
                            </ol>
                        </li>
                        <li className='mt-0'>การชดเชยความเสียหาย
                            <ol className='ol-next'>
                                <li className='mt-0'>หรับกรณีที่ผู้ให้บริการทำความสะอาด ก่อให้เกิดความเสียหายในทรัพย์สินของผู้ใช้บริการ ลูกค้าจะต้องแจ้งปัญหาให้กับทางบริษัทพร้อมหลักฐานภายใน 24 ชั่วโมง โดยบริษัทฯจะรับประกันความเสียหายภายในวงเงินไม่เกิน 10,000 บาท</li>
                                <li className='mt-0'>บริษัทฯ ไม่รับผิดชอบ และรับประกันทรัพย์สินมีค่าหรือของสูญหายในทุกกรณี (ยกเว้นมีหลักฐานจากกล้องวงจรปิดเท่านั้น) ขอความร่วมมือลูกค้าที่เรียกใช้บริการ จัดเก็บทรัพย์สินและของมีค่าไว้ในที่ปลอดภัยให้เรียบร้อย ก่อนผู้ให้บริการทำความสะอาดเข้าไปถึง</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <strong>บริการนี้รวมอุปกรณ์ทำความสะอาดและค่าเดินทางแล้ว</strong>
                </div>
            </>
        )
    }
}