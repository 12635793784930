
import React from 'react';
import { Helper } from '../Services/Helper';
import { db, onSnapFirebase } from "../Services/firebase";
import { doc } from "firebase/firestore"
const axios = require('axios').default;
export default class AirserviceOmiseCallback extends React.Component {
    constructor() {
        super();
        let merchantReference = Helper.getParameterByName("merchantReference") || false;
        this.state = {
            merchantReference: merchantReference,
            render_type: 'loading'
        }
    }
    componentDidMount() {
        this.init();
    }
    init() {
        if (this.state.merchantReference) {
            this.onSnapWegate = onSnapFirebase(doc(db, "onSnapWegate", this.state.merchantReference), (e) => {
                if (e.exists) {
                    console.log('wait for noti')
                    let paymentStatusCode = e.data().paymentStatusCode;
                    if (paymentStatusCode) {
                        if (paymentStatusCode == "004") {
                            this.setState({ render_type: "success" })
                        } else if (paymentStatusCode == "006" || paymentStatusCode == "007" || paymentStatusCode == "099") {
                            this.setState({ render_type: "error" })
                        }
                    }
                } else {
                    this.setState({ render_type: "loading" })
                }
            })
        }
    }

    linktoapp() {
        window.ReactNativeWebView.postMessage("goBack");
    }

    render() {
        return (
            <>
                <div className="container content-bg omise-complete-ui">
                    <div className={"title"}>
                        {
                            this.state.render_type == 'success' ?
                                <>
                                    <div style={{ textAlign: 'center', marginBottom: 25 }}>
                                        <img src={window.base_url + "/assets/images/15.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                    </div>
                                    <div>
                                        <div style={{ color: 'green' }}>ชำระเงินสำเร็จ</div>
                                        <small style={{ color: 'green', opacity: 0.7 }}>Payment successfully</small>
                                    </div>
                                </>
                                : null
                        }
                        {
                            this.state.render_type == 'error' ?
                                <>
                                    <div style={{ textAlign: 'center', marginBottom: 25 }}>
                                        <img src={window.base_url + "/assets/images/29.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                    </div>

                                    <div>
                                        <div style={{ color: 'red' }}>ชำระเงินไม่สำเร็จ</div>
                                        <small style={{ color: 'red', opacity: 0.7 }}>Payment Failed</small><br />
                                        <small style={{ opacity: 0.7 }}>โปรดกลับไปเพื่อทำการชำระเงินใหม่อีกครั้ง</small>
                                    </div>
                                </>
                                : null
                        }
                        {
                            this.state.render_type == 'loading' ?
                                <>
                                    <div style={{ textAlign: 'center', marginBottom: 25 }}>
                                        <img src={window.base_url + "/assets/images/9.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                                    </div>
                                    <>
                                        <div>
                                            <div className="text-primary">กรุณารอสักครู</div>
                                            <small className="text-primary" style={{ opacity: 0.7 }}>Please wait</small><br />
                                            <small style={{ opacity: 0.7 }}>ระบบกำลังทำการยืนยันการชำระเงิน</small>
                                        </div>
                                    </>
                                </>
                                : null
                        }
                        <br />
                        {/* <button className={"beeclean-button btn"} style={{ width: 'auto' }} onClick={this.linktoapp}>กดเพื่อกลับไปยัง Application</button> */}
                    </div>
                </div>
            </>
        )
    }
}