import React, { Component } from 'react'
import { Helper } from '../../Services/Helper'
import Swal from 'sweetalert2'
import { navigate } from '@reach/router'

export default class TaxInvoice extends Component {
    render() {
        return (<>
            <div className='content-full center' style={{ backgroundColor: '#ffe8b6' }}>
                <div>
                    <form
                        className='login-box mb-4'

                        ref={(ref) => {
                            this.$form = window.$(ref)
                            this.submit = this.$form.find(`button[type="submit"]`)
                        }}
                        onSubmit={async (e) => {
                            e.preventDefault();
                            if (this.submit.hasClass('loading')) { return false; }
                            this.submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            if (!data.phoneNumber) {
                                Swal.fire({
                                    // title: `Warning`,
                                    icon: 'warning',
                                    html: 'กรุณากรอกเบอร์โทรศัพท์',
                                })
                                this.submit.removeClass('loading');
                                return;
                            }
                            if (data.phoneNumber
                                && (data.phoneNumber.length < 9 || data.phoneNumber.length > 10)) {
                                Swal.fire({
                                    // title: `Warning`,
                                    icon: 'warning',
                                    html: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
                                })
                                this.submit.removeClass('loading');
                                return;
                            }
                            data.phoneNumber = data.phoneNumber.replace(' ', '');
                            data.phoneNumber = data.phoneNumber.replace('-', '');
                            navigate(`/request/tax-invoice/${data.phoneNumber}`)
                            this.submit.removeClass('loading');

                        }}
                    >
                        <h2 className='title mb-4'>ส่งคำขอทำใบกำกับภาษี</h2>
                        <p className='mb-1 text-center'>กรุณากรอกหมายเลขโทรศัพท์ของท่าน</p>
                        <div className='d-flex justify-content-center align-items-center pl-3 pr-3 mb-3'>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="las la-phone mr-0"></i></span>
                                </div>
                                <input
                                    type="number"
                                    className="form-control hide-arrow"
                                    name={"phoneNumber"}
                                    style={{ letterSpacing: 5 }}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (value.length > 10) {
                                            let newValue = value.substring(0, 10)
                                            window.$(e.target).val(newValue)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <button
                                type={"submit"}
                                className={`submit-btn btn`}
                            >
                                ถัดไป
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>)
    }
}
