import React from 'react';
import InfoService from './../Page/services/include/InfoService'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helper } from '../Services/Helper';
import Swal from 'sweetalert2';
const baseUrl = `https://s3.amazonaws.com/static.neostack.com/img/react-slick`
export default class ServiceModal extends React.Component {
    constructor() {
        super();
        this.sizeRef = React.createRef();
    }

    componentDidMount() {
        this.calculatePrice();
    }

    calculatePrice() {
        let defaultAmount = parseFloat(this.serviceAmount.val());
        let sizeIndex = this.sizeRef.current.getSizeIndex();
        let currentPrice = parseFloat(this.props.data.prices[sizeIndex]);

        let price = 0;
        if (isNaN(defaultAmount)) {
            defaultAmount = 1;
        }
        if (isNaN(currentPrice)) {
            currentPrice = 0;
        }
        price = defaultAmount * currentPrice;
        let priceNode = window.$('.summary-text strong');
        priceNode.html(Helper.numberFormat(price))
        // console.log(defaultAmount, this.props.data.prices[sizeIndex], price)
    }

    render() {
        const settings = {
            customPaging: (i) => {
                return (
                    <a href="/#">
                        <img src={this.props.data.images[i]} alt={"Air Service "} />
                    </a>
                );
            },
            dots: true,
            dotsClass: "custom-dots",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        console.log(this.props.data)
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                style={{ maxWidth: '100%' }}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <form className="modal-dialog modal-lg" role="document"
                    style={{ maxWidth: 1200 }}
                    ref={(ref) => {
                        this.$form = window.$(ref)
                        this.$form.off().on('submit', (e) => {
                            e.preventDefault();
                            // if (this.props.addItems) {
                            //     this.props.addItems({ id: 1, name: `items` + Math.random() })
                            // }
                            let $submit = this.$form.find('button[type="submit"]');
                            if ($submit.hasClass('loading')) { return false; }
                            $submit.addClass('loading');
                            let data = Helper.getFormInputObject(this.$form);
                            let storageCartItems = Helper.getLocalStorage('items');
                            data.service = this.props.data;
                            data.uid = this.props.data.uid;
                            data.id = this.props.data.uid + data.selectIndex;
                            let cartItems = storageCartItems ? JSON.parse(storageCartItems) : [];
                            let addedIndex = cartItems.findIndex((item) => {
                                return item.uid == data.service.uid
                                    && item.selectIndex == data.selectIndex
                            })
                            if (addedIndex != -1) {
                                cartItems[addedIndex] = data
                            } else {
                                cartItems.push({ ...data });
                            }

                            Helper.setLocalStorage('items', JSON.stringify(cartItems))
                            if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
                                window.cartRef.current.renderCartItems()
                            }

                            Swal.fire({
                                title: 'เพิ่มบริการ',
                                text: 'ทำการเพิ่มบริการเรียบร้อยแล้ว',
                                icon: 'success',
                                position: 'top-end',
                                toast: true,
                                backdrop: false,
                                showConfirmButton: false,
                                timer: 2500
                            })
                            this.$modal.modal('hide')

                        })
                    }}
                >
                    <div className="modal-content custom-modal" >
                        <div className="modal-body">
                            <div className='row mb-4 justify-content-center align-items-center'>
                                <div className='col-12 col-md-3'>
                                    <Slider {...settings}>
                                        {this.props.data.images.map((element, i) => {
                                            return (
                                                <div key={i}>
                                                    <img src={element} alt={"Air Service "} style={{ maxHeight: 350, maxWidth: '100%' }} />
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <InfoService data={this.props.data} />
                                </div>
                            </div>
                            <div className='service-modal-container mb-4'>
                                <div className='service-modal-box'>
                                    <div className='row mb-3'>
                                        <div className='col-12 col-md-3 align-items-center d-flex'>
                                            <label className='mb-0'>ขนาดแอร์ BTU :</label>
                                        </div>
                                        <div className='col-12 col-md-9'>
                                            <ServiceSelectBox
                                                ref={this.sizeRef}
                                                data={this.props.data.size}
                                                calculatePrice={() => { this.calculatePrice() }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-12 col-md-3 align-items-center d-flex'>
                                            <label className='mb-0'>จำนวนเครื่อง :</label>
                                        </div>
                                        <div className='col-12 col-md-9'>
                                            <div className='d-flex align-items-center'>
                                                <button
                                                    type="button"
                                                    className='circle-yellow'
                                                    onClick={() => {
                                                        let defaultAmount = parseFloat(this.serviceAmount.val());
                                                        if (isNaN(defaultAmount)) {
                                                            defaultAmount = 1;
                                                        }
                                                        let newAmount = defaultAmount - 1;
                                                        if (newAmount < 1) {
                                                            newAmount = 1;
                                                        }
                                                        this.serviceAmount.val(newAmount)
                                                        this.calculatePrice();
                                                    }}
                                                >
                                                    <i className="fa-solid fa-minus"></i>
                                                </button>
                                                <input ref={(ref) => { this.serviceAmount = window.$(ref); }} type="text" name="amount" className='form-control service-amount' defaultValue={'1'}
                                                    onChange={() => {
                                                        this.calculatePrice();
                                                    }}
                                                />
                                                <button
                                                    type="button"
                                                    className='circle-yellow'
                                                    onClick={() => {
                                                        let defaultAmount = parseFloat(this.serviceAmount.val());
                                                        if (isNaN(defaultAmount)) {
                                                            defaultAmount = 1;
                                                        }
                                                        let newAmount = defaultAmount + 1;
                                                        if (newAmount < 1) {
                                                            newAmount = 1;
                                                        }
                                                        this.serviceAmount.val(newAmount)
                                                        this.calculatePrice();
                                                    }}
                                                >
                                                    <i className="fa-solid fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <p className='summary-text'>ยอดชำระทั้งหมด <strong>{Helper.numberFormat(this.props.data.prices[0])} บาท</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center btn-form'>
                                <button type="button"
                                    className="btn btn-service mr-4"
                                    data-dismiss="modal"
                                >ยกเลิก</button>
                                <button type="submit" className={"btn submit-btn"}>เพิ่มลงในรายการ</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

class ServiceSelectBox extends React.Component {
    constructor() {
        super();
        this.state = {
            selected: 0
        }
    }
    getSizeIndex() {
        return this.state.selected
    }

    render() {
        return (
            <div className='d-flex' style={{ margin: '0 -5px' }}>
                {
                    this.props.data.map((e, i) => {
                        return (
                            <div
                                key={i}
                                className={this.state.selected != undefined && this.state.selected == i ? 'service-select-box active' : 'service-select-box'}
                                onClick={() => {
                                    this.setState({
                                        selected: i
                                    }, () => {
                                        this.props.calculatePrice();
                                    })
                                }}
                            >
                                {e}
                            </div>
                        )
                    })
                }
                {
                    this.state.selected != undefined ?
                        <input type="hidden" name="selectIndex" value={this.state.selected} />
                        : null
                }
                {/* <div className={this.state.selected && this.state.selected === '1' ? 'service-select-box active' : 'service-select-box'}
                    onClick={() => {
                        this.setState({
                            selected: '1'
                        })
                    }}
                >
                    ไม่เกิน 18,000 BTU
                </div>
                <div className={this.state.selected && this.state.selected === '2' ? 'service-select-box active' : 'service-select-box'}
                    onClick={() => {
                        this.setState({
                            selected: '2'
                        })
                    }}
                >
                    เกิน 18,000 BTU
                </div> */}
            </div>
        )
    }
}