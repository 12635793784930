import httpClient, { serverUrl } from './HttpClient';
const baseUrl = `${serverUrl}`;

export const getPartnerExistEmail = async (email) => {
    const url = `${baseUrl}/partner-exist-email/${email}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const createPartner = async (data) => {
    const url = `${baseUrl}/partner`;

    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

