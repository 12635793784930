import React from 'react';
import { Link } from '@reach/router';
import Swal from 'sweetalert2';
import { createPartner, getPartnerExistEmail } from '../../Services/partnerService';
import FormFileInput from '../../Components/FormFileInput';
import { Helper } from '../../Services/Helper';
import PrivacyInfo from '../../Components/PrivacyInfo';
import TermsInfo from '../../Components/TermsInfo';
import moment from 'moment'
export default class Form extends React.Component {
    constructor() {
        super();
        this.state = {
            step: [1, 2, 3, 4, 5, 6],
            bank_list: [
                "ธนาคารกรุงเทพ",
                "ธนาคารกสิกรไทย",
                "ธนาคารกรุงไทย",
                "ธนาคารทหารไทย",
                "ธนาคารไทยพาณิชย์",
                "ธนาคารกรุงศรีอยุธยา",
                "ธนาคารเกียรตินาคินภัทร",
                "ธนาคารซีไอเอ็มบีไทย",
                "ธนาคารทิสโก้",
                "ธนาคารธนชาต",
                "ธนาคารยูโอบี",
                "ธนาคารไทยเครดิตเพื่อรายย่อย",
                "ธนาคารแลนด์แอนด์ เฮ้าส์",
                "ธนาคารไอซีบีซี (ไทย)",
                "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
                "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
                "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
                "ธนาคารออมสิน",
                "ธนาคารอาคารสงเคราะห์",
                "ธนาคารอิสลามแห่งประเทศไทย"
            ],
            currentStep: 1,
            // partnerType: '2',
        }
    }

    onSubmitForm(data) {
        let uploadimages = [];
        if (this.$bankRef
            && this.$bankRef[0]
            && this.$bankRef[0].files
            && this.$bankRef[0].files[0]) {
            uploadimages.push({ file: this.$bankRef, name: 'bookBankImage' })
        }
        if (this.$personalCardRef
            && this.$personalCardRef[0]
            && this.$personalCardRef[0].files
            && this.$personalCardRef[0].files[0]) {
            uploadimages.push({ file: this.$personalCardRef, name: 'personalCardImage' })
        }
        if (this.$companyLicenseRef
            && this.$companyLicenseRef[0]
            && this.$companyLicenseRef[0].files
            && this.$companyLicenseRef[0].files[0]) {
            uploadimages.push({ file: this.$companyLicenseRef, name: 'companyLicenseImage' })
        }
        if (this.$companyPersonRef
            && this.$companyPersonRef[0]
            && this.$companyPersonRef[0].files
            && this.$companyPersonRef[0].files[0]) {
            uploadimages.push({ file: this.$companyPersonRef, name: 'companyPersonImage' })
        }

        Swal.fire({
            title: 'กำลังอัพโหลดรูปภาพ...',
            html: '<b></b>',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })
        this.uploadFile(uploadimages, 0, data)
    }

    uploadFile(uploadimages, index, data) {

        let nextIndex = index + 1
        Helper.uploadFile(uploadimages[index].file, 'airservicePartner/', {
            resize: true,
            maxWidth: 2500,
            maxHeight: 2500,
            onSuccess: async (url) => {
                let momentDate = moment()
                let uploadDate = momentDate.format('YYYY-MM-DD');
                data[uploadimages[index].name] = { ...url, created: uploadDate }
                if ((nextIndex) < uploadimages.length) {
                    this.uploadFile(uploadimages, nextIndex, data)
                } else {
                    this.create(data);
                }
            },
            onError: async (obj) => {
                Swal.fire({
                    // title: 'Warning!',
                    text: obj.message,
                    icon: 'error',
                    confirmButtonText: 'OK!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-light'
                    },
                    buttonsStyling: false,
                    reverseButtons: true
                })
                this.submit.removeClass('loading');
            }
        })
    }

    async create(data) {
        // console.log(data);
        // prepare data before send api

        // send api
        const res = await createPartner(data);
        if (res.error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: res.response.data.message, // ข้อความ error
                icon: 'error',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            this.$submit.removeClass('loading');
            return
        }
        this.setState({
            currentStep: 6,
            email: data.email
        }, () => {

            Swal.fire({
                title: 'เพิ่ม Partner สำเร็จ',
                // text: res.response.data.message, // ข้อความ error
                icon: 'success',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
        })

        this.$submit.removeClass('loading');
    }

    render() {
        let progressWidth = 0;
        let allPercent = 100 / this.state.step.length;
        progressWidth = allPercent * this.state.currentStep
        return (
            <>
                <div className='content-full booking-detail-content' style={{ backgroundColor: '#ffe8b6' }}>
                    <div className='container pt-5 pb-5'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-8'>
                                <div className='card' style={{ borderRadius: 20, backgroundColor: '#fff', borderWidth: 0 }}>
                                    <div className='card-body'>
                                        <div className="wizard">
                                            <div className="progress" style={{ height: 30 }}>
                                                <div className={`progress-bar progress-bar-striped ${this.state.currentStep != 6 ? "progress-bar-animated" : "bg-success"} ${this.state.currentStep >= 4 ? "bg-info" : ""}`} role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="4" style={{ width: `${progressWidth}%` }}>
                                                    {
                                                        this.state.currentStep == this.state.step.length ?
                                                            <>Complete</>
                                                            : <>
                                                                Step {this.state.currentStep} of {this.state.step.length}
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <form
                                                ref={(ref) => {
                                                    this.$form = window.$(ref);
                                                    this.$submit = this.$form.find(`[type="submit"]`);

                                                }}
                                                role="form"
                                                className="register-wizard-box custom-form"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    if (this.state.currentStep != 5) { return; }
                                                    if (this.$submit.hasClass('loading')) { return; }
                                                    this.$submit.addClass('loading');
                                                    let data = Helper.getFormInputObject(this.$form);
                                                    // console.log(data)
                                                    let error = false;
                                                    if (!this.$bankRef
                                                        || !this.$bankRef[0]
                                                        || !this.$bankRef[0].files
                                                        || !this.$bankRef[0].files[0]) {
                                                        error = { text: 'กรุณาอัพโหลดรูปสำเนาสมุดบัญชีธนาคาร' }
                                                    }
                                                    if (!data.bankAccountNumber) {
                                                        error = { text: 'กรุณากรอกเลขบัญชี' };
                                                    }
                                                    if (!data.bankAccountName) {
                                                        error = { text: 'กรุณากรอกชื่อบัญชี' };
                                                    }
                                                    if (!data.bankBranch) {
                                                        error = { text: 'กรุณากรอกสาขา' };
                                                    }
                                                    if (!data.bank) {
                                                        error = { text: 'กรุณาเลือกธนาคาร' };
                                                    }
                                                    if (error) {
                                                        Swal.fire({
                                                            title: error.text,
                                                            // text: '',
                                                            icon: 'warning',
                                                            position: 'top-end',
                                                            toast: true,
                                                            backdrop: false,
                                                            showConfirmButton: false,
                                                            timer: 2500
                                                        })
                                                        this.$submit.removeClass('loading');
                                                        Helper.setButtonLoaded(this.$submit)
                                                        return false;
                                                    }

                                                    this.onSubmitForm(data);
                                                }}
                                            >
                                                <div className="tab-content" id="main_form">
                                                    {/* STEP 1 */}
                                                    <div className={`tab-pane ${this.state.currentStep == 1 ? "active" : ""}`} role="tabpanel" id="step1">
                                                        <div className="form-input-steps">
                                                            <h4 className='mb-0'>เลือกประเภทของ Partner</h4>
                                                            <h3>ประเภท Partner</h3>
                                                            <div className='mt-0'>
                                                                <label>ประเภทลูกค้า</label>
                                                                <select
                                                                    className="form-control"
                                                                    name="partnerType"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            partnerType: e.target.value
                                                                        })
                                                                    }}
                                                                >
                                                                    <option value="">เลือกประเภทลูกค้า</option>
                                                                    <option value="1">นิติบุคคล</option>
                                                                    <option value="2">บุคคล</option>
                                                                </select>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <label>อีเมล์</label>
                                                                <input name="email" type={'email'} className={"form-control"} />
                                                                <p className="mb-0">โปรดระบุอีกเมล์เพื่อใช้เป็น Username พร้อมรับแจ้งผลลงทะเบียนและการแจ้งเตือนการใช้งานระบบ</p>
                                                                <small className="text-muted">ตัวอย่าง air-service@beeclean.com</small>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <label>รหัสผ่าน</label>
                                                                <input name="password" type={'password'} className={"form-control"} />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <label>ยืนยันรหัสผ่าน</label>
                                                                <input name="confirm_password" type={'password'} className={"form-control"} />
                                                            </div>
                                                        </div>
                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <Link to="/" className="btn btn-white">กลับ</Link>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-service ml-2"
                                                                    onClick={async (e) => {
                                                                        let _this = window.$(e.target);
                                                                        if (_this.hasClass('loading')) { return; }
                                                                        _this.addClass('loading');
                                                                        let passwordRef = window.$(`[name="password"]`)
                                                                        let confirmPasswordRef = window.$(`[name="confirm_password"]`)
                                                                        let emailValue = window.$(`[name="email"]`);
                                                                        // check email partner Type empty
                                                                        if (!this.state.partnerType) {
                                                                            Swal.fire({
                                                                                title: 'กรุณาเลือกประเภทลูกค้า',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        // check email empty
                                                                        if (!emailValue.val()) {
                                                                            Swal.fire({
                                                                                title: 'กรุณากรอกอีกเมล์',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        // check password empty
                                                                        if (!passwordRef.val()) {
                                                                            Swal.fire({
                                                                                title: 'กรุณากรอกรหัสผ่าน',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        // check confirm password empty
                                                                        if (!confirmPasswordRef.val()) {
                                                                            Swal.fire({
                                                                                title: 'กรุณากรอกรหัสผ่าน',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        if (passwordRef.val() != confirmPasswordRef.val()) {
                                                                            Swal.fire({
                                                                                title: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        const res = await getPartnerExistEmail(emailValue.val())
                                                                        if (res.error) {
                                                                            Swal.fire({
                                                                                title: 'อีเมลถูกใช้งานไปแล้ว',
                                                                                // text: '',
                                                                                icon: 'warning',
                                                                                position: 'top-end',
                                                                                toast: true,
                                                                                backdrop: false,
                                                                                showConfirmButton: false,
                                                                                timer: 2500
                                                                            })
                                                                            _this.removeClass('loading');
                                                                            return;
                                                                        }
                                                                        // go to next step
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                currentStep: 2
                                                                            }, () => {
                                                                                _this.removeClass('loading');
                                                                            })
                                                                        }, 500)
                                                                    }}
                                                                >ดำเนินการ</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* STEP 2 */}
                                                    <div className={`form-th tab-pane ${this.state.currentStep == 2 ? "active" : ""}`} role="tabpanel" id="step2">
                                                        <div className="form-input-steps">
                                                            {
                                                                this.state.partnerType == '2' ?
                                                                    <>
                                                                        <h4 className='mb-0'>ข้อมูลผู้ลงทะเบียน - ผู้มีอำนาจ / ผู้รับมอบอำนาจ</h4>
                                                                        <h3>ข้อมูลผู้ลงทะเบียน</h3>
                                                                        <IndividualForm />
                                                                    </>

                                                                    : null
                                                            }
                                                            {
                                                                this.state.partnerType == '1' ?
                                                                    <>
                                                                        <h3>ข้อมูลนิติบุคคล</h3>
                                                                        <LegalEntityForm />
                                                                        <hr className='hr-color mb-3' />
                                                                        <h4 className='mb-0'>ข้อมูลผู้ลงทะเบียน - ผู้มีอำนาจ / ผู้รับมอบอำนาจ</h4>
                                                                        <h3>ข้อมูลผู้ลงทะเบียน</h3>
                                                                        <IndividualForm />
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                !this.state.partnerType ?
                                                                    <>
                                                                        <h2 className='text-th text-black fs-1'>กรุณาเลือกประเภท Partner</h2>
                                                                        <p className='text-muted fs-5 text-th'>กดที่ปุ่ม "กลับ" เพื่อกลับไปเลือกประเภท Partner</p>
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>

                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <button type="button" onClick={() => { this.setState({ currentStep: 1 }) }} className="btn btn-white">กลับ</button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-service ml-2"
                                                                    onClick={() => {
                                                                        let check = this.checkStep2();
                                                                        if (!check) { return; }
                                                                        this.setState({
                                                                            currentStep: 3
                                                                        })
                                                                    }}
                                                                >ดำเนินการ</button>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    {/* STEP 3 */}
                                                    <div className={`form-th tab-pane ${this.state.currentStep == 3 ? "active" : ""}`} role="tabpanel" id="step3">
                                                        <div className="form-input-steps">
                                                            <h4 className='mb-0'>กรุณากรอกข้อมูลที่อยู่</h4>
                                                            <h3>ข้อมูลที่อยู่</h3>

                                                            <div className='mt-3 bold-label'>
                                                                <label>ที่อยู่</label>
                                                                <input ref={(ref) => { this.$address = window.$(ref) }} name="address" type={'text'} className={"form-control"} />
                                                                <small className="mb-0 text-muted">กรอกข้อมูลที่อยู่สำหรับติดต่อ</small>
                                                            </div>
                                                            <div className='mt-3 bold-label'>
                                                                <label>ที่อยู่จัดส่งเอกสาร/ใบกำกับภาษี</label>
                                                                <input ref={(ref) => { this.$taxInvoiceAddress = window.$(ref) }} name="taxInvoiceAddress" type={'text'} className={"form-control"} />
                                                                <small className="mb-0 text-muted">กรอกข้อมูลที่อยู่สำหรับจัดส่งเอกสาร/ใบกำกับภาษี</small>
                                                            </div>
                                                        </div>
                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <button type="button" onClick={() => {
                                                                    this.setState({
                                                                        currentStep: !this.state.partnerType ? 1 : 2
                                                                    })
                                                                }} className="btn btn-white">กลับ</button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-service ml-2"
                                                                    onClick={() => {
                                                                        let check = this.checkStep3();
                                                                        if (!check) { return; }
                                                                        this.setState({
                                                                            currentStep: 4
                                                                        })
                                                                    }}
                                                                >ดำเนินการ</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* STEP 4 */}
                                                    <div className={`form-th tab-pane ${this.state.currentStep == 4 ? "active" : ""}`} role="tabpanel" id="step3">
                                                        <div className="form-input-steps">
                                                            {
                                                                !this.state.partnerType ?
                                                                    <>
                                                                        <h4 className='mb-0'>กดที่ปุ่ม "กลับ" เพื่อกลับไปเลือกประเภท Partner</h4>
                                                                        <h3>กรุณาเลือกประเภท Partner</h3>
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.partnerType ?
                                                                    <>
                                                                        <h4 className='mb-0'>อัพโหลดเอกสารสำหรับยืนยันตัว</h4>
                                                                        <h3>อัพโหลดรูป</h3>
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                this.state.partnerType == '2' ?
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-md-6 mb-4">
                                                                                <div>
                                                                                    <label className="mb-0">บัตรประชาชนผู้มีอำนาจ / ผู้รับมอบอำนาจ</label>
                                                                                </div>
                                                                                <FormFileInput
                                                                                    ref={(ref) => {
                                                                                        if (ref && ref.$file) {
                                                                                            this.$personalCardRef = ref.$file;
                                                                                        }
                                                                                    }}
                                                                                    fileName={"personalCard"}
                                                                                />
                                                                                <div className="d-flex flex-column">
                                                                                    <small className="text-danger">กรุณาอัพโหลดรูปถ่ายบัตรประชาชนผู้มีอำนาจ / ผู้รับมอบอำนาจ</small>
                                                                                    <small className="text-muted">(รองรับไฟล์รูป JPG,PNG เท่านั้น)</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>

                                                                    : null
                                                            }
                                                            {
                                                                this.state.partnerType == '1' ?
                                                                    <>
                                                                        <div className="row bold-label">
                                                                            <div className="col-md-6 mb-4">
                                                                                <div>
                                                                                    <label className="mb-0">หนังสือรับรองจดทะเบียน/จัดตั้งองค์กร</label>
                                                                                </div>
                                                                                <small className="text-muted">ที่ออกโดยกรมพัฒราธุรกิจการค้า (DBD)</small>
                                                                                <FormFileInput
                                                                                    ref={(ref) => {
                                                                                        if (ref && ref.$file) {
                                                                                            this.$companyLicenseRef = ref.$file;
                                                                                        }
                                                                                    }}
                                                                                    fileName={"companyLicense"}
                                                                                />
                                                                                <div className="d-flex flex-column">
                                                                                    <small className="text-danger">กรุณาอัพโหลดรูปหรอืแบบโปรไฟล์หนังสือรับรองจดทะเบียน/จัดตั้งองค์กร</small>
                                                                                    <small className="text-muted">เอกสารรับรองบริษัทต้องไม่เกิน 6 เดือน</small>
                                                                                    <small className="text-muted">(รองรับไฟล์รูป JPG,PNG, PDF เท่านั้น)</small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 mb-4">
                                                                                <div>
                                                                                    <label className="mb-0">ใบมอบอำนาจสำหรับผู้รับมอบอำนาจ</label>
                                                                                </div>
                                                                                <small className="text-muted">ในกรณีที่ผู้ลงทะเบียนคือผู้รับมอบอำนาจ</small>
                                                                                <FormFileInput
                                                                                    ref={(ref) => {
                                                                                        if (ref && ref.$file) {
                                                                                            this.$companyPersonRef = ref.$file;
                                                                                        }
                                                                                    }}
                                                                                    fileName={"companyPerson"}
                                                                                />
                                                                                <div className="d-flex flex-column">
                                                                                    <small className="text-muted">(รองรับไฟล์รูป JPG,PNG, PDF เท่านั้น)</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className='hr-color mb-4' />
                                                                        <div className="row">
                                                                            <div className="col-md-6 mb-4">
                                                                                <div>
                                                                                    <label className="mb-0">บัตรประชาชนผู้มีอำนาจ / ผู้รับมอบอำนาจ</label>
                                                                                </div>
                                                                                <FormFileInput
                                                                                    ref={(ref) => {
                                                                                        if (ref && ref.$file) {
                                                                                            this.$personalCardRef = ref.$file;
                                                                                        }
                                                                                    }}
                                                                                    fileName={"personalCard"}
                                                                                />
                                                                                <div className="d-flex flex-column">
                                                                                    <small className="text-danger">กรุณาอัพโหลดรูปถ่ายบัตรประชาชนผู้มีอำนาจ / ผู้รับมอบอำนาจ</small>
                                                                                    <small className="text-muted">(รองรับไฟล์รูป JPG,PNG เท่านั้น)</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>
                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <button type="button" onClick={() => {
                                                                    this.setState({
                                                                        currentStep: !this.state.partnerType ? 1 : 3
                                                                    })
                                                                }} className="btn btn-white">กลับ</button>
                                                            </li>
                                                            {
                                                                this.state.partnerType ?
                                                                    <li>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-service ml-2"
                                                                            onClick={() => {
                                                                                let check = this.checkStep4();
                                                                                if (!check) { return; }
                                                                                this.setState({
                                                                                    currentStep: 5
                                                                                })
                                                                            }}
                                                                        >ดำเนินการ</button>
                                                                    </li>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </div>
                                                    {/* STEP 5 */}
                                                    <div className={`form-th tab-pane ${this.state.currentStep == 5 ? "active" : ""}`} role="tabpanel" id="step3">
                                                        <div className="form-input-steps">
                                                            <h4 className='mb-0'>ข้อมูลธนาคารที่ใช้ในการโอนเงิน</h4>
                                                            <h3>ธนาคาร</h3>
                                                            <div className='mb-3'>
                                                                <label>ธนาคาร</label>
                                                                <select className="form-control" name="bank">
                                                                    <option value="">เลือกธนาคาร</option>
                                                                    {
                                                                        this.state.bank_list.map((bank, bank_i) => {
                                                                            return (
                                                                                <option key={bank_i} value={bank}>{bank}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label>สาขา</label>
                                                                <input name="bankBranch" type={'text'} className={"form-control"} />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label>ชื่อบัญชี</label>
                                                                <input name="bankAccountName" type={'text'} className={"form-control"} />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label>เลขบัญชี</label>
                                                                <input name="bankAccountNumber" type={'text'} className={"form-control"} />
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-4">
                                                                    <div>
                                                                        <label className="mb-0">สำเนาหน้าสมุดบัญชีธนาคาร</label>
                                                                    </div>
                                                                    <FormFileInput
                                                                        ref={(ref) => {
                                                                            if (ref && ref.$file) {
                                                                                this.$bankRef = ref.$file;
                                                                            }
                                                                        }}
                                                                        fileName={"bankImage"}
                                                                    />
                                                                    <div className="d-flex flex-column">
                                                                        <small className="text-muted">(รองรับไฟล์รูป JPG,PNG เท่านั้น)</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <div>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value="true"
                                                                        name="acceptTerms"
                                                                        id="acceptTerms"

                                                                        onChange={(e) => {
                                                                            let formNode = this.$submit
                                                                            if (window.$(e.target).is(':checked')) {
                                                                                formNode.attr('disabled', false);
                                                                            } else {
                                                                                formNode.attr('disabled', true);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" for="acceptTerms" >
                                                                        ยอมรับเงื่อนไขในการให้บริการ
                                                                    </label>
                                                                    <span className='btn-link ml-1 mr-1 cursor-pointer'
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <ModalInfo
                                                                                    type="privacy"
                                                                                />
                                                                            )
                                                                        }}
                                                                    >นโยบายและข้อกำหนด</span>
                                                                    และ
                                                                    <span className='btn-link ml-1 cursor-pointer'
                                                                        onClick={() => {
                                                                            Helper.reactToDom(window.$('body'),
                                                                                <ModalInfo
                                                                                    type="terms"
                                                                                />
                                                                            )
                                                                        }}
                                                                    >ข้อตกลงการใช้งาน</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <button type="button" onClick={() => {
                                                                    this.setState({
                                                                        currentStep: 4
                                                                    })
                                                                }} className="btn btn-white">กลับ</button>
                                                            </li>
                                                            <li>
                                                                <button
                                                                    disabled={true}
                                                                    type="submit"
                                                                    className="btn btn-service ml-2"
                                                                >ลงทะเบียน</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* STEP 6 */}
                                                    <div className={`form-th tab-pane ${this.state.currentStep == 6 ? "active" : ""}`} role="tabpanel" id="step3">
                                                        <div className="form-input-steps">
                                                            <h4>ลงทะเบียน Partner สำเร็จ</h4>
                                                            <h3>เสร็จสิ้น</h3>
                                                        </div>
                                                        <ul className="list-inline text-right mt-5">
                                                            <li>
                                                                <Link
                                                                    to={"/"}
                                                                    className="btn btn-service ml-2"
                                                                >กลับหน้าแรก</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </form>
                                            {/* <div className="wizard-inner">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    {
                                                        this.state.step.map((step) => {
                                                            return (
                                                                <li role="presentation" key={step}>
                                                                    <a href={`#step${step}`} className={this.state.currentStep >= step ? "active" : "disabled"}>
                                                                        <span className="round-tab">{step}</span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    checkStep3() {
        let error = false;
        if (this.$taxInvoiceAddress
            && (!this.$taxInvoiceAddress.val() || this.$taxInvoiceAddress.val() == "")
        ) {
            error = { text: 'กรุณากรอกข้อมูลที่อยู่สำหรับจัดส่งเอกสาร/ใบกำกับภาษี' }
        }
        if (this.$address
            && (!this.$address.val() || this.$address.val() == "")
        ) {
            error = { text: 'กรุณากรอกข้อมูลที่อยู่' }
        }
        // taxInvoiceAddress
        if (error) {
            Swal.fire({
                title: error.text,
                // text: '',
                icon: 'warning',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            return false;
        }
        return true
    }
    checkStep4() {
        let error = false;

        if (this.state.partnerType == '1') {
            if (!this.$companyLicenseRef
                || !this.$companyLicenseRef[0]
                || !this.$companyLicenseRef[0].files
                || !this.$companyLicenseRef[0].files[0]) {
                error = { text: 'กรุณาอัพโหลดรูปหนังสือรับรองจดทะเบียน/จัดตั้งองค์กร' }
            }
        }
        if (!this.$personalCardRef
            || !this.$personalCardRef[0]
            || !this.$personalCardRef[0].files
            || !this.$personalCardRef[0].files[0]) {
            error = { text: 'กรุณาอัพโหลดรูปบัตรประชาชนผู้มีอำนาจ / ผู้รับมอบอำนาจ' }
        }
        if (error) {
            Swal.fire({
                title: error.text,
                // text: '',
                icon: 'warning',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            return false;
        }
        return true
    }

    checkStep2() {

        let personalNumberRef = window.$(`[name="personalNumber"]`)
        let sexRef = window.$(`[name="sex"]`)
        let prefixRef = window.$(`[name="prefix"]`)
        let nameRef = window.$(`[name="name"]`)
        let surnameRef = window.$(`[name="surname"]`)
        let dayRef = window.$(`[name="day"]`)
        let monthRef = window.$(`[name="month"]`)
        let yearRef = window.$(`[name="year"]`)

        let error = false;
        if (!yearRef.val()) {
            error = { text: 'กรุณาเลือกปีเกิด' };
        }
        if (!monthRef.val()) {
            error = { text: 'กรุณาเลือกเดือนเกิด' };
        }
        if (!dayRef.val()) {
            error = { text: 'กรุณาเลือกวันเกิด' };
        }
        if (!surnameRef.val()) {
            error = { text: 'กรุณากรอกนามสกุล' };
        }
        if (!nameRef.val()) {
            error = { text: 'กรุณากรอกชื่อ' };
        }
        if (!prefixRef.val()) {
            error = { text: 'กรุณาเลือกประเภทลูกค้า' };
        }
        if (!sexRef.val()) {
            error = { text: 'กรุณาเลือกเพศ' };
        }
        if (!personalNumberRef.val()) {
            error = { text: 'กรุณากรอกเลขบัตรประจำตัวประชาชน' };
        }


        if (this.state.partnerType == '1') {
            let legalType = window.$(`[name="legalType"]`)
            let legalNumber = window.$(`[name="legalNumber"]`)
            let legalName = window.$(`[name="legalName"]`)
            let legalPhone = window.$(`[name="legalPhone"]`)
            let legalBranch = window.$(`[name="legalBranch"]`)

            if (!legalBranch.val()) {
                error = { text: 'กรุณากรอกสาขา' };
            }
            if (!legalPhone.val()) {
                error = { text: 'กรุณากรอกหมายเลขโทรศัพท์ ที่จดทะเบียนนิติบุคคล' };
            }
            if (!legalName.val()) {
                error = { text: 'กรุณากรอกชื่อบริษัท' };
            }
            if (!legalNumber.val()) {
                error = { text: 'กรุณากรอกเลขทะเบียนนิติบุคคล' };
            }
            if (!legalType.val()) {
                error = { text: 'กรุณาเลือกประเภทนิติบุคคล' };
            }
        }

        if (error) {
            Swal.fire({
                title: error.text,
                // text: '',
                icon: 'warning',
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            return false;
        }
        return true
    }
}


class IndividualForm extends React.Component {
    constructor() {
        super();
        let days = [];
        for (let index = 1; index <= 31; index++) {
            days.push(index)
        }
        let years = [];
        let now = new Date();
        let nowYear = now.getFullYear() + 543;
        for (let index = nowYear - 100; index <= nowYear; index++) {
            years.push(index)
        }
        this.state = {
            days: days,
            months: window.months_th,
            year: years
        }
    }
    render() {
        return (
            <>
                <div className='mt-3 mb-3'>
                    <label>เลขประจำตัวประชาชน</label>
                    <input name="personalNumber" type={'text'} className={"form-control"} />
                    <small className="text-muted">ตัวอย่าง : 11XXXXXXXX999</small>
                </div>
                <div className='mt-3 mb-3'>
                    <label>หมายเลขโทรศัพท์</label>
                    <input name="phoneNumber" type={'text'} className={"form-control"} />
                    <small className="text-muted">ตัวอย่าง : 08XXXXXXXX</small>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <label>เพศ </label>
                            <select
                                className="form-control"
                                name="sex"
                            >
                                <option value="">เลือกเพศ</option>
                                <option value="1">ชาย</option>
                                <option value="2">หญิง</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <label>คำนำหน้า </label>
                            <select
                                className="form-control"
                                name="prefix"
                            >
                                <option value="">เลือกคำนำหน้า</option>
                                <option value="1">นาย</option>
                                <option value="2">นาง</option>
                                <option value="3">นางสาว</option>
                            </select>
                            <small className="text-muted">คำนำหน้าตามบัตรประชาชน</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <label>ชื่อ</label>
                            <input name="name" type={'text'} className={"form-control"} />
                            <small className="text-muted">ในกรณีสัญชาติไทยให้กรอกเป็นภาษาไทย</small>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <label>ชื่อรอง</label>
                            <input name="middlename" type={'text'} className={"form-control"} />
                            <small className="text-muted">ในกรณีสัญชาติไทยให้กรอกเป็นภาษาไทย</small>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <label>นามสกุล</label>
                            <input name="surname" type={'text'} className={"form-control"} />
                            <small className="text-muted">ในกรณีสัญชาติไทยให้กรอกเป็นภาษาไทย</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <label>วัน/เดือน/ปีเกิด</label>
                            <select className="form-control" name="day">
                                <option value="">วัน</option>
                                {
                                    this.state.days.map((item, i) => {
                                        return <option value={item} key={i}>{item}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <label>&nbsp;</label>
                            <select className="form-control" name="month">
                                <option value="">เดือน</option>
                                {
                                    this.state.months.map((item, i) => {
                                        return <option value={item} key={i}>{item}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <label>&nbsp;</label>
                            <select className="form-control" name="year">
                                <option value="">ปี</option>
                                {
                                    this.state.year.map((item, i) => {
                                        return <option value={item} key={i}>{item}</option>
                                    })
                                }
                            </select>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
class LegalEntityForm extends React.Component {
    render() {
        return (
            <>

                <div className='mt-3 mb-3'>
                    <label>ประเภทนิติบุคคล </label>
                    <select
                        className="form-control"
                        name="legalType"
                    >
                        <option value="">เลือกประเภทนิติบุคคล</option>
                        <option value="1">ห้างหุ้นส่วนสามัญ จดทะเบียนและห้างหุ้นส่วนจำกัด</option>
                        <option value="2">บริษัทจำกัด</option>
                    </select>
                </div>
                <div className='mt-3 mb-3'>
                    <label>เลขทะเบียนนิติบุคคล </label>
                    <input name="legalNumber" className="form-control" />
                    <small className="text-muted">ตัวอย่าง : 0 0000 00000 00 0</small>
                </div>
                <div className='mt-3 mb-3'>
                    <label>ชื่อบริษัท </label>
                    <input name="legalName" className="form-control" />
                    <small className="text-muted">ตัวอย่าง : บริษัท ABC</small>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <label>หมายเลขโทรศัพท์ <small >ที่จดทะเบียนนิติบุคคล</small></label>
                        <input name="legalPhone" className="form-control" />
                        <small className="text-muted">ตัวอย่าง : 0XX-XXX-XXXX</small>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label>สาขา</label>
                        <input name="legalBranch" className="form-control" />
                    </div>
                </div>
            </>
        )
    }
}

class ModalInfo extends React.Component {
    render() {
        return (

            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                style={{ maxWidth: '100%' }}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content custom-modal" >
                        <div className="modal-body">
                            {
                                this.props.type == "privacy" ?
                                    <PrivacyInfo />
                                    : null
                            }
                            {
                                this.props.type == "terms" ?
                                    <TermsInfo />
                                    : null
                            }
                        </div>
                        <div className='modal-footer'>
                            <button type="button"
                                className="btn btn-service mr-4"
                                data-dismiss="modal"
                            >ปิด</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}