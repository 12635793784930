import axios from 'axios';
import React from 'react';
import Loading from './../Components/Loading'
import XMLParser from 'react-xml-parser';

export default class Blog extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {


        // fetch("https://blog.beeclean.app/feed/", {
        //     mode: `no-cors`
        // })
        //     .then(res => res.text())
        //     .then(data => {
        //         console.log(data)
        //         var xml = new XMLParser().parseFromString(data);
        //         console.log(xml)
        //         this.setState({
        //             loading: false,
        //         })
        //     })
        //     .catch(err => {
        //         this.setState({
        //             loading: false,
        //         })
        //     });

        // fetch("https://blog.beeclean.app/feed/", {
        //     mode: `no-cors`
        // })
        //     .then(response => response.text())
        //     .then(data => {
        //         const parser = new DOMParser();
        //         const xml = parser.parseFromString(data, "text/xml");
        //         console.log(xml);
        //     })
        //     .catch(console.error);

        // fetch("https://blog.beeclean.app/feed/", {
        //     mode: `no-cors`
        // })
        //     .then(response => response.text())
        //     .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        //     .then(data => console.log(data));
    }

    render() {
        return (
            <div className={"page_content polygon-bg bg-bottom-left"} style={{ height: '100%' }}>
                <div className="container pt-5 pb-5 text-th ">
                    {
                        this.state.loading ?
                            <div className='d-flex justify-content-center mt-5'><Loading /></div>
                            : null
                    }
                    {
                        !this.state.loading
                            && this.state.data ?
                            <>
                            </>
                            : null
                    }
                    {
                        !this.state.loading
                            && !this.state.data ?
                            <>
                            </>
                            : null
                    }
                </div>
            </div>
        )
    }
}