import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;

export const getSetting = async () => {
    const url = `${baseUrl}/setting`;
    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
