import React from 'react';
import { Link } from '@reach/router'
import { Affix } from 'rsuite';
import { Helper } from '../Services/Helper';
export default class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            loginuser: false,
        }
    }

    componentDidMount() {
        this.checkLoginUser();
    }

    async checkLoginUser() {
        let loginuser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage('loginuser')) : false;
        this.setState({
            loginuser: loginuser,
        })
    }

    render() {
        return (
            <header>
                <div className='top-header'>
                    <div className='d-flex'>
                        <div>
                            <a href="/">
                                <i className="fa-brands fa-line mr-2"></i>
                                @BEEclean
                            </a>
                            <a href="/" className='ml-3'>
                                <i className="fa-solid fa-square-phone mr-2"></i>
                                02-027-7848
                            </a>
                        </div>
                        <div className='right-floated'>
                            <strong className='mr-2'>เขตพื้นที่ให้บริการ</strong>
                            กรุงเทพฯ และเขตปริมณฑล
                        </div>
                    </div>
                </div>
                <div style={{ overflow: 'hidden' }}>
                    <Affix className='header d-flex' top={0}>
                        <div className={"logo"}>
                            <Link to={"/"}>
                                <img src={window.location.origin + "/assets/images/logo-bee.png"} alt={"LOGO"} className="logo-commond" />
                                <img src={window.location.origin + "/assets/images/logo.png"} alt={"LOGO"} className="logo-resp" />
                            </Link>
                        </div>
                        <div className='container d-flex'>
                            <div className='header-container d-flex' style={{ flex: 1 }}>
                                <div className='d-flex align-items-center justify-content-center' style={{ flex: 1 }}>
                                    <ul className='menu'>
                                        <li>
                                            <Link to={"/"}>HOME</Link>
                                        </li>
                                        <li>
                                            <Link to={"/service"}>SERVICE</Link>
                                        </li>
                                        <li>
                                            <a href={"https://blog.beeclean.app/"} target={"_blank"}>BLOG</a>
                                        </li>
                                        <li>
                                            <Link to={"/contact"}>CONTACT</Link>
                                        </li>
                                        <li>
                                            <a href={"https://beeclean-frontend.web.app/register"}>JOIN US</a>
                                        </li>
                                        {/* <li>
                                        <p>JOIN US <i className="fa-solid fa-sort-down ml-2"></i></p>
                                        <ul className='submenu'>
                                            <li>
                                                <a href={"https://beeclean-frontend.web.app/register"}>สมัครงานทำความสะอาดบ้าน</a>
                                            </li>
                                            <li>
                                                <Link to={"/partner/register"}>สมัครงานทำความสะอาดแอร์</Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className='d-flex align-items-center'>
                        {
                            this.state.loginuser ?
                                <Link to={"/dashboard"} className={'login-button'} style={{ marginTop: -3 }}>
                                    {this.state.loginuser.displayName ? this.state.loginuser.displayName : 'ไม่ระบุชื่อ'}
                                </Link>
                                :
                                <Link to={"/login"} className={'login-button'} style={{ marginTop: -3 }}>
                                    เข้าสู่ระบบ
                                </Link>
                        }
                    </div> */}
                    </Affix>
                </div>
            </header >
        )
    }
}