import React from 'react';
// import { Link } from "@reach/router"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByLatLng } from 'react-google-places-autocomplete';
// import { firestore } from '../firebase';
import { Helper } from '../Services/Helper';
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {
                lat: props.lat || 13.9618178,
                lng: props.lng || 100.5688045,
            },
            selected: 2,
            // api_key: "AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8"
        }
    }

    componentDidMount() {
        // this.fetch()
    }

    async searchByLatLng(val) {
        let replace = val;
        let lat_from = val.indexOf("!3d");
        let lat_to = val.indexOf("!4d");
        let lng_from = val.indexOf("!4d");
        let lng_to = val.indexOf("?");
        if (lat_from && lat_to && lng_from) {
            if (lng_to === -1) {
                lng_to = val.length;
            }
            let latitude = val.substring(lat_from + 3, lat_to);
            let longitude = val.substring(lng_from + 3, lng_to);
            replace = `${latitude},${longitude}`;
        }

        replace = replace.replace("https://www.google.com/maps/search/?api=1&query=", "");
        replace = replace.replace("https://www.google.com/maps?q=", "");
        console.log(replace)
        let getData = replace.split(',');

        let lat = parseFloat(getData[0]);
        let lng = parseFloat(getData[1]);
        console.log(getData)
        if (getData.length !== 2) {
            Helper.messageTop({
                message: 'ไม่พบสถานที่'
            })
            return;
        }
        let geo = await geocodeByLatLng({ lat: lat, lng: lng });
        // console.log(geo)
        let location = { ...this.state.location };
        location.lat = lat;
        location.lng = lng;
        location.address = geo[0].formatted_address;
        this.setState({
            location: location
        })
    }

    render() {
        let customContainerStyle = {}
        if (this.props.customContainerStyle) {
            customContainerStyle = { ...this.props.customContainerStyle }
        }
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={this.props.mode !== 'view' ? 16 : 15}
                defaultCenter={{
                    lat: this.props.mode === 'view' ? this.props.lat : this.state.location.lat,
                    lng: this.props.mode === 'view' ? this.props.lng : this.state.location.lng,
                }}
            >
                {props.isMarkerShown &&
                    <Marker position={{
                        lat: this.props.mode === 'view' ? this.props.lat : this.state.location.lat,
                        lng: this.props.mode === 'view' ? this.props.lng : this.state.location.lng,
                    }}
                        draggable={this.props.mode !== 'view'}
                        onDragEnd={async (e) => {
                            let geo = await geocodeByLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                            // console.log(`geo`, geo)
                            let location = { ...this.state.location };
                            location.lat = e.latLng.lat();
                            location.lng = e.latLng.lng();
                            location.address = geo[0].formatted_address;
                            window.$('#renderPlace').html(`${location.address}`)
                            // let coordinate = firestore(location.lat, location.lng);
                            // placesRef.update({ coordinate: coordinate });
                            if (this.props.mode === 'modal') {
                                this.lat.val(location.lat);
                                this.lng.val(location.lng);
                                this.address.val(location.address);
                            }
                            if (this.props.onSelected && this.props.mode !== 'modal') {
                                this.props.onSelected(location);
                            }
                        }}
                    />}
            </GoogleMap>
        ))
        if (this.props.mode === 'modal') {
            return (
                <React.Fragment>
                    <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                        style={{ maxWidth: '100%' }}
                        ref={(ref) => {
                            this.$modal = window.$(ref);
                            this.$modal.modal('show');
                            this.$modal.on('hidden.bs.modal', () => {
                                let settime = window.setTimeout(() => {
                                    this.$modal.remove();
                                    clearTimeout(settime);
                                }, 500)
                            });
                        }}
                    >
                        <form className="modal-dialog modal-lg" role="document"
                            style={{ maxWidth: 1200 }}
                            ref={(ref) => {
                                this.$form = window.$(ref)
                                this.$form.off().on('submit', (e) => {
                                    e.preventDefault();
                                    let $submit = this.$form.find('button[type="submit"]');
                                    if ($submit.hasClass('loading')) { return false; }
                                    $submit.addClass('loading');
                                    let data = Helper.getFormInputObject(this.$form);
                                    // console.log(data);
                                    if (this.props.onSubmit) {
                                        this.props.onSubmit(data);
                                    }
                                    this.$modal.modal('hide')

                                })
                            }}
                        >
                            <div className="modal-content custom-modal" >
                                <div className="modal-header border-bottom-0 pb-0 mb-0">
                                    <h4 className="modal-title" id="myModalLabel">{this.props.modalTitle || "GoogleMapForm"}</h4>

                                </div>

                                <div className="modal-body">
                                    <div className={"mb-3"}>
                                        <div className="row">
                                            {/* <div className="col-12 col-md-3">
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            selected: parseFloat(e.target.value)
                                                        })
                                                    }}
                                                >
                                                    <option value="1">ค้นหาจาก Url</option>
                                                    <option value="2">ค้นหาจาก ชื่อสถานที่</option>
                                                </select>
                                            </div> */}
                                            <div className="col-12">
                                                {
                                                    this.state.selected === 1 ?
                                                        <div className="input-group" id="search-by-url">
                                                            <input className="form-control" placeholder="ค้นหาจาก Url" />
                                                            <span className="input-group-append">
                                                                <button type="button" className="btn btn-info btn-flat"
                                                                    onClick={(e) => {
                                                                        let ref = window.$(e.target);
                                                                        let input = ref.parents('#search-by-url').find('input');
                                                                        this.searchByLatLng(input.val())
                                                                        // console.log(input.val())
                                                                    }}
                                                                >ค้นหา</button>
                                                            </span>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.selected === 2 ?
                                                        <GooglePlacesAutocomplete
                                                            selectProps={{
                                                                placeholder: 'ค้นหาจากชื่อสถานที่',
                                                                onChange: async (e) => {
                                                                    // console.log(e);
                                                                    let geo = await geocodeByPlaceId(e.value.place_id);
                                                                    // console.log(geo)
                                                                    let lat = geo[0].geometry.location.lat();
                                                                    let lng = geo[0].geometry.location.lng();
                                                                    let location = { ...this.state.location };
                                                                    location.lat = lat;
                                                                    location.lng = lng;
                                                                    location.address = geo[0].formatted_address;
                                                                    this.setState({
                                                                        location: location
                                                                    })
                                                                },
                                                                noOptionsMessage: () => {
                                                                    return 'ไม่พบข้อมูล'
                                                                },
                                                                loadingMessage: () => {
                                                                    return 'กำลังค้นหาสถานที่'
                                                                }
                                                            }}
                                                        // apiKey="AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk"
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex'>
                                        <p> เลื่อนหมุด <i className="fa-solid fa-location-dot text-danger" style={{ fontSize: 20 }}></i> ไปยังสถานที่ของคุณ </p>
                                        <span id="renderPlace" className='right-floated'></span>
                                    </div>
                                    <MyMapComponent
                                        isMarkerShown
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8&v=3.exp&libraries=geometry,drawing,places&language=th&region=th"}
                                        // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: this.props.height + 'px' || `800px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        onSelected={(location) => {
                                            console.log(location)
                                            this.setState({
                                                location: location
                                            })
                                        }}
                                    />
                                    <div style={{ display: 'none' }}>
                                        <input type="text" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location.lat} onChange={() => { return; }} />
                                        <input type="text" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location.lng} onChange={() => { return; }} />
                                        <input type="text" ref={(ref) => { this.address = window.$(ref) }} name="address" value={this.state.location.address} onChange={() => { return; }} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button"
                                        className="btn btn-default mr-4"
                                        data-dismiss="modal"
                                    >ยกเลิก</button>
                                    <button type="submit" className={"btn submit-btn"}>บันทึก</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div style={{ border: '4px solid #fff', position: 'relative', ...customContainerStyle }}>
                    {
                        this.props.mode !== 'view' ?
                            <>
                                <button
                                    style={{ position: 'absolute', bottom: 4, left: 4, zIndex: 1 }}
                                    className="btn  btn-service btn-sm"
                                    type={"button"}
                                    onClick={() => {
                                        Helper.reactToDom(window.$('body'),
                                            <SearchBox onSelected={(location) => {
                                                this.setState({
                                                    location: location
                                                }, () => {
                                                    if (this.props.onSelected
                                                        && this.props.mode !== 'modal') {
                                                        this.props.onSelected(location);
                                                    }
                                                })
                                            }} />
                                        )
                                    }}
                                >ค้นหาสถานที่ </button>
                            </>
                            : null
                    }

                    <MyMapComponent
                        isMarkerShown
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk&v=3.exp&libraries=geometry,drawing,places&language=th&region=th"}
                        // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: this.props.height + 'px' || `800px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                    {
                        !this.props.onSelected ?
                            <>
                                <input type="hidden" ref={(ref) => { this.lat = window.$(ref) }} name="lat" value={this.state.location.lat} />
                                <input type="hidden" ref={(ref) => { this.lng = window.$(ref) }} name="lng" value={this.state.location.lng} />
                            </>
                            : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {
                lat: props.lat || 13.631787064094638,
                lng: props.lng || 100.54314216378978,
            },
            // api_key: "AIzaSyCiz0jabZax2-qrOy2nQo0_IKOKlf_6Op8"
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    style={{ maxWidth: '100%' }}
                    ref={(ref) => {
                        this.$modal = window.$(ref);
                        this.$modal.modal('show');
                        this.$modal.on('hidden.bs.modal', () => {
                            let settime = window.setTimeout(() => {
                                this.$modal.remove();
                                clearTimeout(settime);
                            }, 500)
                        });
                    }}
                >
                    <form className="modal-dialog modal-lg" role="document"
                        style={{ maxWidth: 1200 }}
                    >
                        <div className="modal-content" style={{ width: 1200 }}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">ค้นหาสถานที่</h4>
                            </div>
                            <div className="modal-body">
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                        placeholder: 'ค้นหาจากชื่อสถานที่',
                                        onChange: async (e) => {
                                            // console.log(e);
                                            let geo = await geocodeByPlaceId(e.value.place_id);
                                            let lat = geo[0].geometry.location.lat();
                                            let lng = geo[0].geometry.location.lng();
                                            let location = { ...this.state.location };
                                            location.lat = lat;
                                            location.lng = lng;
                                            this.setState({
                                                location: location
                                            }, () => {
                                                if (this.props.onSelected) {
                                                    this.props.onSelected(this.state.location);
                                                    this.$modal.modal('hide');
                                                }
                                            })
                                        },
                                        noOptionsMessage: () => {
                                            return 'ไม่พบข้อมูล'
                                        },
                                        loadingMessage: () => {
                                            return 'กำลังค้นหาสถานที่'
                                        }
                                    }}
                                // apiKey="AIzaSyBVznn93l6d08G6UunFanX2Mjo73dwbEPk"
                                />
                            </div>
                            <div className="modal-footer" style={{ display: 'none' }}>
                                <button type="button"
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.$modal.modal('hide');
                                    }}
                                >ยกเลิก</button>
                                <button type="submit" className={"btn btn-primary"}>บันทึก</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}