import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;
export const getBooks = async (qs) => {
    const url = `${baseUrl}/book-invoice?${window.$.param(qs)}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const requestInvoice = async (data) => {
    const url = `${baseUrl}/request-invoice`;

    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}