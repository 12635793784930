const axios = require('axios').default;
//DEV_PROD
//const skitz_api_host = "https://api.skitz.com/api/v1";
// export const skitz_api_host = "https://skitz.beefastdev.com/api/v1";
let api_host = process.env.REACT_APP_API_HOST;

let is_emulators = false;
if (window.location.origin.indexOf('localhost:') !== -1) {
    // is_emulators = true;
}
if (is_emulators) {
    api_host = "http://192.168.1.39:5100/api/v1";
}
export const serverUrl = api_host;

const httpClient = axios.create();
httpClient.interceptors.request.use(async (config) => {
    config.headers = {}

    return config;
},
    error => {
        Promise.reject(error)
    });
export default httpClient