import axios from 'axios';
import React from 'react';
import Loading from './../Components/Loading'

export default class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: false,
        }
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {


    }

    render() {
        return (
            <div className={"page_content polygon-bg bg-bottom-left"} style={{ height: '100%' }}>
                <div className="container pt-5 pb-5 text-th ">
                    <h2 className="mb-4 text-center">CONTACT</h2>
                    <p className="text-center" style={{ fontSize: 20 }}>
                        50/804 หมู่ที่ 9 ถนนบอนด์สตรีท ตำบลบางพลู อำเภอปากเกร็ด จังหวัดนนทบุรี 11120
                    </p>
                    <p className="text-center mb-4" style={{ fontSize: 20 }}>
                        <i className='fa-solid fa-phone mr-2' /> 02-027-7848 (Customer Service)
                    </p>
                    <div className='contact-social text-center'>
                        <a target="_blank" href="https://www.facebook.com/beecleanapp" rel="noopener noreferrer">
                            <img src="./assets/images/facebook.png" alt="Facebook" />
                        </a>
                        <a target="_blank" href="https://www.instagram.com/beecleanapp/" rel="noopener noreferrer">
                            <img src="./assets/images/instagram.png" alt="Instagram" />
                        </a>
                        <a target="_blank" href="https://twitter.com/beecleanapp" rel="noopener noreferrer">
                            <img src="./assets/images/twitter.png" alt="Twitter" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}