import React from "react";
import { Link, navigate } from "@reach/router";
import { Helper } from "../../Services/Helper";
import DashboardMenu from "../../Components/DashboardMenu";
import Loading from "../../Components/Loading";

export default class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            user: Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false
        }
    }
    componentDidMount() {
        if (!this.state.user) {
            window.location.href = `${window.location.origin}/login`
            return;
        }
        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
            window.cartRef.current.renderCartItems()
        }
        this.setState({
            is_loading: false,
        })
    }
    render() {
        return (
            <>
                <div className='content-full  pt-5 pb-5' style={{ backgroundColor: '#ffe8b680' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3 col-lg-3">
                                <DashboardMenu
                                    active={"dashboard"}
                                />
                            </div>
                            <div className="col-12 col-md-9 col-lg-9  custom-dashboard-card">

                                {
                                    this.state.is_loading ?
                                        <div className="mt-5">
                                            <Loading />
                                        </div>
                                        : null
                                }
                                {
                                    !this.state.is_loading
                                        && this.state.user ?
                                        <>
                                            <div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="image-container">
                                                            <div className="image-profile">
                                                                <img src={`${window.location.origin}/assets/images/blank.png`} alt={"profile"} />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <h3 style={{ fontWeight: 'bold' }}>{this.state.user.displayName}</h3>
                                                            <small className="d-flex align-items-center" style={{ color: '#a1a5b7' }}><i className="las la-envelope mr-1" style={{ fontSize: 16 }}></i> {this.state.user.email}</small>
                                                            <small className="d-flex align-items-center" style={{ color: '#a1a5b7' }}><i className="las la-phone mr-1" style={{ fontSize: 16 }}></i> {Helper.renderPhone(this.state.user.phoneNumber)}</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <Link to={"/service/order"} className={`btn btn-service btn-lg`} >
                                                    <i className="las la-broom"></i> เรียกบริการ
                                                </Link>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

