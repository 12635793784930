import React from 'react';
// import { Helper } from '../Services/Helper';
const axios = require('axios').default;

export default class ToAppProvider extends React.Component {
    componentDidMount() {
        setTimeout(
            function () {
                window.location.href = "beecleanuser://";
            }
                .bind(this),
            500
        );
    }

    render() {
        return (
            <>

            </>
        );
    }
}