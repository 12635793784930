import React from "react"
export default class ImageRender extends React.Component {
    render() {
        let file = this.props.file

        if (file.imagePath.indexOf('.mp4') != -1 || file.imagePath.indexOf('.MOV') != -1) {
            // console.log(file)
            return (
                <div style={{ position: 'relative', overflow: 'hidden', borderRadius: 10 }}>
                    <a href={file.imagePath}
                        data-fancybox={this.props.customImageGroup ? this.props.customImageGroup : `car-images`}
                        data-caption={this.props.title}
                        className={"dashed-box fancybox-frame p-0"}
                        style={{ height: this.props.frameHeight || 150, borderRadius: 10, overflow: 'hidden', display: this.props.hide ? 'none' : 'block' }}
                    >
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }} className={"d-flex justify-content-center align-items-center"}>
                            <i className="fa-regular fa-circle-play" style={{ color: '#fff', fontSize: 40 }}></i>
                        </div>
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                        }} className={"d-flex justify-content-center align-items-center"}>
                            <video width="130" height="130" autoPlay={false}>
                                <source src={file.imagePath} type={file.type || "video/mp4"} />
                            </video>
                        </div>
                    </a >

                </div >
            )
        }
        return (<div style={{ position: 'relative' }}>

            {
                this.props.imageCount
                    && this.props.imageCount > 1 ?
                    <span className="position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary" style={{ zIndex: 10 }}>
                        {this.props.imageCount}
                    </span>
                    : null
            }
            {
                !this.props.disableTitle && this.props.title ?
                    <label className='fs-6 fw-bolder mb-2 justify-content-center align-items-center d-flex' style={{ minHeight: 42, textAlign: 'center', lineHeight: 1.2 }}>{this.props.title}</label>
                    : null
            }
            <a href={file.imagePath}
                data-fancybox={this.props.customImageGroup ? this.props.customImageGroup : `car-images`}
                data-caption={this.props.title}
                className={"dashed-box fancybox-frame p-0"}
                style={{ height: this.props.frameHeight || 150, borderRadius: 10, overflow: 'hidden', display: this.props.hide ? 'none' : 'block' }}
            >
                <img src={file.imagePath} style={{ maxWidth: '100%' }} alt={"image"} />
            </a>
        </div>)
    }
}