import { async } from '@firebase/util';
import httpClient, { serverUrl } from './HttpClient';
const _serverUrl = serverUrl;

const baseUrl = `${_serverUrl}`;
export const createUserAPI = async (userUid, email, phoneNumber, displayName) => {
    var data = {
        userUid, email, phoneNumber, displayName
    }
    const url = `${baseUrl}/airclean-create-user`;

    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}
export const getUserAddress = async (userUid, placeUid) => {
    const url = `${baseUrl}/airclean-user-address/${userUid}/${placeUid}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getUserAddressList = async (userUid) => {
    const url = `${baseUrl}/airclean-user-address/${userUid}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const createUserAddress = async (data) => {
    const url = `${baseUrl}/airclean-user-address`;

    try {
        const res = await httpClient.post(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const editUserAddress = async (data) => {
    const url = `${baseUrl}/airclean-user-address`;

    try {
        const res = await httpClient.put(url, data)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const omiseGetCreditCard = async (uid) => {
    const url = `${baseUrl}/omise-card/${uid}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}


export const omiseDeleteCreditCard = async (uid, cardId) => {
    const url = `${baseUrl}/omise-card/${uid}/${cardId}`;

    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const omiseAddCreditCard = async (data, uid) => {
    const url = `${baseUrl}/omise-card`;

    try {
        const res = await httpClient.post(url, { card: data, uid })
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const omiseEditCreditCard = async (data, uid) => {
    const url = `${baseUrl}/omise-card`;

    try {
        const res = await httpClient.put(url, { card: data, uid })
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const deleteUserAddress = async (userUid, placeUid) => {
    const url = `${baseUrl}/airclean-user-address/${userUid}/${placeUid}`;

    try {
        const res = await httpClient.delete(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}

export const getBookingHistory = async (userUid, page, rows) => {
    const url = `${baseUrl}/history?userUid=${userUid}&page=${page}&rows=${rows}`;

    try {
        const res = await httpClient.get(url)
        return res.data;
    } catch (error) {
        return { error: true, ...error };
    }
}