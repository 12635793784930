

import React from "react"
// import { render } from "react-dom"
import { Router } from "@reach/router";
// import Swal from 'sweetalert2'
import Cart from "./Components/Cart";
//IMPORT INCLUDE
import Header from "./Include/Header"
import Footer from "./Include/Footer"
//IMPORT PAGE
import Home from "./Page/home/Home"
import Dashboard from "./Page/home/Dashboard"
import Login from "./Page/login/Login";
import ConfirmOtp from "./Page/login/ConfirmOtp";
import Register from "./Page/login/Register";
import Service from "./Page/services/Services";
import ServiceHistory from "./Page/services/History";
import Place from './Page/place/Place';
import PlaceForm from './Page/place/PlaceForm';
import Payment from './Page/payment/Payment';
import Result from './Page/payment/Result';
import PartnerForm from './Page/partner/Form';
import Card from './Page/card/Card';
import PrivacyPolicy from './Page/PrivacyPolicy';
import Terms from './Page/Terms';
import Download from './Page/Download';
import ServicePage from "./Page/Service";
import RegisterProvider from "./Page/RegisterProvider";
import Blog from './Page/Blog';
import Contact from './Page/Contact';
import Test from './Page/Test';

import DownloadProviderApp from './Page/DownloadProviderApp'
import DownloadUserApp from './Page/DownloadUserApp';
import DownloadProvider from "./Page/DownloadProvider";
import OmiseComplete from "./Page/OmiseComplete";
import OmiseVoid from './Page/OmiseVoid'
import ToAppProvider from "./Page/ToAppProvider";
import ToAppUser from "./Page/ToAppUser";

import { db } from "./Services/firebase";
import { Helper } from "./Services/Helper";
import AirserviceOmiseCallback from "./Page/AirserviceOmiseCallback";
import AirserviceWegateCallback from "./Page/AirserviceWegateCallback";
import TaxInvoice from "./Page/request/TaxInvoice";
import TaxInvoiceForm from "./Page/request/TaxInvoiceForm";
import TaxInvoiceSuccess from "./Page/request/TaxInvoiceSuccess";


window.headerRef = React.createRef();
window.cartRef = React.createRef();
window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.api_host = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app';
window.api_airservice_host = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/airclean';
if (window.location.origin.indexOf("http://localhost:3000") != -1) {
  window.api_host = "http://localhost:5001/beeclean-app-66b97/asia-southeast2/app";
  window.api_airservice_host = "http://localhost:5001/beeclean-app-66b97/asia-southeast2/airclean";
}
function App() {

  return (
    <>
      {

        window.location.pathname !== '/download-provider-app'
          && window.location.pathname !== '/download-user-app'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/application-tos'
          && window.location.pathname !== '/application-privacy-policy'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/airservice-omise-callback'
          && window.location.pathname !== '/airservice-wegate-callback'
          && window.location.pathname.indexOf('/request/tax-invoice') == -1
          && window.location.pathname !== '/loading' ?
          <Header ref={window.headerRef} />
          : null
      }
      <div
        style={{ position: 'relative', flex: 1, width: `100%` }}
        className={"d-flex flex-column"}
      >
        <Router style={{ flex: 1 }}>
          <Home path="/" />
          <HomeRedirect path="/home" />

          <TaxInvoice path="/request/tax-invoice" />
          <TaxInvoiceSuccess path="/request/tax-invoice/success" />
          <TaxInvoiceForm path="/request/tax-invoice/:phoneNumber" />

          <PrivacyPolicy path="/privacy-policy" />
          <Terms path="/terms" />
          <Terms path="/application-tos" />
          <PrivacyPolicy path="/application-privacy-policy" />
          <ServicePage path="/service" />
          <Blog path="/blog" />
          <Contact path="/contact" />
          <Download path="/download" />

          <DownloadProviderApp path="/download-provider-app" />
          <DownloadUserApp path="/download-user-app" />
          <DownloadProvider path={"/download-provider"} />

          <AirserviceOmiseCallback path="/airservice-omise-callback" />
          <AirserviceWegateCallback path="/airservice-wegate-callback" />

          <ToAppProvider path="/to-app-provider" />
          <ToAppUser path="/to-app-user" />


          <Loading path="/loading" />

          <Login path="/login" />
          <ConfirmOtp path="/login/confirm" />
          <Register path="/register" />
          <Service path="/service/order" />
          <ServiceHistory path="/service/history" />
          <Card path="/my-card" mycard={true} />
          <Card path="/card" />
          <Place path="/my-place" myplace={true} />
          <PlaceForm path="/my-place/add" myplace={true} />
          <PlaceForm path="/my-place/:placeId" myplace={true} />
          <Place path="/place" />
          <PlaceForm path="/place/add" />
          <PlaceForm path="/place/:placeId" />
          <Payment path="/payment" />
          <Result path="/payment/result" />
          <PartnerForm path="/partner/register" />
          <RegisterProvider path="/maid/register" />

          <Dashboard path="/dashboard" />
          <Test path="/test" />
        </Router>
        {/* {
          window.location.pathname !== '/airservice-omise-callback' &&
            window.location.pathname !== '/airservice-wegate-callback'
            ?
            <Footer />
            : null
        } */}

      </div>

      {
        window.location.pathname !== '/download-provider-app'
          && window.location.pathname !== '/download-user-app'
          && window.location.pathname !== '/application-tos'
          && window.location.pathname !== '/application-privacy-policy'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/airservice-omise-callback'
          && window.location.pathname !== '/airservice-wegate-callback'
          && window.location.pathname.indexOf('/request/tax-invoice') == -1
          && window.location.pathname !== '/loading' ?
          <Footer />
          : null
      }
    </>
  );
}

export default App;
class HomeRedirect extends React.Component {
  componentDidMount() {
    window.location.href = '/'
  }
  render() {
    return null
  }
}
class Loading extends React.Component {
  constructor() {
    super();
    let merchantReference = Helper.getParameterByName("merchantReference") || false;
    this.state = {
      merchantReference: merchantReference,
      render_type: 'loading'
    }
  }
  componentDidMount() {
    this.init();
  }
  init() {
    if (this.state.merchantReference) {
      this.onSnapWegate = db.collection('onSnapWegate').doc(this.state.merchantReference).onSnapshot(e => {
        if (e.exists) {
          console.log('wait for noti')
          let paymentStatusCode = e.data().paymentStatusCode;
          if (paymentStatusCode) {
            if (paymentStatusCode == "004") {
              this.setState({ render_type: "success" })
            } else if (paymentStatusCode == "006" || paymentStatusCode == "007" || paymentStatusCode == "099") {
              this.setState({ render_type: "error" })
            }
          }
        } else {
          this.setState({ render_type: "loading" })
        }
      })
    }
  }

  linktoapp() {
    // window.opener = null;
    // window.open('', '_self');
    // window.close();
    window.location.href = "beecleanuser://beeclean";
    // setTimeout(
    //   function () {
    //     window.location.href = "https://beeclean-frontend.web.app/home";
    //   }
    //     .bind(this),
    //   3000
    // );
  }

  render() {
    return (
      <>
        <div className="container content-bg omise-complete-ui">
          <div className={"title"}>
            {
              this.state.render_type == 'success' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.location.origin + "/assets/images/15.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>
                  <div>
                    <div style={{ color: 'green' }}>ชำระเงินสำเร็จ</div>
                    <small style={{ color: 'green', opacity: 0.7 }}>Payment successfully</small>
                  </div>
                </>
                : null
            }
            {
              this.state.render_type == 'error' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.location.origin + "/assets/images/29.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>

                  <div>
                    <div style={{ color: 'red' }}>ชำระเงินไม่สำเร็จ</div>
                    <small style={{ color: 'red', opacity: 0.7 }}>Payment Failed</small><br />
                    <small style={{ opacity: 0.7 }}>โปรดกลับไปเพื่อทำการชำระเงินใหม่อีกครั้ง</small>
                  </div>
                </>
                : null
            }
            {
              this.state.render_type == 'loading' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.location.origin + "/assets/images/9.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>
                  <>
                    <div>
                      <h3 className="text-primary text-center fs-1">กรุณารอสักครู</h3>
                      <p className="text-primary text-center fs-1" style={{ opacity: 0.7 }}>Please wait</p>
                      <p className="text-center d-flex justify-content-center fs-1" style={{ opacity: 0.7 }}>ระบบกำลังทำการยืนยันการชำระเงิน</p>
                    </div>
                  </>
                </>
                : null
            }
            <br />
            <div className='d-flex justify-content-center'>
              <button className={"btn login-button"} style={{ width: 'auto' }} onClick={this.linktoapp}>
                กดเพื่อกลับไปยัง Application
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}