import React from "react";
import Swal from "sweetalert2";
import { Helper } from "../Services/Helper";
import { navigate, Link } from "@reach/router";
import RuleInfo from '../Components/RuleInfo'
import axios from "axios";
import { paymentAPI } from "../Services/aircleanServices";

export default class Cart extends React.Component {
    constructor() {
        super();
        let cart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let promotion = false;
        if (cart && cart.promotion) {
            promotion = cart.promotion
        }
        this.state = {
            cartItems: [],
            cartData: cart,
            promotion: promotion,
        }
    }
    componentDidMount() {
        this.renderCartItems();
    }
    renderCartItems() {
        // console.log(`renderCartItems`)
        let storageCartItems = Helper.getLocalStorage('items');
        let cartItems = storageCartItems ? JSON.parse(storageCartItems) : [];

        this.setState({
            cartItems: cartItems,
            type: window.location.pathname == '/payment' ? 'payment' : 'cart'
        })
    }
    renderPromotion() {
        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let promotion = false;
        if (getCart && getCart.promotion) {
            promotion = getCart.promotion;
        }
        this.setState({
            promotion: promotion
        })
    }

    async payment() {
        Swal.fire({
            title: 'กรุณารอสักครู่...',
            text: 'ระบบกำลังประมวลผล',
            timerProgressBar: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let items = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : {};
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        let card = getCart.card;
        let place = getCart.place;
        // let preRenderDate = new Date(getCart.date)
        let date = getCart.date;
        // let year = preRenderDate.getFullYear();
        // let month = preRenderDate.getMonth();
        // if(month < 10) {
        //     month = `0${month}`;
        // }
        // let day = preRenderDate.getDate()
        // if(day < 10) {
        //     day = `0${day}`;
        // }
        // let date = `${year}-${month}-${day}`;
        // console.log(date)

        const res = await paymentAPI({ userUid: currentUser.uid, items, card, place, date })
        if (res.error) {
            Swal.fire({
                icon: 'warning',
                title: 'เกิดข้อผิดพลาด',
                text: res.response.data.message, // ใส่ข้อความหาก error  เช่น โปรหมด, ใช้ไม่ได้ เพราะ ?
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            return
        }
        Helper.setLocalStorage('items', JSON.stringify([]))
        delete getCart.date;
        Helper.setLocalStorage('cart', JSON.stringify(getCart))

        window.location.href = res.url;
        // Swal.fire({
        //     title: 'ชำระเงินสำเร็จ',
        //     text: 'ทำการจองบริการสำเร็จแล้ว',
        //     icon: 'success',
        //     position: 'top-end',
        //     toast: true,
        //     backdrop: false,
        //     showConfirmButton: false,
        //     timer: 2500
        // })
        // navigate(`/dashboard`)
    }

    navigateToPayment() {
        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        if (!getCart || !getCart.place) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบสถานที่',
                text: 'กรุณาระบุสถานที่', // ใส่ข้อความหาก error  เช่น โปรหมด, ใช้ไม่ได้ เพราะ ?
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 3500
            })
            return;
        }
        navigate('/payment')
    }

    clearPromotion() {
        let promotionButtonRef = this.promotionInput.parent().find('button');
        let promotion = false
        this.setState({
            promotion: promotion,
        }, () => {
            let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
            getCart.promotion = promotion;
            Helper.setLocalStorage('cart', JSON.stringify(getCart))
            promotionButtonRef.removeClass('loading')
            this.promotionInput.val("")
        })
        return;
    }

    addPromotion() {
        let defaultValue = ``;
        if (this.promotionInput) {
            defaultValue = this.promotionInput.val();
        }
        let promotionButtonRef = this.promotionInput.parent().find('button');

        if (!defaultValue || defaultValue === '') {
            Swal.fire({
                icon: 'warning',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณากรอกโค้ดส่วนลด', // ใส่ข้อความหาก error  เช่น โปรหมด, ใช้ไม่ได้ เพราะ ?
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500
            })
            this.promotionInput.focus();
            promotionButtonRef.removeClass('loading')
            return;
        }

        //// check promotion (call api)

        // if success
        let promotion = {
            name: 'ลด!',
            code: defaultValue,
        } // promotion data

        this.setState({
            promotion: promotion,
        }, () => {
            let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
            getCart.promotion = promotion;
            Helper.setLocalStorage('cart', JSON.stringify(getCart))
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มโค้ดส่วนลดเรียบร้อยแล้ว', // ใส่ข้อความหาก error  เช่น โปรหมด, ใช้ไม่ได้ เพราะ ?
                position: 'top-end',
                toast: true,
                backdrop: false,
                showConfirmButton: false,
                timer: 2500

            })
            promotionButtonRef.removeClass('loading')
        })
        return;

        // if error
        Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'กรุณาลองใหม่อีกครั้ง', // ใส่ข้อความหาก error  เช่น โปรหมด, ใช้ไม่ได้ เพราะ ?
            position: 'top-end',
            toast: true,
            backdrop: false,
            showConfirmButton: false,
            timer: 2500

        })
        promotionButtonRef.removeClass('loading')
    }


    render() {
        let airConCount = 0;
        let total = 0;
        if (this.state.cartItems
            && this.state.cartItems.length > 0) {
            for (const item of this.state.cartItems) {
                airConCount += parseFloat(item.amount)
                let price = parseFloat(item.service.prices[parseFloat(item.selectIndex)]);
                if (isNaN(price)) {
                    price = 0;
                }
                let amount = parseFloat(item.amount);
                if (isNaN(amount)) {
                    amount = 0;
                }
                total += (price * amount)
            }
        }
        return (
            <>
                {
                    this.state.cartItems
                        && this.state.cartItems.length > 0 ?
                        <div className='cart-box'>
                            {
                                this.state.type === 'payment'
                                    || this.state.promotion ?
                                    <div className="cart-promotion container-fluid">
                                        <div className='d-flex align-items-center  justify-content-end'>
                                            <div style={{ minWidth: 90 }}><small style={{ fontWeight: 'bold' }}>ใช้โค้ดส่วนลด :</small></div>
                                            <input
                                                ref={(ref) => { this.promotionInput = window.$(ref) }}
                                                type="text"
                                                className="form-control form-control-yel "
                                                style={{ maxWidth: 150 }}
                                                disabled={this.state.promotion}
                                                defaultValue={this.state.promotion && this.state.promotion.code ? this.state.promotion.code : ''}
                                            />
                                            {
                                                this.state.promotion ?
                                                    <button type="button" className='btn submit-btn btn-sm ml-3'
                                                        onClick={(e) => {
                                                            let thisRef = window.$(e.target)
                                                            if (thisRef.hasClass('loading')) { return; }
                                                            thisRef.addClass('loading')
                                                            this.clearPromotion()
                                                        }}
                                                    >
                                                        ยกเลิก
                                                    </button>
                                                    :
                                                    <button type="button" className='btn submit-btn btn-sm ml-3'
                                                        disabled={this.state.promotion}
                                                        onClick={(e) => {
                                                            let thisRef = window.$(e.target)
                                                            if (thisRef.hasClass('loading')) { return; }
                                                            thisRef.addClass('loading')
                                                            this.addPromotion()
                                                        }}
                                                    >
                                                        ใช้
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                    : null
                            }


                            <div className='container-fluid cart-bottom'>
                                <div className='d-flex align-items-center  justify-content-end'>
                                    รวม <strong>({this.state.cartItems.length} รายการ)</strong> จำนวน <strong>({airConCount} เครื่อง)</strong> ยอดชำระทั้งหมด : <strong className='price'>฿{Helper.numberFormat(total)}</strong>
                                    {
                                        this.state.type === 'payment' ?
                                            <>
                                                <button type="button" className='btn btn-white ml-3'
                                                    onClick={() => {
                                                        Helper.reactToDom(window.$('body'),
                                                            <RuleModal
                                                                onConfirmed={() => {
                                                                    Swal.fire({
                                                                        title: 'ยืนยันการชำระเงิน',
                                                                        html:
                                                                            `<div><strong>ชำระเงินสินค้า</strong></div>` +
                                                                            `<div><strong>(${this.state.cartItems.length})</strong> รายการ / จำนวน <strong>(${airConCount})</strong> เครื่อง</div>` +
                                                                            `<div>ยอดชำระทั้งหมด : <strong>฿${Helper.numberFormat(total)} บาท</strong></div>`,
                                                                        icon: 'question',
                                                                        reverseButtons: true,
                                                                        showCancelButton: true,
                                                                        confirmButtonText: 'ชำระเงิน',
                                                                        cancelButtonText: 'ยกเลิก',
                                                                        customClass: {
                                                                            confirmButton: 'btn btn-service',
                                                                            cancelButton: 'btn btn-white'
                                                                        },
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            this.payment();
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                >
                                                    ยืนยันการชำระเงิน
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button className='btn btn-white ml-3'
                                                    onClick={() => {
                                                        this.navigateToPayment()
                                                    }}
                                                >
                                                    ดำเนินการต่อ
                                                </button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                }
            </>
        )
    }
}


class RuleModal extends React.Component {
    render() {
        return (
            <div className="modal fade" id="form_traning" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                style={{ maxWidth: '100%' }}
                ref={(ref) => {
                    this.$modal = window.$(ref);
                    this.$modal.modal('show');
                    this.$modal.on('hidden.bs.modal', () => {
                        let settime = window.setTimeout(() => {
                            this.$modal.remove();
                            clearTimeout(settime);
                        }, 500)
                    });
                }}
            >
                <form className="modal-dialog" role="document"
                    ref={(ref) => {
                        this.$form = window.$(ref)
                        this.$form.off().on('submit', (e) => {
                            e.preventDefault();

                            if (this.props.onConfirmed) {
                                this.props.onConfirmed();
                            }
                            this.$modal.modal('hide')

                        })
                    }}
                >
                    <div className="modal-content custom-modal" >
                        <div className="modal-body">
                            <RuleInfo />
                            <div className='d-flex justify-content-center align-items-center btn-form mt-5'>
                                <button type="button"
                                    className="btn btn-service mr-4"
                                    data-dismiss="modal"
                                >ปิด</button>
                                <button type="submit" className={"btn submit-btn"}>ยอมรับเงื่อนไข</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        )
    }
}