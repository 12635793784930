import React from 'react';
import { Link, navigate } from '@reach/router';
import Swal from 'sweetalert2';
import { Helper } from '../../Services/Helper';
import { getChannels } from '../../Services/WePaymentServices';
import Loading from '../../Components/Loading';
const baseUrl = `https://s3.amazonaws.com/static.neostack.com/img/react-slick`
export default class Payment extends React.Component {
    constructor(props) {
        super(props);
        let storageCartItems = Helper.getLocalStorage('items');
        let cartItems = storageCartItems ? JSON.parse(storageCartItems) : [];
        let now = new Date();
        let creditCardYears = [];
        for (let index = now.getFullYear(); index <= (now.getFullYear() + 10); index++) {
            creditCardYears.push(index)
        }

        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let card = {}
        if (getCart.card) {
            card = { ...getCart.card }
        }
        this.state = {
            is_loading: true,
            cartItems: cartItems,
            selected: false,
            creditCardYears: creditCardYears,
            card: card,
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    componentDidMount() {
        let currentUser = Helper.getLocalStorage('loginuser') ? JSON.parse(Helper.getLocalStorage("loginuser")) : false;
        if (!currentUser) {
            window.location.href = '/dashboard'
            return;
        }

        let cartItems = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : [];
        if (cartItems.length === 0) {
            window.location.href = '/service/order'
            return;
        }
        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
            window.cartRef.current.renderCartItems()
        }

    }
    componentWillUnmount() {
        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
            window.cartRef.current.renderCartItems()
        }
    }


    onDelete(e, item) {
        Swal.fire({
            title: 'ยืนยันการยกเลิก',
            text: "ต้องการยกเลิกบริการนี้ใช่หรือไม่ ?",
            icon: 'question',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            customClass: {
                confirmButton: 'btn btn-service',
                cancelButton: 'btn btn-white'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let getCartItems = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : [];
                let cartItems = getCartItems.filter((e) => { return e.id !== item.id });
                this.setState({
                    cartItems: cartItems,
                }, () => {
                    Helper.setLocalStorage('items', JSON.stringify(cartItems));
                    window.$(e.target).parents('.payment-card').remove();
                    if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
                        window.cartRef.current.renderCartItems()
                    }

                    Swal.fire({
                        title: 'ยกเลิกบริการ',
                        text: 'ทำการยกเลิกบริการออกจากรายการเรียบร้อยแล้ว',
                        icon: 'success',
                        position: 'top-end',
                        toast: true,
                        backdrop: false,
                        showConfirmButton: false,
                        timer: 2500
                    })
                    if (cartItems.length == 0) {
                        let cartData = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : false;
                        delete cartData.promotion;
                        Helper.setLocalStorage('cart', JSON.stringify(cartData))
                        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderPromotion) {
                            window.cartRef.current.renderPromotion()
                        }
                        navigate('/service/order')
                    }
                })
            }
        })
    }

    updateCard(e, index) {
        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let card = {};
        if (getCart && getCart.card) {
            card = { ...getCart.card }
        }
        getCart.card = card;
        getCart.card[e.target.name] = e.target.value;
        Helper.setLocalStorage('cart', JSON.stringify(getCart))
    }

    calculateSummary(amount, item, node) {
        let service = item.service
        let defaultAmount = parseFloat(amount);
        let currentPrice = parseFloat(service.prices[item.selectIndex]);

        let price = 0;
        if (isNaN(defaultAmount)) {
            defaultAmount = 1;
        }
        if (isNaN(currentPrice)) {
            currentPrice = 0;
        }
        price = defaultAmount * currentPrice;
        let priceNode = window.$(node);
        priceNode.html(`฿${Helper.numberFormat(price)}`)
    }

    render() {
        return (
            <div className='content-full content-payment' style={{ backgroundColor: '#f4f4f4f4' }}>
                <div className='container pt-5 '>
                    <div className='card payment-card mb-4' style={{ background: 'none', borderWidth: 0 }}>
                        <div className='card-body'>
                            <h3 className='mb-4'>ช่องทางการชำระเงิน</h3>
                            <PaymentMethods />
                        </div>
                    </div>
                    {
                        this.state.cartItems ?
                            <>
                                {this.state.cartItems.map((item, index) => {
                                    let service = item.service;
                                    return (
                                        <div className='card payment-card' key={index}>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-4 col-md-2'>
                                                        <img src={service.images[0]} style={{ width: `100%` }} alt={"serviceimage"} />
                                                    </div>
                                                    <div className='col-8 col-md-4 d-flex flex-column justify-content-center'>
                                                        <p className='mb-0'>บริการล้างแอร์</p>
                                                        <h5 className='mb-0'><strong className='fs-2'>{service.name}</strong></h5>
                                                        <div className='d-flex align-items-center cursor-pointer'>
                                                            <small className='text-muted'>ตัวเลือก : {service.size[item.selectIndex]}</small>
                                                            {/* <i className="fa-solid fa-caret-down text-muted ml-1" style={{ fontSize: 12 }}></i> */}
                                                        </div>
                                                    </div>
                                                    <div className='col-7 col-md-3 col-lg-2  d-flex flex-column justify-content-center'>
                                                        <div className='d-flex align-items-center' id="form-amount">
                                                            <button
                                                                type="button"
                                                                className='circle-yellow'
                                                                onClick={(e) => {
                                                                    let inputTarget = window.$(e.target).parents(`#form-amount`).find(`input[name="amount"]`);
                                                                    let defaultAmount = parseFloat(inputTarget.val());
                                                                    if (isNaN(defaultAmount)) {
                                                                        defaultAmount = 1;
                                                                    }
                                                                    let newAmount = defaultAmount - 1;
                                                                    if (newAmount < 1) {
                                                                        newAmount = 1;
                                                                        this.onDelete(e, item);
                                                                    }
                                                                    inputTarget.val(newAmount)
                                                                    let cartItems = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : [];
                                                                    let addedIndex = cartItems.findIndex((e) => {
                                                                        return e.id == item.id
                                                                    })
                                                                    cartItems[addedIndex].amount = newAmount;
                                                                    this.setState({
                                                                        cartItems: cartItems,
                                                                    }, () => {
                                                                        Helper.setLocalStorage('items', JSON.stringify(cartItems));
                                                                        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
                                                                            window.cartRef.current.renderCartItems()
                                                                        }
                                                                        this.calculateSummary(newAmount, item, `.summary-${item.id}`);
                                                                    })
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-minus"></i>
                                                            </button>
                                                            <input type="text" name="amount" className='form-control service-amount' defaultValue={item.amount}
                                                                onKeyUp={(e) => {
                                                                    let inputTarget = window.$(e.target)
                                                                    let defaultAmount = parseFloat(inputTarget.val());
                                                                    if (isNaN(defaultAmount)) {
                                                                        defaultAmount = 1;
                                                                    }
                                                                    let newAmount = defaultAmount;
                                                                    if (newAmount < 1) {
                                                                        newAmount = 1;
                                                                    }
                                                                    inputTarget.val(newAmount)
                                                                    let cartItems = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : [];
                                                                    let addedIndex = cartItems.findIndex((e) => {
                                                                        return e.id == item.id
                                                                    })
                                                                    cartItems[addedIndex].amount = newAmount;
                                                                    this.setState({
                                                                        cartItems: cartItems,
                                                                    }, () => {
                                                                        Helper.setLocalStorage('items', JSON.stringify(cartItems));
                                                                        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
                                                                            window.cartRef.current.renderCartItems()
                                                                        }
                                                                        this.calculateSummary(newAmount, item, `.summary-${item.id}`);
                                                                    })
                                                                }}
                                                            />
                                                            <button
                                                                type="button"
                                                                className='circle-yellow'
                                                                onClick={(e) => {
                                                                    let inputTarget = window.$(e.target).parents(`#form-amount`).find(`input[name="amount"]`);
                                                                    let defaultAmount = parseFloat(inputTarget.val());
                                                                    if (isNaN(defaultAmount)) {
                                                                        defaultAmount = 1;
                                                                    }
                                                                    let newAmount = defaultAmount + 1;
                                                                    if (newAmount < 1) {
                                                                        newAmount = 1;
                                                                    }
                                                                    inputTarget.val(newAmount)
                                                                    let cartItems = Helper.getLocalStorage('items') ? JSON.parse(Helper.getLocalStorage('items')) : [];
                                                                    let addedIndex = cartItems.findIndex((e) => {
                                                                        return e.id == item.id
                                                                    })
                                                                    cartItems[addedIndex].amount = newAmount;
                                                                    this.setState({
                                                                        cartItems: cartItems,
                                                                    }, () => {
                                                                        Helper.setLocalStorage('items', JSON.stringify(cartItems));
                                                                        if (window.cartRef && window.cartRef.current && window.cartRef.current.renderCartItems) {
                                                                            window.cartRef.current.renderCartItems()
                                                                        }
                                                                        this.calculateSummary(newAmount, item, `.summary-${item.id}`);
                                                                    })
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='col-5 col-md-2 col-lg-3 d-flex flex-column justify-content-center align-items-center'>
                                                        <p className='mb-0'>รวม <strong className={`summary-${item.id}`}>฿{Helper.numberFormat((parseFloat(item.amount) * parseFloat(service.prices[item.selectIndex])))}</strong></p>
                                                    </div>
                                                    <div className='col-12 col-md-1  d-flex flex-column justify-content-center align-items-center'>
                                                        <button type={"button"}
                                                            className={"btn btn-light btn-icon btn-circle"}
                                                            style={{ borderRadius: 30 }}
                                                            onClick={(e) => {
                                                                this.onDelete(e, item);
                                                            }}
                                                        >
                                                            <i className="fa-solid fa-trash-can"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            : null
                    }
                </div>

            </div>
        )
    }
}

class PaymentMethods extends React.Component {
    constructor() {
        super();
        this.state = {
            is_loading: true,
            channels: [],
        }
    }

    componentDidMount() {
        this.fetch();
    }


    async fetch() {
        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
        let payment = false;
        if (getCart.payment) {
            payment = getCart.payment;
        }
        let res = await getChannels();
        let channels = [
            {
                channelCode: 'OMISE',
                channelName: 'Credit Card',
                image: `${window.location.origin}/assets/images/channels/cp.png`,
            }
        ];
        if (!res.error) {
            for (const channel of res.channels) {
                let _channel = { ...channel };
                if (channel.channelCode == "PP") {
                    _channel.image = `${window.location.origin}/assets/images/channels/pp.png`;
                }
                if (channel.channelCode == "TM") {
                    _channel.image = `${window.location.origin}/assets/images/channels/tm.png`
                }
                if (channel.channelCode == "AL") {
                    _channel.image = `${window.location.origin}/assets/images/channels/al.png`
                }
                if (channel.channelCode == "SP") {
                    _channel.image = `${window.location.origin}/assets/images/channels/sp.png`
                }
                if (channel.channelCode == "LP") {
                    _channel.image = `${window.location.origin}/assets/images/channels/lp.png`
                }
                if (channel.channelCode == "WC") {
                    _channel.image = `${window.location.origin}/assets/images/channels/wc.png`
                }
                channels.push(_channel)
            }
        }

        let selected = this.state.selected;
        if (payment) {
            selected = payment
        }

        this.setState({
            is_loading: false,
            channels: channels,
            selected: selected,
        })
    }

    displayPlaceIcon(channel) {
        if (!channel || !channel.image) {
            return false
        }
        return <img src={channel.image} alt={`Payment images`} />
    }

    renderCardNumber(number, options) {
        let numberConvert = number;
        let splitBy = '-';
        if (options && options.splitBy) {
            splitBy = options.splitBy;
        }
        if (number) {
            numberConvert = '';
            let i = 0;
            for (const num of number.match(/.{1,4}/g)) {
                if (i > 0) {
                    numberConvert += splitBy;
                }
                numberConvert += num;
                i++;
            }
        }
        return numberConvert;
    }

    renderSelected() {
        if (this.state.selected.channelCode == 'OMISE') {
            let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
            let card = false;
            if (getCart.card) {
                card = { ...getCart.card }
            }
            let item = card;
            return (
                <>
                    {
                        item ?
                            <div className='place-card-container' >
                                <div className={'place-card creditcard-box box-shadow mb-2'}>
                                    <h5 className='mb-0 mt-3'>
                                        <strong>{this.renderCardNumber(item.cardNumber, { splitBy: '-' })}</strong>
                                    </h5>
                                    <h5 className='mb-0'>{item.cardExpiredMonth}/{item.cardExpiredYear}</h5>
                                    <p className='mb-0'>{item.cardName}</p>
                                    <div className='d-flex justify-content-end align-items-end w-100 card-icon'>
                                        <img src={`${window.location.origin}/assets/images/channels/cp.png`} alt={`cardpic`} style={{ height: 50 }} />
                                    </div>
                                </div>
                                <p style={{ fontSize: 14, lineHeight: 1.2 }}>{item.address}</p>
                            </div>
                            : null
                    }
                    <Link to={`/card`} className='login-button w-100 d-flex justify-content-center align-items-center' style={{ width: '100%' }}>
                        เลือกบัตร
                    </Link>
                </>
            )
        }
    }

    render() {
        if (this.state.is_loading) {
            return <Loading />
        }
        return (
            <div className='row'>
                <div className='col-12 col-md-8'>
                    <div className='row'>
                        {
                            this.state.channels.map((channel, i) => {
                                return (
                                    <div className='col-6 col-md-4 col-lg-3 mb-3' key={i}>
                                        <div className='place-card-container payment-card'>
                                            <div className={this.state.selected && this.state.selected.channelCode === channel.channelCode ? 'place-card box-shadow active mb-2' : 'place-card box-shadow mb-2'}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: channel
                                                    }, () => {
                                                        let getCart = Helper.getLocalStorage('cart') ? JSON.parse(Helper.getLocalStorage('cart')) : {};
                                                        getCart.payment = channel;
                                                        Helper.setLocalStorage('cart', JSON.stringify(getCart));
                                                    })
                                                }}
                                            >

                                                <div className={this.state.selected && this.state.selected.channelCode === channel.channelCode ? 'icon-check show' : 'icon-check'}>
                                                    <i className="fa-solid fa-check text-success"></i>
                                                </div>
                                                <div className='img'>
                                                    {this.displayPlaceIcon(channel)}
                                                </div>
                                                <p className='mb-0'>
                                                    {channel.channelName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    {
                        !this.state.selected ?
                            <h4 style={{ fontWeight: 'bold' }}>กรุณาเลือกวิธีการชำระเงิน</h4>
                            : this.renderSelected()
                    }
                </div>
            </div>
        )
    }
}