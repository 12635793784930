import React from 'react';

export default class ContactFixed extends React.Component {
    render() {
        return (
            <>
                <div className='fixed-right'>
                    <a href="tel:020277848" className="fixed-line-icon mb-3">
                        <img src={`${window.location.origin}/assets/images/phones.png`} alt="lione icon by flaticon.com" />
                    </a>
                    <a href="https://line.me/R/ti/p/@beeclean" target={"_blank"} className="fixed-line-icon">
                        <img src={`${window.location.origin}/assets/images/line.png`} alt="lione icon by flaticon.com" />
                    </a>
                </div>
            </>
        )
    }
}